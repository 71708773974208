import axios from "axios";
import moment from "moment-jalaali";
import {ServerAddress, User, Header} from "../Enum/Urls";
import {Reducers} from "../Enum/Models"
import {toggleVarification,toggleAccountWindow,showError,editMessage} from "../Actions/VisualDataAction";
import Currency from '../Enum/Currency';

export const createUser=(data)=>{

    return {type:'CreateUser',userData:data,token:data.accessToken}
};
export const logoutUser=()=>{
    localStorage.removeItem("userToken");
    localStorage.removeItem("userTokenCreateTime");
    localStorage.removeItem("userData");
    return {type:'CreateUser',userData:null,token:null}
};
//create user
const  getUserAsync =(data) =>({
    type:Reducers.CREATEUSER,userData:data
});
//set Currency
export const setCurrency=(currency)=> {
    Currency.setCurrency(currency);
    return {
        type: Reducers.SETCURRENCY, Currency: Currency.getCurrency()
    }
};
export const getCurrency=()=>{
    return{
        type:Reducers.SETCURRENCY,Currency:Currency.getCurrency()
    }
}
//verification user by tokenID
export const getUser=()=>{
  return dispatch=>{
      let currency=Currency.getCurrency();
      dispatch(setCurrency(currency));
      let token=localStorage.getItem('userToken');
      if(token){
          axios({
              method:'get',
              url:`${ServerAddress.Type}${ServerAddress.User}${User.VarficationUser}`,
              headers:{Authorization:`Bearer ${token}`,...Header}
          })
          .then((responsive)=>{
              dispatch(getUserAsync(responsive.data.result));
              dispatch(toggleVarification(true));

          })
          .catch((error)=>{
                  dispatch(toggleVarification(false))
              })
      }else{
          dispatch(toggleVarification(false))
      }

  }
};
//login user
export const loginUser=(params)=>{
    return (dispatch)=>{
            axios({
                method:'post',
                url:`${ServerAddress.Type}${ServerAddress.User}${User.Login}`,
                data:{...params},
                headers:{...Header}
            }).then((response)=>{
                const user = response.data.result.user;

                localStorage.setItem('userData',JSON.stringify(user));
                localStorage.setItem('userToken',response.data.result.accessToken);
                localStorage.setItem('userTokenCreateTime',moment().format());

                dispatch(getUserAsync(response.data.result.user));
                dispatch(toggleVarification(true));
                dispatch(toggleAccountWindow(false));
                
                const currentPagePath = window.location.pathname;
                if (currentPagePath === "/confrim" && response.data.result.user.isEmailConfirmed){
                    window.location.href='/';
                }
                debugger;

                // if(response.data.result.user.isEmailConfirmed){
                //     window.location.href='/';
                // }else{
                //     window.location.href='/profile';
                // }


            }).catch((error)=>{
                try{
                    dispatch(toggleVarification(false));
                    dispatch(showError({visibility:true,content:error.response.data.error.message,closable:true}))
                }catch (e) {
                    dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page",closable:true}))
                }
            })

    }
};

//register user
export const registerUser=(params)=>{
    return(dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.Register}`,
            data:{...params},
            headers:{...Header}
        })
        .then((response)=>{
               dispatch(loginUser(params));

        })
        .catch((error)=>{
            try{
                dispatch(toggleVarification(false));
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
}

//register user
export const registerNaminUser=(params)=>{
    return(dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.RegisterByInfo}`,
            data:{...params},
            headers:{...Header}
        })
        .then((response)=>{
            let newParams = {
                emailOrPhoneNumber:params.emailAddress,
                password:params.password
            }
               dispatch(loginUser(newParams));

        })
        .catch((error)=>{
            try{
                dispatch(toggleVarification(false));
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
}
//confrimEmail
export const confrimEmail=(params)=>{
    return (dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.ActivateEmail}`,
            data:{...params},
            headers:{...Header}
        }).then((response)=>{
            dispatch(editMessage(true));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }

        });
    }

}
//send recover email
export const recoverEmail=(params)=>{
    return (dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.Forgot}`,
            data:{...params},
            headers:{...Header}
        }).then((response)=>{
            dispatch(editMessage(true));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        });
    }
}
//reset email by revocery link
export const resetEmail=(params)=>{
    return (dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.ResetPassword}`,
            data:{...params},
            headers:{...Header}
        }).then((result)=>{
            dispatch(editMessage(true));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }

        })
    }

}
//update user
export const updateUser=(params)=>{
    let token=localStorage.getItem("userToken");
    return (dispatch)=>{
        axios({
            method:'put',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.Update}`,
            data:{...params},
            headers:{Authorization:`Bearer ${token}`,...Header}
        }).then((result)=>{
            dispatch(getUserAsync(result.data.result));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }

}
//changePassword
export const changePassword=(param)=>{
    let token=localStorage.getItem("userToken");
    return (dispatch)=>{
        axios({
            method:'post',
            url:`${ServerAddress.Type}${ServerAddress.User}${User.ChangePassword}`,
            data:{...param},
            headers:{Authorization:`Bearer ${token}`,...Header}
        }).then((result)=>{
            dispatch(editMessage(true));
        }).catch((error)=>{
            try{
                dispatch(showError({visibility:true,content:error.response.data.error.message}))
            }catch (e) {
                dispatch(showError({visibility:true,content:"Internal Error,Please refresh the page"}))
            }
        })
    }
}
//resetEmail
//forgotEmail

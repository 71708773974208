import React from "react";

// import Autocomplete from "../Common/Autocomplete.js";
// import { Link } from "react-router-dom";
// import { ServerAddress, Hotel, Header } from "../../Enum/Urls";
// import SelectRoom from "./SelectRoom";
// import { Rangepicker } from "react-itours-rangepicker";
// import { withRouter } from "react-router-dom";
// import queryString from "query-string";
import HotelFormSearchTOF from "./Hotel-Form-Search-TOF";
// import { DatePicker } from "antd";

/* import flightIcon from '../../image/flight.svg';
import hotelIcon from '../../image/hotel.svg'; */
class HotelHomeTop extends React.Component {
  render() {
    return (
      <div className="page-container">
        <div className="padideh-home-top">
          <HotelFormSearchTOF />
          <div
            onClick={() => this.props.handleLink()}
            className="adv-banner hidden-small hidden-xsmall hidden-medium margin-start-medium"
          ></div>
        </div>
      </div>
    );
  }
}
export default HotelHomeTop;

import React from "react";
import { Link } from "react-router-dom";
import resaneh from "../../image/footer/resaneh.png";
import passengerRights from "../../image/footer/passenger-rights.png";
import padidehLogo from '../../image/padideh-small.png';
import enamad from "../../image/footer/enamad.png";
import iranPlaneOrganization from "../../image/footer/iran-plane-organization.png";
import iranAirplane from "../../image/footer/iran-airplane.png";
import { connect } from "react-redux";
import LayoutConfig from "../../Config/layoutConfig";

class NaminTravelFooter extends React.Component {
  state = {
    showcurrency: false,
    showlang: false,
  };
  toggleCurrency() {
    this.setState((prevState) => ({
      showcurrency: !prevState.showcurrency,
    }));
  }
  toggleLang() {
    this.setState((prevState) => ({
      showlang: !prevState.showlang,
    }));
  }
  render() {
    const { Dictionary, LAN } = this.props;
    return (
      <footer className="footer">
          <div className="main-footer">
            <div className="page-container">
              <div className="float-row">
              <div className="col-xsmall-12 col-small-6 col-medium-4 offset-medium-2 text-center">
                    {LAN === "IR" ? (
                    <>
                        <div className="namads-holder clearfix">
                        {(LayoutConfig.companyName === "Sunhotel")?
                            <a referrerPolicy="origin" target="_blank" rel="noopener noreferrer" href="https://logo.samandehi.ir/Verify.aspx?id=160388&p=rfthgvkaobpdxlaomcsimcsi">
                                <img referrerPolicy="origin" src={resaneh} className="logo-samandehi" alt="" />
                            </a>
                            :
                            <a referrerPolicy="origin" target="_blank" rel="noopener noreferrer" href="https://logo.samandehi.ir/Verify.aspx?id=77316&p=jyoejyoexlaorfthgvka">
                                <img referrerPolicy="origin" src={resaneh} className="logo-samandehi" alt="" />
                            </a>
                        }
                        <a
                            href="https://www.cao.ir/paxrights"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                            src={passengerRights}
                            className="namad-item"
                            alt="حقوق مسافر"
                            />
                        </a>
                        {(LayoutConfig.companyName === "Sunhotel")?
                        <a referrerPolicy="origin" target="_blank" rel="noopener noreferrer" href="https://trustseal.enamad.ir/?id=29250&amp;Code=uGKdtCgAdAMGvWshw6E0">
                            <img referrerPolicy="origin" src={enamad} className="namad-logo" alt="" id="uGKdtCgAdAMGvWshw6E0" />
                        </a>                           
                        :
                            <a referrerPolicy="origin" target="_blank" rel="noopener noreferrer" href="https://trustseal.enamad.ir/Verify.aspx?id=34040&p=fuixodshdrfsodshdrfs">
                                <img referrerPolicy="origin" src={enamad} className="namad-logo" alt="" id="fuixodshdrfsodshdrfs" />
                            </a>
                        }

                        <a
                            href="https://www.cao.ir"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                            src={iranPlaneOrganization}
                            className="namad-item"
                            alt="سازمان هواپیمایی کشور "
                            />
                        </a>

                        <a
                            href="http://www.aira.ir/"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <img
                            src={iranAirplane}
                            className="namad-item"
                            alt="انجمن شرکت های هواپیمایی ایران "
                            />
                        </a>
                        <span>
                            <img src={padidehLogo} alt="padideh81" className="namad-item" />
                        </span>
                        
                        </div>
                        <br />
                    </>
                    ) : (
                    <h5 className="footer-title">
                        {Dictionary.followUsOnSocialNetworks}:{" "}
                    </h5>
                    )}

                    <div>
                    {LayoutConfig.contacts &&
                    LayoutConfig.contacts.instagram === "disabled" ? (
                        <span className="social-link disabled margin-end">
                        <span className="itours-icon instagram" />
                        </span>
                    ) : (
                        <a
                        href={
                            LayoutConfig.contacts &&
                            LayoutConfig.contacts.instagram
                            ? LayoutConfig.contacts.instagram
                            : LAN === "IR"
                            ? "https://www.instagram.com/itours.ir/"
                            : "https://www.instagram.com/itours_no/"
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link margin-end"
                        >
                        <span className="itours-icon instagram" />
                        </a>
                    )}

                    <a
                        href={`https://wa.me/${LayoutConfig.contacts.telephone[0].replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link margin-end"
                        >
                        <span className="itours-icon whatsapp" />
                    </a>  


                    {LayoutConfig.contacts &&
                    LayoutConfig.contacts.facebook && (LayoutConfig.contacts.facebook !== "disabled" )? (
                        <a
                        href={LayoutConfig.contacts.facebook}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="social-link margin-end"
                        >
                        <span className="itours-icon facebook" />
                        </a>  
                    ) : (
                        <span className="social-link">
                            <span className="itours-icon facebook-gray" />
                        </span>

                    )}

                    </div>
                    {/* <div className="text-center margin-top">
                    <span dir="ltr">
                        {LayoutConfig.contacts && LayoutConfig.contacts.telephone && LayoutConfig.contacts.telephone.length>0
                        ? LayoutConfig.contacts.telephone[0]
                        : Dictionary.supportNumber}
                    </span>
                    </div> */}
                </div>                  
                <div className="col-xsmall-12 col-small-6 col-medium-4">
                    <div className="margin-start-large quick-access-block">
                    <h5 className="footer-title">
                        {Dictionary.quickAccess}
                    </h5>
                    <Link to={"/"} className="footerblock-link">
                        {Dictionary.flight}
                    </Link>
                    <Link to={"/hotel"} className="footerblock-link">
                        {Dictionary.hotel}
                    </Link>
                    <Link
                        to={"/terms"}
                        className="footerblock-link"
                        disabled={
                        LayoutConfig.themeClassName === "padide-com-theme"
                        }
                    >
                        {Dictionary.termsAndConditions}
                    </Link>
                    <Link to={"/about"} className="footerblock-link">
                        {Dictionary.aboutUs}
                    </Link>
                    <Link to={"/contact"} className="footerblock-link">
                        {Dictionary.contactUs}
                    </Link>
                    </div>
                </div>
                </div>
            </div>
          </div>
      </footer>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(NaminTravelFooter);

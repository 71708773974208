import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { ServerAddress, Header,User } from "../../Enum/Urls";
import { CountryCode } from "../../Enum/Models";
import {Form,Input,Select} from "antd";
import LayoutConfig from "../../Config/layoutConfig";

class ContactUS extends React.PureComponent {
  state={
    submitLoading:false,
    submitMessage:undefined,
    showModal:false
  }
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  closeModal = () =>{
    if (!this.state.submitLoading){
      this.setState({showModal:false});
    }
  }
  handleReset = () => {
    this.props.form.resetFields();
  };
  handleSubmitContact = e =>{
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({submitLoading:true,showModal:true});
        axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.CreateContactUs}`, values, {headers: {...Header,},})
        .then(({ data }) => {
          if (data.error) {
             this.setState({
                submitLoading:false,
                submitMessage:{text:data.error.message,success:data.success}
              });
            }else{
              this.handleReset();
              this.setState({
                submitLoading:false,
                submitMessage:{text:this.props.Dictionary.yourMessageHasBeenSentSuccessfully,success:data.success}
              });
           }
        })
        .catch((error) => {
          try {
            this.setState({
              submitLoading:false,
              submitMessage:{text:error.response.data.error.message,success:false}
            });
          } catch (e) {
            this.setState({
              submitLoading:false,
              submitMessage:{text:"Internal Error,Please refresh the page",success:false}
            });
          }
        });
      }
    });
  }
  render() {
    const { Dictionary, LAN } = this.props;
    const { getFieldDecorator } = this.props.form;
    const { TextArea } = Input;
    const { Option } = Select;
    const phoneItem = (value)=>{
      if (LayoutConfig.themeClassName === 'naminTravel-theme'){
        return (
          <a href={`https://wa.me/${value.replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`} className="contact-row content-link" rel="noopener noreferrer" target="_blank">
          <span className="margin-end-half itours-icon whatsapp icon-small" />
            <span dir="ltr">{value} ({Dictionary.WhatsAppOnly})</span>
          </a>
        );
      }
      return (
        <div className="contact-row">
          <span className="margin-end-half itours-icon red-phon-icon icon-small mirror-rtl" />
          <span dir="ltr">{value} </span>
        </div>
      )
    };
    return (
      <div className="section-vertical-padding bg-white contact-us">
        <div
          className={`itours-modal ${this.state.showModal ? "itours-modal visible" : ""}`}
        >
          <div className="modal-overlay" onClick={this.closeModal} />
          <div className="modal-inner small-width text-center">
            <div className="modal-main">
              {this.state.submitLoading ? (
                <div className="loading-outer-container">
                  <div>
                    <div className="loading-inner-container">
                      <div className="item-1"></div>
                      <div className="item-2"></div>
                      <div className="item-3"></div>
                      <div className="item-4"></div>
                      <div className="item-5"></div>
                    </div>
                    <div className="text-center">
                      {Dictionary.loadingData} ...
                    </div>
                  </div>
                </div>
              ) : (
                this.state.submitMessage && 
                <div>
                  <br/><br/>
                    {this.state.submitMessage.success?
                    <span className="itours-icon check-green icon-xlarge" />
                    :
                    <span className="itours-icon error-icon icon-xlarge" />
                    }
                  <div className="margin-top margin-bottom">
                  {this.state.submitMessage.text}.
                  </div>
                  <br/>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button color-1-btn"
                onClick={this.closeModal}
                disabled={this.state.submitLoading}
              >
                {Dictionary.ok}
              </button>
            </div>
          </div>
        </div>
        <div className="page-container">
          <h2 className="page-title"> {Dictionary.contactUs} </h2>
          <div className="float-row large-gap">
            <div className="col-small-12 col-medium-7">
              <div className="margin-bottom">
                <p>{Dictionary.contactP1}</p>
                <br />
                {/*                             <h4 className="normal-title">ایران - تهران</h4>
                                <div className="contact-row">
                                    <span className="margin-end-half itours-icon red-phon-icon icon-small mirror-rtl" />
                                    <span dir="ltr" className="en-font">(+98) 021 88712609</span>
                                </div>
                                <div className="contact-row">
                                    <span className="margin-end-half itours-icon red-email-icon icon-small" />
                                    <a href="mailto:info@sunhotel.ir" dir="ltr" className="en-font content-link">info@sunhotel.ir</a>
                                </div>
                                <div className="margin-bottom contact-row">
                                    <span className="margin-end-half itours-icon red-email-icon icon-small" />
                                    <span>تهران،خالد اسلامبولی،نبش خیابان سوم،ساختمان 222 پلاک 27 طبقه دوم واحد 2</span>
                                </div>
                                <br/> */}
                {/* <h4 className="normal-title">نروژ - اسلو</h4> */}

                
                {LayoutConfig.siteName==="itours-ir"?<>
                  <div className="itours-phoneNumber-row-item">
                    <div>
                      <span className="margin-end-half itours-icon icon-small red-phon-icon mirror-rtl" />
                      شنبه تا چهارشنبه، ساعت ۹ الی ۱۷
                      </div>
                    <div>
                      <div dir="ltr" className="no-wrap">+98 21 91008091</div>
                      {/* <div dir="ltr" className="no-wrap">+98 21 88104861</div> */}
                    </div>
                  </div>  
                  <div className="itours-phoneNumber-row-item">
                    <div>
                      <span className="margin-end-half itours-icon icon-small red-phon-icon mirror-rtl" />
                      شنبه تا چهارشنبه، ساعت ۱۷ الی ۲۳
                    </div>
                    <div dir="ltr" className="no-wrap">
                      <div>+98 901 899 59 03</div>
                      <div>+98 901 899 59 04</div>
                    </div>
                  </div> 
                  <div className="itours-phoneNumber-row-item">
                    <div>
                      <span className="margin-end-half itours-icon icon-small red-phon-icon mirror-rtl" />
                    	پنج شنبه، جمعه و تعطيلات، ساعت ۹ الی ۲۳
                    </div>
                    <div dir="ltr" className="no-wrap">
                      <div>+98 901 899 59 03</div>
                      <div>+98 901 899 59 04</div>
                    </div>
                  </div>                  
                </>
                :
                LayoutConfig.contacts && LayoutConfig.contacts.telephone && (LayoutConfig.contacts.telephone.length > 0) 
                  ? LayoutConfig.contacts.telephone.map(phone=><div key={phone}>{phoneItem(phone)}</div>)
                  :
                  phoneItem(LAN === "IR"? "(+98) 21 91008091": LAN === "AR"? "..." : LAN === "TR"? "...": "(+47)22112744")
                }
                <div className="contact-row">
                  <span className="margin-end-half itours-icon red-email-icon icon-small" />
                  <a
                    href={`mailto:${
                      LayoutConfig.contacts && LayoutConfig.contacts.email
                        ? LayoutConfig.contacts.email
                        : LAN === "IR"
                        ? "online@itours.ir"
                        : LAN ==="AR"
                        ? "..."
                        : LAN ==="TR"
                        ? "..."
                        : "info@itours.no"
                    }`}
                    dir="ltr"
                    className="en-font content-link"
                  >
                    {LayoutConfig.contacts && LayoutConfig.contacts.email
                      ? LayoutConfig.contacts.email
                      : LAN === "IR"
                      ? "online@itours.ir"
                      : LAN === "AR"
                      ? "..."
                      : LAN === "TR"
                      ? "..."
                      : "info@itours.no"}
                  </a>
                </div>
                <div className="contact-row">
                  <span className="margin-end-half itours-icon red-address-icon icon-small" />
                  <span>
                    {LayoutConfig.contacts && LayoutConfig.contacts.address
                      ? LayoutConfig.contacts.address
                      : LAN === "IR"
                      ? "تهران ، خیابان بهشتی ، خ وزرا پلاک ۲۷ ، واحد ۲۲"
                      : LAN === "AR"
                      ? "..."
                      : LAN === "TR"
                      ? "..."
                      : "(0183) - Torggata 31"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-small-12 col-medium-5">
              <div className="card margin-bottom">
                <Form onSubmit={this.handleSubmitContact}>

                <Form.Item label={Dictionary.fullName} className="form-group">
                    {getFieldDecorator('name', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                    })(
                      <Input
                        placeholder={Dictionary.fullName}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.email} className="form-group">
                    {getFieldDecorator('email', {
                      rules: [
                        { required: true, message: Dictionary.thisFieldIsRequired },
                        { type:"email", message: Dictionary.enteredEmailAddressIsNotValid }
                      ],
                    })(
                      <Input
                        placeholder={Dictionary.email}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.phoneNumber} className="form-group">
                    {getFieldDecorator('phoneNo', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired },
                        {pattern:"^[0-9]*$",message:Dictionary.onlyNumbersAreAllowed}]
                      })(
                      <Input
                        maxLength={15}
                        placeholder={Dictionary.phoneNumber}
                      />,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.Country} className="form-group">
                    {getFieldDecorator('country', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                    })(
                      <Select
                        showSearch
                        placeholder={Dictionary.Country}
                        dir="ltr"
                      >
                      {CountryCode.map((item) => (
                        <Option key={item.value} value={item.value}>{item.code}</Option>
                      ))}
                    </Select>,
                    )}
                  </Form.Item>
                  <Form.Item label={Dictionary.messageText} className="form-group">
                    {getFieldDecorator('message', {
                      rules: [{ required: true, message: Dictionary.thisFieldIsRequired }],
                    })(
                      <TextArea rows={4} placeholder={Dictionary.messageText} />,
                    )}
                  </Form.Item>

                  <div className="clearfix">
                    <button type="submit" className="button red-btn pull-end">
                      {Dictionary.send}
                    </button>
                  </div>

                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

const WrappedContactUS = Form.create({ name: 'contactUsForm' })(ContactUS);

export default connect(mapStateToProps)(WrappedContactUS);

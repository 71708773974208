//load  libraries
import React from "react";
import ReactGA from "react-ga";
import { Route, Switch,Redirect } from "react-router-dom";
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
//load Layout Part Components
import Header from "../components/Views/Header";
import NaminHeader from "../components/Views/NaminTravelHeader";
import NaminFooter from "../components/Views/NaminTravelFooter";
import Footer from "../components/Views/Footer";
import Home from "./Home";
import AccountWindow from "../components/Views/Account";
import ErrorShow from "../components/Views/Error";
import ReserveDetails from "../page/profile/ReserveDetails";
import FlightResult from "../page/flight/FlightResult";
import domesticFlightResult from "../page/flight/domesticFlightResult";
import AboutUS from "../page/US/AboutUS";
import Terms from "../page/US/Terms/Terms";
import ContactUS from "../page/US/ContactUS";
import GetPassengerFlight from "../page/flight/GetPassengerFlightNew";
import GetPassengerDomesticFlight from "../page/flight/GetPassengerDomesticFlightNew";
import GetPassengerPackage from "../page/package/GetPassengerPackage";
import UnderConstruction from "./UnderConstruction";
import { createUser, getUser,logoutUser } from "../Actions/UserAction";
import moment from "moment-jalaali";
import {
  toggleVarification,
  toggleErrorWindow,
  loadLanguage,
} from "../Actions/VisualDataAction";
import GaurdRouter from "../components/Common/GaurdRouter";
import Index from "../page/profile/Index";
import HotelResult from "../page/hotel/HotelResult";
import PackageResult from "../page/package/PackageResult";
import Confrim from "../page/profile/Confrim";
import ForgotPassword from "../page/profile/ForgotPassword";
import HotelDetails from "../page/hotel/HotelDetails";
import PackageDetails from "../page/package/PackageDetails";
import NotFound from "../page/NotFound";
import GetPassengers from "./hotel/GetPassengers";
import Reserve from "../page/payment/Reserve";
import Result from "../page/payment/Result";
import HotelIndex from "../page/hotel/HotelIndex";
import PackageIndex from "../page/package/PackageIndex";
// import DomesticFlightIndex from "../page/flight/domesticFlightIndex";
import ForeignFlightIndex from "../page/flight/foreignFlightIndex"; 
import MessageAccept from "../components/Common/MessageAccept";
import Loading from "../components/Common/Loading";
import padide81FakePageScrrenShot from "../image/padideh81.png";
import "antd/dist/antd.css";
import "../scss/layout.scss";
import LayoutConfig from "../Config/layoutConfig";
// Import React material
class Layout extends React.Component {

  logoutAfter5hours = () =>{
    const newTime = moment().format();
    const loginExpireTime = moment(localStorage.getItem("userTokenCreateTime")).add(5, 'hours').format();
    if (!(localStorage.getItem("userTokenCreateTime")) || (moment(newTime).isAfter(loginExpireTime))){
      this.props.logout();
      this.props.toggleVarification(false);
    }
  }

  componentDidMount() {
    this.initializeGA();
    this.logoutAfter5hours();
    this.props.getUser();
    let domain = process.env.REACT_APP_SERVER_DOMAIN;
    this.props.loadLanguage(domain);
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location && process.env.REACT_APP_SERVER_GA_ID){
      this.useGaTracker();
    }
    if (this.props.LAN && this.props.LAN === "IR") {
      document.body.classList.remove("ltr");
      document.body.classList.remove("arabic");
    } else if (this.props.LAN && this.props.LAN === "AR") {
      document.body.classList.remove("ltr");
      document.body.classList.add("arabic");
    } else {
      document.body.classList.add("ltr");
      document.body.classList.remove("arabic");
    }
    if (LayoutConfig.themeClassName) {
      document.body.classList.add(LayoutConfig.themeClassName);
    }
  }

  initializeGA = () => {
    const ga_id = process.env.REACT_APP_SERVER_GA_ID;
    if (ga_id) {
      ReactGA.initialize(ga_id,{debug:true});
      this.useGaTracker();
    }
  }

  useGaTracker = ()=>{
    ReactGA.pageview(this.props.location.pathname + this.props.location.search);
  }
  
  render() {
    const isUnderConstruction= process.env.REACT_APP_THEME==='NAMINTRAVELCOM___' || process.env.REACT_APP_THEME === "NORTRAVELSE";
    const {
      showAccountWindow,
      showErrorWindow,
      closableError,
      errorContent,
      registerMode,
      Verification,
    } = this.props;
    return !Verification && !this.props.LAN ? (
      <Loading fullPage/>
    ) : LayoutConfig.themeClassName === "padide-com-theme______" &&
      this.props.LAN === "IR" ? (
      <img
        src={padide81FakePageScrrenShot}
        alt="padide 81"
        className="full-width"
      />
    ) : (
      <div className={`container full`}>
        {showAccountWindow ? <AccountWindow mode={registerMode} /> : null}
        {showErrorWindow ? <ErrorShow closableError={closableError} icon={this.props.errorIcon} buttonLabel={this.props.errorButtonLabel}>{errorContent}</ErrorShow> : null}
        {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'? 
        <NaminHeader />: 
        <Header />
        }
        <main className="main-content">

          {isUnderConstruction?
              
                  <Switch>
                    <Route path="/" exact={true} component={Home} />
                    <Route path="/flight" exact={true} component={Home} />
                    <Route path="/hotel" exact={true} component={HotelIndex} />
                    <Route path="/package" exact={true} component={PackageIndex} />
                    <Route path="/underConstruction" exact={true} component={UnderConstruction} />
                    <Redirect from='/' to='/underConstruction' />
                  </Switch>
              
          :
              
                  <Switch>
                    <Route path="/" exact={true} component={Home} />
                                        
                    <Route path="/contact" component={ContactUS} />

                    <Route path="/about" component={AboutUS} />
                  
                    <Route path="/terms" component={Terms} />

                    <Route path="/flight" exact={true} component={Home} />
                    <Route path="/hotel" exact={true} component={HotelIndex} />
                    <Route path="/package" exact={true} component={PackageIndex} />
                    {/* <Route path="/domesticflight" exact={true} component={DomesticFlightIndex} /> */}
                    <Route path="/foreignflight" exact={true} component={ForeignFlightIndex} />                    
                    <Route path="/underConstruction" exact={true} component={UnderConstruction} />
                    <Route
                        path="/hotel/result"
                        exact={true}
                        component={HotelResult}
                    />                    
                    <Route
                        path="/package/result"
                        exact={true}
                        component={PackageResult}
                    />
                    <Route
                        path="/hotel/details"
                        exact={true}
                        component={HotelDetails}
                    />
                    <Route
                        path="/package/details"
                        exact={true}
                        component={PackageDetails}
                    />
                    <Route
                        path="/hotel/getPassengers"
                        exact={true}
                        component={GetPassengers}
                    />
                    <Route
                        path="/package/getPassengers"
                        exact={true}
                        component={GetPassengerPackage}
                    />
                    <GaurdRouter
                        component={Index}
                        exact={true}
                        path="/profile"
                        auth={Verification}
                    />
                    <Route path="/profile/details" component={ReserveDetails} />
                    <Route
                        component={Confrim}
                        path="/confrim"
                        auth={Verification}
                    />
                    <Route path="/ForgotPassword" component={ForgotPassword} />
                    <Route component={Reserve} exact={true} path="/payment/reserve" />
                    <Route component={Result} exact={true} path="/payment/result" />
                    <Route
                        component={FlightResult}
                        exact={true}
                        path="/flight/result"
                    />
                    <Route
                        component={domesticFlightResult}
                        exact={true}
                        path="/domesticflight/result"
                    />                    
                    <Route
                        component={GetPassengerFlight}
                        path="/flight/getpassenger"
                        exact={true}
                    />                  
                    <Route
                        component={GetPassengerDomesticFlight}
                        path="/domesticflight/getpassenger"
                        exact={true}
                    />
                    {/* {process.env.REACT_APP_THEME !=='NAMINTRAVELCOM' && <>
                      <Route path="/contact" component={ContactUS} />
                      <Route path="/terms" component={Terms} />
                      <Route path="/about" component={AboutUS} />
                    </>} */}

                    <Route component={NotFound} />
                  </Switch>
              
          }




        </main>
        {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'? 
        <NaminFooter />: 
        <Footer />
        }
        {LayoutConfig.themeClassName === "padide-com-theme" || (
          <MessageAccept />
        )}
      </div>
    );
  }
}
const MapStateToProps = (state) => {
  return {
    showAccountWindow: state.VisualData.showAccountWindow,
    showErrorWindow: state.VisualData.error.visibility,
    errorContent: state.VisualData.error.content,
    closableError:state.VisualData.error.closable,
    errorButtonLabel:state.VisualData.error.buttonLabel,
    errorIcon:state.VisualData.error.icon,
    registerMode: state.VisualData.registerMode,
    userToken: state.UserData.token,
    Verification: state.VisualData.Verification,
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
  };
};
const MapDispatchToProps = (dispatch) => ({
  createUser: (data) => dispatch(createUser(data)),
  toggleVarification: (data) => dispatch(toggleVarification(data)),
  toggleErrorWindow: (params) => dispatch(toggleErrorWindow(params)),
  getUser: (data) => dispatch(getUser(data)),
  loadLanguage: (data) => dispatch(loadLanguage(data)),
  logout: (data) => dispatch (logoutUser(data)),
});
export default withRouter(connect(MapStateToProps, MapDispatchToProps)(Layout));

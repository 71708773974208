import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import moment from "moment-jalaali";
import { ServerAddress } from "../../Enum/Urls";
import {
  getBankList,
  setBankID,
  paymentByMethod,
  paymentByMethodForDomesticFlight,
  GetReserveById
} from "../../Actions/PaymentAction";
import { editMessage,showError } from "../../Actions/VisualDataAction";
import {
  getReserveByID,
  bookhotel,
  selectedHotel,
} from "../../Actions/HotelAction";
import {
  bankStatus,
  setFlightSelected,
  returnFlightReserveById,
  setSafarmarketPixel,
  setDirectTicketByIdResult,
  getTime
} from "../../Actions/FlightAction";
import RemainingTime from "../../components/Common/RemainingTime";
import paymentError from "../../image/payment-error.svg";
import { Steps } from "antd";
import { Payment } from "../../Enum/Models";
import FlighSummary from "../../components/Flight/FlighSummary";
import SummaryHotel from "../../components/Accomodation/SummaryHotel";
import PackageSummary from '../../components/Package/PackageSummary';
import PaymentList from "../../components/Common/PaymentList";
import Loading from "../../components/Common/Loading";
import Price from "../../components/Common/Price";
import LayoutConfig from "../../Config/layoutConfig";
class Reserve extends React.Component {
  state = {
    selectedBank: false,
    showError: false,
    isFlight: false,
    farePrice: null,
    reserveId:undefined,
    directTicketByIdLoading:false,
    expirationTime:undefined,
    startTime:undefined,
    safarmarketSelectBank:false,
    paymentError:false
  };

  updateBankStatus = async(data)=>{
    const response = await bankStatus(data);
    return (response);
  }

  updateStartTime = async()=>{
    const response = await getTime();
    if (response.data.result){
      this.setState({startTime:response.data.result})
    }
  }

  componentDidMount() {
    document.addEventListener('visibilitychange', ()=>{
      if (document.visibilityState === "visible"){
        this.updateStartTime();
      }
    });
    this.props.setDirectTicketByIdResult(undefined);
    window.scrollTo(0, 0);
    this.props.editMessage(false);
    //
    let parameters = queryString.parse(this.props.location.search);
    this.setState({reserveId:parameters.reserveId});
    //get payment
    if (this.props.LAN === "IR") {
      parameters.acceptLanguage = "fa-IR";
    }
    this.props.getBankList(parameters);
    //get information
    this.props.setFlightSelected(null);
    this.props.selectedHotel(null);
    let params = new URLSearchParams();
    params.append("reserveId", parameters.reserveId);
    parameters.username && params.append("username", parameters.username);
    this.props.GetReserveById(params, parameters);
    let status = parseInt(parameters.status);
    if (status > 0) {      
      let param = new URLSearchParams();
      param.append("reserveId", parameters.reserveId);
      parameters.username && param.append("username", parameters.username);
      const fetchData = async () => {
        this.setState({directTicketByIdLoading:true});
        const response = await returnFlightReserveById(param);
        if (response.data && response.data.result.isDomestic) {              
          this.props.paymentByMethodForDomesticFlight(params);
        }else{
          this.props.paymentByMethod(params);
        }
      };
      fetchData();
      
      
      // if(this.props.hotelInfo!==null){
      //     this.props.bookhotel({reserveId:parameters.reserveId,userName:parameters.username});
      // }else{
      //     let param=new URLSearchParams();
      //     param.append("reserveId",parameters.reserveId);
      //     this.props.getDirectTicketByID(param)
      // }
      // this.props.history.push(
      //   `/profile/details?userName=${parameters.username}&reserveId=${parameters.reserveId}`
      // );
    } else if (status === 0) {
      if (parameters.smid && this.props.currencyCode==="IRR"){
        this.updateBankStatus({reserveId:+parameters.reserveId,status:1});
      }
      this.setState({ showError: true,paymentError:true });
    } else {
      this.setState({ showError: false });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevState.startTime !== this.state.startTime){
      console.log(this.state.startTime);
    }
    if (this.props.respond && this.state.selectedBank) {
      this.props.editMessage(false);
      window.location.href = `${ServerAddress.Type}${ServerAddress.Payment}/en/Reserves/Payment/PaymentRequest?tokenId=${this.props.tokenId}`;
    }
    if (
      this.props.flightInfo !== null &&
      this.props.flightInfo !== prevProps.flightInfo
    ) {
      let perPrice = [];
      this.props.flightInfo.passengersFare.forEach((item) => {
        perPrice[item.passengerTypeQuantity.code] = {
          totalTaxes: item.fare.totalTaxes,
          totalFare: item.fare.totalFare,
          baseFare: item.fare.baseFare,
        };
      });
      let passengerInfo = this.props.flightInfo.passengers;
      passengerInfo.forEach((itemPass) => {
        itemPass["baseFare"] = perPrice[itemPass.code].baseFare;
        itemPass["totalTaxes"] = perPrice[itemPass.code].totalTaxes;
        itemPass["totalFare"] = perPrice[itemPass.code].totalFare;
      });
      let expTime = undefined;
      const duration = moment.utc(this.props.flightInfo.expirationTime).diff(this.props.flightInfo.currentTime || Date.now());
      if(this.props.flightInfo.reserveStatus === Payment.Pending ){
        if (this.props.flightInfo.paymentBeforePNR){
          expTime=this.props.flightInfo.expirationTime;
        }else if (duration > 20*60*1000){
          expTime=moment(this.props.flightInfo.currentTime || Date.now()).add(20, 'minutes').format();
        }
      }
      this.setState({
        farePrice: passengerInfo,
        expirationTime:expTime,
        startTime:this.props.flightInfo.currentTime
      });

      let parameters = queryString.parse(this.props.location.search);

      //priceChange message:
      if (this.props.flightInfo.priceChangedAtReserve && parameters.status!=="0" && parameters.status!=="1"){
        this.props.showError({
          visibility:true,
          content:<div>
              {this.props.Dictionary.ThePriceOfThisFlightHasChanged}.
              <br/>
              <h5 className="normal-title">{this.props.Dictionary.newPrice}: <span dir="ltr"><Price currency={this.props.currencyCode}>
                  {this.props.flightInfo.flightFare.totalFare}
                </Price></span>
              </h5>
            </div>,
          closable:true,
          buttonLabel:this.props.Dictionary.ok
        });
      }

      //safarmarket:
      if (parameters.smid && this.props.currencyCode==="IRR"){
        if (parseInt(parameters.status)===0){
          this.setSMPixel(this.props.flightInfo,parameters.smid,5);
        }else if (parseInt(parameters.status)===1){
          this.setSMPixel(this.props.flightInfo,parameters.smid,4);
        }else{
          this.setState({safarmarketSelectBank:true})
        }
      }
    }
    if(this.props.directTicketResult !== prevProps.directTicketResult){
      this.setState({directTicketByIdLoading:false});
      
      this.props.editMessage(false);
      //
      let parameters = queryString.parse(this.props.location.search);
      if (this.props.LAN === "IR") {
        parameters.acceptLanguage = "fa-IR";
      }
      //update information
      this.props.setFlightSelected(null);
      let params = new URLSearchParams();
      params.append("reserveId", parameters.reserveId);
      parameters.username && params.append("username", parameters.username);
      this.props.GetReserveById(params, parameters);

    }
  }

  componentWillUnmount(){
    this.props.setSafarmarketPixelValue(undefined);
  }
  
  bankSelect = async(id, param) => {
    this.props.editMessage(false);
    this.setState({
      selectedBank: true,
    });
    let reserveID = queryString.parse(this.props.location.search);
    let callbackUrl = `${ServerAddress.Type}${ServerAddress.localServer}/payment/reserve`;
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.smid){
      callbackUrl += `?smid=${parameters.smid}`;
    }
    let params = {
      ...reserveID,
      gatewayId: id,
      callbackUrl: callbackUrl,
      ipAddress: ServerAddress.localIP,
      formParams: { ...param },
    };
    if (this.state.safarmarketSelectBank){
      if (this.props.flightInfo){
        this.setSMPixel(this.props.flightInfo,parameters.smid,3);
      }
      const response = await bankStatus({reserveId:+parameters.reserveId,status:0});
      if (response.data && response.data.success){
        this.props.setBankID(params);
      }else{
        debugger;
      }
    }else{
      this.props.setBankID(params);
    }
  };
  phoneItem = (value)=>{
    if (LayoutConfig.themeClassName === 'naminTravel-theme'){
      return (
        <a target="_blank" rel="noreferrer" href={`https://wa.me/${value.replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`} className="pull-end" dir="ltr"  >
        <span className="margin-end-half itours-icon whatsapp icon-small" />
          <span dir="ltr">{value} ({this.props.Dictionary.WhatsAppOnly})</span>
        </a>
      );
    }
    return (
      <span className="pull-end" dir="ltr" >
        <span className="margin-right-light itours-icon red-phon-icon icon-small" />
        <span dir="ltr">{value} </span>
      </span>
    )
  };

  setSMPixel = (reserveInformation,smId,paymentStatus) =>{
    const pixel = {};
    const reserveId = queryString.parse(this.props.location.search).reserveId;
    const firstSegment = reserveInformation.airItinerary.originDestinationOptions[0]?.flightSegments[0];        
    //pixel.pnr = (!reserveInformation.pnrCode || reserveInformation.pnrCode.toLowerCase().includes("not set") )?reserveId:reserveInformation.pnrCode;
    pixel.pnr=reserveId;
    pixel.flight_number = firstSegment.flightNumber;
    pixel.fromCode = firstSegment.departureAirport.locationCode;
    pixel.toCode = firstSegment.arrivalAirport.locationCode;
    pixel.departureTime = moment(firstSegment.departureDateTime).format("YYYY-MM-DD HH:mm");
          
    if(reserveInformation.airTripType === "RoundTrip"){
      const returnFirstSegment = reserveInformation.airItinerary.originDestinationOptions[1]?.flightSegments[0];
      pixel.returnTime= moment(returnFirstSegment.departureDateTime).format("YYYY-MM-DD HH:mm");
      pixel.returnFlightType =reserveInformation.flightType;
      pixel.returnFlightNumber = returnFirstSegment.flightNumber;
    }else{
      pixel.returnTime="";
      pixel.returnFlightType ="";
      pixel.returnFlightNumber = "";
    }

    let flightType="";
    if (reserveInformation.flightType && reserveInformation.flightType.toLowerCase() ==="systemic"){
      flightType="SYSTEM";
    }else if (reserveInformation.flightType && reserveInformation.flightType.toLowerCase() ==="charter"){
      flightType="CHARTER";
    }else if(reserveInformation.flightType){
      flightType = reserveInformation.flightType.toUpperCase();
    }
    pixel.flightType = flightType;
    pixel.adults = reserveInformation.passengers.filter(passenger=>passenger.code==="ADT").length;
    pixel.children = reserveInformation.passengers.filter(passenger=>passenger.code==="CHD").length;
    pixel.infants = reserveInformation.passengers.filter(passenger=>passenger.code==="INF").length;

    pixel.passengerName = reserveInformation.reserver.firstName + " " + reserveInformation.reserver.lastName;
    let passengerPhoneNumber= reserveInformation.reserver.phoneNumber;
    if (passengerPhoneNumber.toLowerCase().includes('0098')){
      passengerPhoneNumber = passengerPhoneNumber.replace("0098", "0");
    } else if(passengerPhoneNumber.toLowerCase().includes('+98')){
      passengerPhoneNumber = passengerPhoneNumber.replace("+98", "0");
    }
    if (passengerPhoneNumber.indexOf("0")!==0){
      passengerPhoneNumber = "0"+ passengerPhoneNumber;
    }
    pixel.passengerPhone = passengerPhoneNumber;
    pixel.passengerEmail = reserveInformation.reserver.email;

    pixel.oneAdultPrice = reserveInformation.passengersFare.filter(item => item.passengerTypeQuantity.code==="ADT")[0]?.fare.totalFare;
    pixel.totalPrice = reserveInformation.flightFare.totalFare;       
    
    this.props.setSafarmarketPixelValue(encodeURI(`https://safarmarket.com/api/v1/trace/pixel/itours/${paymentStatus}/?smId=${smId}&pnr=${pixel.pnr}&fliNo=${pixel.flight_number}&typ=${pixel.flightType}&pri=${pixel.oneAdultPrice}&pax=${pixel.totalPrice}&adu=${pixel.adults}&chi=${pixel.children}&inf=${pixel.infants}&from=${pixel.fromCode}&to=${pixel.toCode}&dep=${pixel.departureTime}&ret=${pixel.returnTime}&nam=${pixel.passengerName}&pho=${pixel.passengerPhone}&ema=${pixel.passengerEmail}&rFTyp=${pixel.returnFlightType}&rPnr=&rFliNo=${pixel.returnFlightNumber}&bck=false`));
  }

  render() {
    const { Dictionary, LAN } = this.props;
    // let checkIn=null;
    // let checkOut=null;
    let night = 0;
    let totalPrice = 0;
    const { respond, banks, hotelInfo, flightInfo ,packageInfo,directTicketResult} = this.props;
    const { Step } = Steps;
    if (respond) {
      if (hotelInfo != null) {
        night = hotelInfo.date.duration;
        // let cashDateIN=new Date(hotelInfo.date.checkIn);
        // checkIn=cashDateIN.getFullYear()+"/"+(cashDateIN.getMonth()+1)+"/"+cashDateIN.getDate();
        // let cashDateOUT=new Date(hotelInfo.date.checkOut);
        // checkOut=cashDateOUT.getFullYear()+"/"+(cashDateOUT.getMonth()+1)+"/"+cashDateOUT.getDate();
        if (hotelInfo.accommodation) {
          hotelInfo.accommodation.rooms.forEach((item) => {
            totalPrice += parseFloat(item.salePrice);
          });
        }
      }
    }
    return !respond ? (
      <Loading fullPage description={this.props.directTicketResult?Dictionary.loadingData:Dictionary.ChooseYourPaymentMethod+" ..."}/>
      // <div className="loading-outer-container">
      //   <div>
      //     <img
      //       src={LayoutConfig.logo ? LayoutConfig.logo : siteLogo}
      //       className="loading-logo"
      //       alt={LayoutConfig.companyName ? LayoutConfig.companyName : "itours"}
      //     />
      //     <div className="loading-inner-container">
      //       <div className="item-1" />
      //       <div className="item-2" />
      //       <div className="item-3" />
      //       <div className="item-4" />
      //       <div className="item-5" />
      //     </div>
      //     <div className="text-center">
      //       {Dictionary.ChooseYourPaymentMethod} ...
      //     </div>
      //   </div>
      // </div>
    ) : (
      // loading
      <main className="main-content">
        <div className="page-padding">
          <div className="page-container">
            {(hotelInfo || flightInfo || packageInfo) && (
              <>
                {directTicketResult ? null : <div>
                  <Steps current={2}>
                    <Step title={Dictionary.chooseFlightsOrHotels} />
                    <Step title={Dictionary.enterThePassengersInformation} />
                    <Step title={Dictionary.paymentTypeOptions} />
                  </Steps>
                </div>}

                <div className="sides-outer-holder">
                  <div className="sides-holder reverse">
                    <div className="small-side">
                      <div className="side-item full-width">
                        <div className="side-item-inner">
                          {flightInfo && (
                            <FlighSummary
                              showFlightAllDetails
                              itinTotalFare ={flightInfo.flightFare}
                              providerName={flightInfo.providerName}
                              model={flightInfo.airItinerary.originDestinationOptions}
                              fareInfo={flightInfo.passengersFare}
                              totalPrice={flightInfo.flightFare.totalFare}
                              currencyCode={this.props.currencyCode}
                              showButton={false}
                              feild={"fare"}
                            />
                          )}
                          {hotelInfo && hotelInfo.accommodation !== undefined && (
                            <SummaryHotel
                              model={hotelInfo}
                              currencyCode={this.props.currencyCode}
                              showButton={false}
                              totalPrice={totalPrice}
                              night={night}
                              cancellationPolicy={
                                hotelInfo.cancellationPolicy
                              }
                            />
                          )}
                          {packageInfo && (
                            <PackageSummary
                            currencyCode={this.props.currencyCode}
                            flightModel={packageInfo.reserveFlightInfo.airItinerary.originDestinationOptions}
                            hotelModel={packageInfo.reserveHotelInfo}
                            night={packageInfo.reserveHotelInfo.date.duration}
                            cancellationPolicy={packageInfo.reserveHotelInfo.cancellationPolicy}

                            flightPrice={packageInfo.reserveFlightInfo.flightFare.totalFare}                              
                            />
                          )}
                          
                          {this.state.expirationTime && !this.state.paymentError && <RemainingTime startTime={this.state.startTime} expirationTime={this.state.expirationTime} />}
                                 
                          {
                          (
                              (hotelInfo && hotelInfo.status === Payment.Pending) 
                            ||
                              (packageInfo && packageInfo.reserveFlightInfo.reserveStatus === Payment.Pending && packageInfo.reserveHotelInfo.status === Payment.Pending ) 
                            ||
                              (flightInfo && flightInfo.reserveStatus === Payment.Pending)
                          ) && (<div className="box-border top-color-border orange margin-top bg-white">
                            <div className="colored">
                              {Dictionary.pricesToRiseSoon}!
                            </div>
                            <div className="font-12">
                              {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}.
                            </div>
                          </div>)}

                     
                        </div>
                      </div>
                    </div>
                    <div className="large-side">
                      <div className="side-item full-width">
                        <div className="side-item-inner">

                          {this.state.directTicketByIdLoading ?
                            <Loading className="margin-bottom-large" />
                          :
                          directTicketResult && flightInfo && (directTicketResult!=="Error") && <div className={`${(directTicketResult==="PnrNotMade")?"red-card":"green-card"} card card-padding message-block box-border margin-bottom`}>
                            {(directTicketResult==="PNRIssued")?
                            <>
                              <p className="semi-bold font-18">
                                <span className="itours-icon check-green margin-end-half" />
                                {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
                                {Dictionary.YourTicketHasBeenIssuedSuccessfully}.
                              </p>
                              <div className="clearfix">
                                <a
                                href={
                                  LayoutConfig.themeClassName === 'naminTravel-theme'?
                                  `https://voucher.namintravel.com/en/flight/Ticket2?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                                  : (LAN ==="IR")?
                                  `https://voucher.itours.ir/en/flight/irTicket?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                                  :
                                  `https://voucher.imt.as/en/flight/Ticket?reserveid=${this.state.reserveId}&username=${flightInfo.reserver.username}`
                                }
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  download
                                  className="button color-1-btn pull-end"
                                >
                                  {Dictionary.ticket}
                                </a>                              
                              </div>
                            </>
                            :(directTicketResult==="PnrNotMade")?<>
                              <p className="semi-bold font-18">
                                <span className="itours-icon circle-error margin-end-half" />
                                {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
                                {Dictionary.SorryYourTicketWasNotIssued}.
                              </p>
                              <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
                              <span className="inline-block">
                                {
                                  LayoutConfig.themeClassName === 'naminTravel-theme'?
                                  Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                                  :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
                                }
                                :
                              </span>
                              {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
                              : this.phoneItem(Dictionary.supportNumber)}   
                            </>
                            :<>
                              <p className="semi-bold font-16">
                                <span className="itours-icon check-green margin-end-half" />
                                {LAN==="IR" && <span className="margin-end-light">{flightInfo.reserver.firstName} عزیز،</span>}
                                {Dictionary.YourTicketHasBeenSuccessfullyRegisteredAndOurSupportTeamIsIssuingItYourTicketWillBeSentToYouAsSoonAsItIsIssued}
                              </p>  
                              <div className="semi-bold font-18 margin-bottom">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
                              <span className="inline-block">
                                {
                                  LayoutConfig.themeClassName === 'naminTravel-theme'?
                                  Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                                  :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
                                }
                                :
                              </span>
                              {LayoutConfig.contacts?.telephone?.length ? this.phoneItem (LayoutConfig.contacts.telephone[0])
                              : this.phoneItem(Dictionary.supportNumber)} 
                            </>}
                          </div>}

                          {this.state.reserveId && !this.state.directTicketByIdLoading && !this.state.paymentError && <div className="bg-white card card-padding message-block box-border margin-bottom">
                          <div className="semi-bold font-20">{Dictionary.ReservationNumber} : {this.state.reserveId}</div>
                          </div>}

                          {this.state.paymentError? <div className="card card-padding message-block error-message box-border margin-bottom">
                            <h3 className="normal-title">
                              <span className="itours-icon icon-large red-info-icon margin-end-half" />
                              {Dictionary.unfortunatelyPaymentIsFailed}.
                            </h3>
                            <div className="semi-bold margin-bottom-half">{Dictionary.trackingNumber} : {this.state.reserveId}</div>
                            <p>
                              {
                                LayoutConfig.themeClassName === 'naminTravel-theme'?
                                this.props.Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                                :this.props.Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
                              }:
                            </p>
                            <div className="clearfix">
                              {LayoutConfig.contacts &&
                              LayoutConfig.contacts.telephone &&
                              LayoutConfig.contacts.telephone.length > 0
                              ? this.phoneItem (LayoutConfig.contacts.telephone[0])
                              : this.phoneItem(Dictionary.supportNumber)}
                            </div>
                          </div> 
                          :
                          LAN === "IR" ? (                            
                              ((flightInfo && flightInfo.isDomestic) || directTicketResult || this.state.directTicketByIdLoading )?
                              null:
                              <div className="card card-padding message-block error-message box-border margin-bottom">
                              <h3 className="normal-title">
                                <span className="itours-icon icon-large red-info-icon margin-end-half" />
                                رزرو شما با موفقیت در سیستم ثبت شد
                              </h3>
                              <p>
                                با توجه به شرایط کنونی به وجود امده به دلیل
                                کووید ۱۹ لطفا قبل از پرداخت مبلغ رزرو خود با
                                شماره  
                                <span dir="ltr" className="margin-right-light margin-left-light">(+98) 21 91008091</span>
                                 تماس بگیرید.
                              </p>
                            </div>                            

                          ) : (
                            <>
                              {this.state.showError && (
                                <div className="card card-padding bg-white box-border margin-bottom text-center">
                                  <div className="margin-bottom">
                                    <img
                                      src={paymentError}
                                      alt=""
                                      className="payment-icon"
                                    />
                                    <br />
                                    <strong className="purple margin-bottom">
                                      {Dictionary.paymentOperationFailed}.
                                    </strong>
                                  </div>
                                  <div className="margin-bottom">
                                    <strong>{Dictionary.pleaseTryAgain}</strong>
                                  </div>
                                  <p>
                                    {Dictionary.YouCanCallsupportNumberForFurtherTracking} .
                                  </p>
                                </div>
                              )}

                              {(
                                (hotelInfo && hotelInfo.status === Payment.Pending)
                                  ||
                                (flightInfo && flightInfo.reserveStatus ===Payment.Pending)
                                ) && (
                                <div className="card card-padding message-block success-message box-border margin-bottom">
                                  <h3 className="normal-title">
                                    <span className="itours-icon icon-large check-green margin-end-half" />
                                    {Dictionary.YourReservationIsReadyToBePaid}
                                  </h3>
                                  <p>
                                    {
                                      Dictionary.pleaseMakeAReservationAtTheSpecifiedTimeAfterWhichYourReservationWillBeSuspended
                                    }
                                    .
                                    {
                                      Dictionary.ifSoContactYourBackupToCompleteTheProcess
                                    }
                                  </p>
                                </div>
                              )}
                            </>
                          )}

                          {/*reserver information*/}
                          {flightInfo && (
                            <div
                              className={
                                "bg-white box-border card-padding Reserver information margin-bottom"
                              }
                            >
                              <h5 className="normal-title">
                                {Dictionary.ReserverInformartion}
                              </h5>
                              <div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.gender}:
                                  </span>
                                  {flightInfo.reserver.gender
                                    ? Dictionary.male
                                    : Dictionary.female}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.firstName}:
                                  </span>
                                  {flightInfo.reserver.firstName}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.lastName}:
                                  </span>
                                  {flightInfo.reserver.lastName}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.email}:
                                  </span>
                                  {flightInfo.reserver.email}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.phoneNumber}:
                                  </span>
                                  {flightInfo.reserver.phoneNumber}
                                </div>
                              </div>
                            </div>
                          )}
                          {hotelInfo && hotelInfo.reserver && (
                            <div
                              className={
                                "bg-white box-border card-padding Reserver information margin-bottom"
                              }
                            >
                              <h5 className="normal-title">
                                {Dictionary.ReserverInformartion}
                              </h5>
                              <div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.gender}:
                                  </span>
                                  {hotelInfo.reserver.gender
                                    ? Dictionary.male
                                    : Dictionary.female}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.firstName}:
                                  </span>
                                  {hotelInfo.reserver.firstName}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.lastName}:
                                  </span>
                                  {hotelInfo.reserver.lastName}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.email}:
                                  </span>
                                  {hotelInfo.reserver.email}
                                </div>
                                <div>
                                  <span className="item-label margin-end-light">
                                    {Dictionary.phoneNumber}:
                                  </span>
                                  {hotelInfo.reserver.phoneNumber}
                                </div>
                              </div>
                            </div>
                          )}

                          {packageInfo && packageInfo.reserveFlightInfo.reserver && (
                           <div
                           className={
                             "bg-white box-border card-padding Reserver information margin-bottom"
                           }
                         >
                           <h5 className="normal-title">
                             {Dictionary.ReserverInformartion}
                           </h5>
                           <div>
                             <div>
                               <span className="item-label margin-end-light">
                                 {Dictionary.gender}:
                               </span>
                               {packageInfo.reserveFlightInfo.reserver.gender
                                 ? Dictionary.male
                                 : Dictionary.female}
                             </div>
                             <div>
                               <span className="item-label margin-end-light">
                                 {Dictionary.firstName}:
                               </span>
                               {packageInfo.reserveFlightInfo.reserver.firstName}
                             </div>
                             <div>
                               <span className="item-label margin-end-light">
                                 {Dictionary.lastName}:
                               </span>
                               {packageInfo.reserveFlightInfo.reserver.lastName}
                             </div>
                             <div>
                               <span className="item-label margin-end-light">
                                 {Dictionary.email}:
                               </span>
                               {packageInfo.reserveFlightInfo.reserver.email}
                             </div>
                             <div>
                               <span className="item-label margin-end-light">
                                 {Dictionary.phoneNumber}:
                               </span>
                               {packageInfo.reserveFlightInfo.reserver.phoneNumber}
                             </div>
                           </div>
                         </div>
                          )}
                          {/*passenger information*/}
                          {flightInfo && flightInfo.passengers && (
                            <div className="bg-white box-border card-padding Reserver information margin-bottom">
                              <h5 className="normal-title">
                                {Dictionary.PassengersInformation}
                              </h5>
                              <table className="full-width">
                                <thead>
                                  <tr>
                                    <th>{Dictionary.firstName} - {Dictionary.lastName}</th>
                                    <th>{Dictionary.type}</th>
                                    {LayoutConfig.themeClassName === 'naminTravel-theme' ? <>
                                      <th>{Dictionary.wheelchair}</th>
                                      <th>{Dictionary.specialDiet}</th>
                                    </>:
                                    <>
                                      <th>{Dictionary.birthday}</th>
                                      {flightInfo.passengers.some(passItem=>passItem.nationalId && passItem.nationalId!=="0") ? 
                                        <th>
                                          {Dictionary.nationalCode}
                                        </th>
                                      :
                                      <>
                                        {flightInfo.passengers.some(passItem=>passItem.passportNumber) && <th>
                                          {Dictionary.passportNumber}
                                        </th>}
                                        {flightInfo.passengers.some(passItem=>passItem.passportExpireDate) && <th>
                                          {Dictionary.passportExpDate}
                                        </th>}                                      
                                      </>  
                                      } 
                                    </>}
                                    <th>
                                      {Dictionary.fare}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {flightInfo.passengers.map((item, index) => <tr key={index}>
                                    <td>
                                      {item.title ? "Mr " : "Mrs"}{" "}
                                      {item.firstName} {item.lastName}
                                    </td>
                                    <td>
                                      {item.code === "ADT" && Dictionary.adult}
                                      {item.code === "CHD" && "Child"}
                                      {item.code === "INF" && "Infant"}                                      
                                    </td>                                    
                                    {LayoutConfig.themeClassName === 'naminTravel-theme' ?
                                      <>
                                        <td>{item.hasWheelChair?<span className="itours-icon green-checkmark icon-small" />:"-----"}</td>
                                        <td>{item.mealType?item.mealType:"-----"}</td>
                                      </>
                                    :
                                      <>
                                        <td>{moment(item.birthDate).format(flightInfo.isDomestic && (item.nationality == 105 || item.nationality==="IR")?"jYYYY/jM/jD":"YYYY-MM-DD")}</td>
                                        {flightInfo.passengers.some(passItem=>passItem.nationalId && passItem.nationalId!=="0") ? 
                                          <td>{item.nationalId || "--"}</td>
                                        :
                                          <>
                                            {flightInfo.passengers.some(passItem=>passItem.passportNumber) && <td>{item.passportNumber || "--"}</td>}
                                            {flightInfo.passengers.some(passItem=>passItem.passportExpireDate) && <td>{moment(item.passportExpireDate).format("YYYY-MM-DD") || "-----"}</td>}
                                          </>
                                        }
                                      </>
                                    }
                                    <td>
                                      <Price currency={this.props.currencyCode}>
                                        {item.totalFare}
                                      </Price>
                                    </td>
                                  </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}

                          {packageInfo?.reserveFlightInfo?.passengers && (
                            <div className="bg-white box-border card-padding Reserver information margin-bottom">
                              <h5 className="normal-title">
                                {Dictionary.PassengersInformation}
                              </h5>
                              <table className="full-width">
                                <thead>
                                  <tr>
                                    <th>{Dictionary.firstName} - {Dictionary.lastName}</th>
                                    <th>{Dictionary.type}</th>
                                    <th>{Dictionary.birthday}</th>
                                    {packageInfo.reserveFlightInfo.passengers.some(passItem=>passItem.passportNumber) && <th>
                                      {Dictionary.passportNumber}
                                    </th>}
                                    {LayoutConfig.themeClassName === 'naminTravel-theme' && <>
                                      <th>{Dictionary.wheelchair}</th>
                                      <th>{Dictionary.specialDiet}</th>
                                    </>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {packageInfo.reserveFlightInfo.passengers.map((item, index) => <tr key={index}>
                                    <td>
                                      {item.title ? "Mr " : "Mrs"}{" "}
                                      {item.firstName} {item.lastName}
                                    </td>
                                    <td>
                                      {item.code === "ADT" && Dictionary.adult}
                                      {item.code === "CHD" && "Child"}
                                      {item.code === "INF" && "Infant"}                                      
                                    </td>
                                    <td>{moment(item.birthDate).format("YYYY-MM-DD")}</td>
                                    {packageInfo.reserveFlightInfo.passengers.some(passItem=>passItem.passportNumber) &&<td>{item.passportNumber || "-----"}</td>}
                                    {LayoutConfig.themeClassName === 'naminTravel-theme' && <>
                                    <td>{item.hasWheelChair?<span className="itours-icon green-checkmark icon-small" />:"-----"}</td>
                                    <td>{item.mealType?item.mealType:"-----"}</td>
                                    </>}
                                  </tr>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          )}
                          {/* {hotelInfo &&
                          hotelInfo.accommodation.rooms.map(
                            (item, index) => {
                              return (
                                <>
                                  {item.passengers &&
                                    item.passengers.map((passItem) => {
                                      return (
                                        <div
                                          className="float-row"
                                          key={index}
                                        >
                                          <div className="col-small-12 col-medium-6">
                                            {passItem.gender
                                              ? "Mr "
                                              : "Mrs "}{" "}
                                            {passItem.firstname}{" "}
                                            {passItem.lastname}
                                          </div>
                                          <div className="col-small-12 col-medium-2">
                                            {passItem.ageCategory &&
                                            passItem.ageCategory ===
                                              "Adult"
                                              ? Dictionary.adult
                                              : passItem.ageCategory ===
                                                "Child"
                                              ? Dictionary.child
                                              : passItem.ageCategory}
                                          </div>
                                          <div className="col-small-12 col-medium-4">
                                            <Price
                                              currency={
                                                this.props.currencyCode
                                              }
                                            >
                                              {item.salePrice}
                                            </Price>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </>
                              );
                            }
                          )} */}

                          {/*{hotelInfo && (hotelInfo.accommodation !== undefined) && (hotelInfo.reservers) &&*/}
                          {/*<div className={"bg-white box-border card-padding Reserver information margin-bottom"}>*/}
                          {/*    <h5 className="normal-title">{Dictionary.ReserverInformartion}</h5>*/}
                          {/*    <div className="float-row">*/}
                          {/*        <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.gender}: </span> {hotelInfo.reserver.gender?Dictionary.male:Dictionary.female}</div>*/}
                          {/*        <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.firstName}: </span> {hotelInfo.reserver.firstName}</div>*/}
                          {/*        <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.lastName}: </span> {hotelInfo.reserver.lastName}</div>*/}
                          {/*        <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.email}: </span> {hotelInfo.reserver.email}</div>*/}
                          {/*        <div className="col-small-12 col-medium-4"><span className="item-label">{Dictionary.phoneNumber}: </span> {hotelInfo.reserver.phoneNumber}</div>*/}
                          {/*    </div>*/}
                          {/*</div>                                                    */}
                          {/*}*/}
                          {!directTicketResult && !this.state.paymentError && <div className="choicePayment margin-bottom">
                            {(
                              (hotelInfo && hotelInfo.status === Payment.Pending) ||
                              (packageInfo && (packageInfo.reserveFlightInfo.reserveStatus === Payment.Pending) && (packageInfo.reserveHotelInfo.status === Payment.Pending) ) ||
                              (flightInfo && flightInfo.reserveStatus === Payment.Pending)) && (
                              <div className="bankSelect">
                                <h5 className="normal-title">
                                  {Dictionary.ChooseYourPaymentMethod}                                  
                                </h5>
                                <div>
                                  {!banks ? (
                                    // <div className="loading-outer-container">
                                    //   <div>
                                    //     <img
                                    //       src={
                                    //         LayoutConfig.logo
                                    //           ? LayoutConfig.logo
                                    //           : siteLogo
                                    //       }
                                    //       className="loading-logo"
                                    //       alt={
                                    //         LayoutConfig.companyName
                                    //           ? LayoutConfig.companyName
                                    //           : "itours"
                                    //       }
                                    //     />
                                    //     <div className="loading-inner-container">
                                    //       <div className="item-1" />
                                    //       <div className="item-2" />
                                    //       <div className="item-3" />
                                    //       <div className="item-4" />
                                    //       <div className="item-5" />
                                    //     </div>
                                    //     <div className="text-center">
                                    //       {Dictionary.loadingPaymentInformation}
                                    //       ...
                                    //     </div>
                                    //   </div>
                                    // </div>
                                    <Loading description={Dictionary.loadingPaymentInformation+" ..."} />
                                  ) : (
                                    <PaymentList
                                      banks={banks}
                                      selectedBankthis={this.bankSelect}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </div>}

                          <div className="card-padding bg-white box-border clearfix">
                            <span className="inline-block">
                              {
                                LayoutConfig.themeClassName === 'naminTravel-theme'?
                                Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                                :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
                              }
                              :
                            </span>
                            {LayoutConfig.contacts &&
                            LayoutConfig.contacts.telephone &&
                            LayoutConfig.contacts.telephone.length > 0
                            ? this.phoneItem (LayoutConfig.contacts.telephone[0])
                            : this.phoneItem(Dictionary.supportNumber)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
    );
  }
}
const mapStateToProps = (state) => ({
  reserveId: state.HotelSearch.reserveId,
  respond: state.VisualData.Responded,
  banks: state.PaymentData.banks,
  tokenId: state.PaymentData.tokenId,
  ///
  flightInfo: state.FlightSearch.flightSelected,
  hotelInfo: state.HotelSearch.selectedHotel,
  packageInfo: state.PackageSearch.reservedPackageData,
  currencyCode: state.UserData.Currency,
  searchURL: state.HotelSearch.searchURL,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  directTicketResult:state.FlightSearch.directTicketByIdResult,
  err:state.VisualData.error
});
const mapActionToProps = (dispatch) => ({
  getBankList: (params) => {
    dispatch(getBankList(params));
  },
  editMessage: (params) => {
    dispatch(editMessage(params));
  },
  setBankID: (params) => {
    dispatch(setBankID(params));
  },
  getReserveByID: (params) => dispatch(getReserveByID(params)),
  bookhotel: (params) => dispatch(bookhotel(params)),
  GetReserveById: (params) => dispatch(GetReserveById(params)),
  //getDirectTicketByID: (params) => dispatch(getDirectTicketByID(params)),
  setFlightSelected: (param) => dispatch(setFlightSelected(param)),
  selectedHotel: (param) => dispatch(selectedHotel(param)),
  paymentByMethod: (param) => dispatch(paymentByMethod(param)),
  paymentByMethodForDomesticFlight: (param) => dispatch(paymentByMethodForDomesticFlight(param)),
  setSafarmarketPixelValue:(data)=>dispatch(setSafarmarketPixel(data)),
  setDirectTicketByIdResult:(data)=>dispatch(setDirectTicketByIdResult(data)),
  showError: (param) => dispatch(showError(param))
});
export default connect(mapStateToProps, mapActionToProps)(Reserve);

import React from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Logo from '../../image/itours-logo-2.svg';

const Loading = props => {
    const {Dictionary} = props;
    return(
        <div className={`${props.fullPage?"loading-full-page":""} ${props.className||""}`}>
            <div className="loading-section text-center">
                <div className="logo-loading">
                    <img src={Logo} alt={'iTours logo'} title={'iTours logo'} />
                </div>
                <div>
                    {Dictionary ? <strong>{props.description || `${Dictionary.loadingData}${Dictionary.comma} ${Dictionary.pleaseWait} ...`}</strong> : null }
                </div>
            </div>
        </div>      
    )
}
const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary
});
const RouterDom=withRouter(Loading);
export default connect(mapStateToProps)(RouterDom);







import React from "react";
import { Link } from "react-router-dom";
import resaneh from "../../image/footer/resaneh.png";
import passengerRights from "../../image/footer/passenger-rights.png";
import padidehLogo from '../../image/padideh-small.png';
import enamad from "../../image/footer/enamad.png";
import iranPlaneOrganization from "../../image/footer/iran-plane-organization.png";
import iranAirplane from "../../image/footer/iran-airplane.png";
import moment from "moment";
import { connect } from "react-redux";
import LayoutConfig from "../../Config/layoutConfig";

class Footer extends React.Component {
  state = {
    showcurrency: false,
    showlang: false,
  };
  toggleCurrency() {
    this.setState((prevState) => ({
      showcurrency: !prevState.showcurrency,
    }));
  }
  toggleLang() {
    this.setState((prevState) => ({
      showlang: !prevState.showlang,
    }));
  }
  render() {
    const { Dictionary, LAN } = this.props;
    let date = new Date();
    let checkIn = moment(date).add(2, "d").format("YYYY-MM-DD");
    let checkout = moment(date).add(5, "d").format("YYYY-MM-DD");
    return (
      <footer className="footer">
        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "naminTravel-theme" ) || (
          <div className="main-footer">
            <div className="page-container">
              <div className="float-row">
                <div className="col-small-12 col-large-6 hidden-small hidden-xsmall">
                  <h5 className="footer-title">
                    {Dictionary.mostVisitedHotel}
                  </h5>
                  <div className="float-row">
                    <div className="col-xsmall-12 col-small-4">
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=75540&location=Antalya&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Antalya}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=488&location=Yerevan&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.yeravan}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=54323&location=Rome&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Rome}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=40977&location=London&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.London}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=75286&location=Istanbul&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Istanbul}{" "}
                      </Link>
                    </div>
                    <div className="col-xsmall-12 col-small-4">
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=65852&location=Oslo&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Oslo}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=84092&location=Athens&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Athens}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=75608&location=Ankara&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Ankara}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=74961&location=Phuket&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Phuket}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=39933&location=Paris&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Paris}{" "}
                      </Link>
                    </div>
                    <div className="col-xsmall-12 col-small-4">
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=27488&location=Madrid&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Madrid}{" "}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=57375&location=Florence&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Florence}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=12827&location=Beijing&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Beijing}
                      </Link>
                      <Link
                        className={"footerblock-link"}
                        to={`/hotel/result?locationId=64153&location=Amsterdam&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`}
                      >
                        {" "}
                        {Dictionary.Amsterdam}{" "}
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-small-12 col-large-6">
                  <div className="float-row">
                    <div className="col-xsmall-12 col-small-6">
                      <div className="margin-start-large quick-access-block">
                        <h5 className="footer-title">
                          {Dictionary.quickAccess}
                        </h5>
                        <Link to={"/"} className="footerblock-link">
                          {Dictionary.flight}
                        </Link>
                        <Link to={"/hotel"} className="footerblock-link">
                          {Dictionary.hotel}
                        </Link>
                        <Link to={"/package"} className="footerblock-link">
                          {Dictionary.package}
                        </Link>
                        <Link
                          to={"/terms"}
                          className="footerblock-link"
                          disabled={
                            LayoutConfig.themeClassName === "padide-com-theme"
                          }
                        >
                          {Dictionary.termsAndConditions}
                        </Link>
                        <Link to={"/about"} className="footerblock-link">
                          {Dictionary.aboutUs}
                        </Link>
                        <Link to={"/contact"} className="footerblock-link">
                          {Dictionary.contactUs}
                        </Link>
                      </div>
                    </div>
                    <div className="col-xsmall-12 col-small-6 text-center">
                      {LAN === "IR" ? (
                        <>
                          <div className="namads-holder clearfix">
                            {(LayoutConfig.companyName === "Sunhotel")?
                                <a referrerPolicy="origin" target="_blank" href="https://logo.samandehi.ir/Verify.aspx?id=160388&p=rfthgvkaobpdxlaomcsimcsi">
                                  <img referrerPolicy="origin" src={resaneh} className="logo-samandehi" alt="" />
                                </a>
                                :
                                <a referrerPolicy="origin" target="_blank" href="https://logo.samandehi.ir/Verify.aspx?id=77316&p=jyoejyoexlaorfthgvka">
                                  <img referrerPolicy="origin" src={resaneh} className="logo-samandehi" alt="" />
                                </a>
                            }
                            <a
                              href="https://www.cao.ir/paxrights"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={passengerRights}
                                className="namad-item"
                                alt="حقوق مسافر"
                              />
                            </a>
                            {(LayoutConfig.companyName === "Sunhotel")?
                            <a referrerPolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=29250&amp;Code=uGKdtCgAdAMGvWshw6E0">
                              <img referrerPolicy="origin" src={enamad} className="namad-logo" alt="" id="uGKdtCgAdAMGvWshw6E0" />
                            </a>                           
                            :
                              <a referrerpolicy="origin" target="_blank" href="https://trustseal.enamad.ir/?id=34040&amp;Code=PsXBCQQuoLeiSeNcJ7sv">
                                <img referrerpolicy="origin" src={enamad} alt="" id="PsXBCQQuoLeiSeNcJ7sv" />
                              </a>
                            }
                            <a
                              href="https://www.cao.ir"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={iranPlaneOrganization}
                                className="namad-item"
                                alt="سازمان هواپیمایی کشور "
                              />
                            </a>

                            <a
                              href="http://www.aira.ir/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={iranAirplane}
                                className="namad-item"
                                alt="انجمن شرکت های هواپیمایی ایران "
                              />
                            </a>
                            <span>
                              <img src={padidehLogo} alt="padideh81" className="namad-item" />
                            </span>
                            
                          </div>
                          <br />
                        </>
                      ) : (
                        <h5 className="footer-title">
                          {Dictionary.followUsOnSocialNetworks}:{" "}
                        </h5>
                      )}

                      <div>
                        {LayoutConfig.contacts &&
                        LayoutConfig.contacts.instagram === "disabled" ? (
                          <span className="social-link disabled margin-end">
                            <span className="itours-icon instagram" />
                          </span>
                        ) : (
                          <a
                            href={
                              LayoutConfig.contacts &&
                              LayoutConfig.contacts.instagram
                                ? LayoutConfig.contacts.instagram
                                : LAN === "IR"
                                ? "https://www.instagram.com/itours.ir/"
                                : "https://www.instagram.com/itours_no/"
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            className="social-link margin-end"
                          >
                            <span className="itours-icon instagram" />
                          </a>
                        )}

                        <span className="social-link disabled margin-end">
                          <span className="itours-icon twitter" />
                        </span>
                        <span className="social-link disabled">
                          <span className="itours-icon telegram" />
                        </span>
                      </div>
                      {LayoutConfig.siteName==="itours-ir"?<div>
                        <div className="itours-phoneNumber-row-item">
                          <div>
                            <span className="margin-end-half itours-icon icon-small red-phon-icon mirror-rtl" />
                            شنبه تا چهارشنبه، ساعت ۹ الی ۱۷
                            </div>
                          <div>
                            <div dir="ltr" className="no-wrap">+98 21 91008091</div>
                            {/* <div dir="ltr" className="no-wrap">+98 21 88104861</div> */}
                          </div>
                        </div>  
                        <div className="itours-phoneNumber-row-item">
                          <div>
                            <span className="margin-end-half itours-icon icon-small red-phon-icon mirror-rtl" />
                            شنبه تا چهارشنبه، ساعت ۱۷ الی ۲۳
                          </div>
                          <div dir="ltr" className="no-wrap">+98 901 899 59 03</div>
                          <div dir="ltr" className="no-wrap">+98 901 899 59 04</div>
                        </div> 
                        <div className="itours-phoneNumber-row-item">
                          <div>
                            <span className="margin-end-half itours-icon icon-small red-phon-icon mirror-rtl" />
                            پنج شنبه، جمعه و تعطيلات، ساعت ۹ الی ۲۳
                          </div>
                          <div dir="ltr" className="no-wrap">+98 901 899 59 03</div>
                          <div dir="ltr" className="no-wrap">+98 901 899 59 04</div>
                        </div>                  
                      </div>
                      :                
                        <div className="text-center margin-top">
                          {LayoutConfig.contacts?.telephone?.map(telItem=><div key={telItem} dir="ltr">{telItem}</div>)}
                          {LayoutConfig.contacts?.telephone?.length > 0 || <div dir="ltr">{Dictionary.supportNumber}</div>}
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="bottom-footer">
          <div className={`${LayoutConfig.themeClassName === "naminTravel-theme" ? "" : "text-center" } page-container relative`}>
            {LayoutConfig.themeClassName === "naminTravel-theme" || <img
              src={
                LayoutConfig.footerLogo
                  ? LayoutConfig.footerLogo
                  : LayoutConfig.logo
              }
              alt={LayoutConfig.companyName}
              className="footer-logo"
            />}
            <div>
              <span className="small">
                <small>
                  &copy;
                  {LayoutConfig.copyrightText
                    ? LayoutConfig.copyrightText
                    : Dictionary.copyrightText}
                </small>
              </span>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(Footer);

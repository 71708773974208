import React from "react";
import validator from "validator";
import { Map } from "../../Enum/Common";

export default class Form extends React.Component {
  state = {
    ValidatorList: {},
    ValidatorSummary: [],
    Loading: false,
    DataInput: {},
  };
  componentDidMount(formDOM) {
    let FormData = {};
    if (formDOM !== null) {
      for (let element of formDOM) {
        if (element.name.length > 0) {
          FormData[element.name] = "";
        }
      }
    }

    this.setState({
      ValidatorList: { ...FormData },
    });
  }
  validateForm(event, callback) {
    event.preventDefault();
    let DataFeild = {};
    let ValidatorArr = {};
    let FormStatus = true;
    const { Dictionary } = this.props;
    this.setState({ ValidatorSummary: [] });
    for (let item of event.target) {
      if (item.name.length > 0) {
        if (item.type === "tel" && item.value) {
          const pattern = /[^0-9۰-۹]/gi;
          let testResult = item.value.match(pattern);
          if(item.value.length < 4 || item.value.length > 15){
            testResult = true;
          }
          if (testResult) {
            ValidatorArr[item.name] = Dictionary.enteredPhoneNumberIsNotValid;
            FormStatus = false;
          } else {
            Map(DataFeild, item.name, item.value);
          }
        }
        if (item.getAttribute("data-validator") === "true") {
          if (validator.isEmpty(item.value) || (item.tagName==="SELECT" && item.value === "false")) {
            ValidatorArr[item.name] = Dictionary.thisFieldIsRequired;
            FormStatus = false;
          } else if (item.type === "checkbox") {
            if (!item.checked) {
              ValidatorArr[item.name] = Dictionary.youShouldAcceptThis;
              FormStatus = false;
            }
          } else {
            if (item.type === "email"){
              if (item.value && !validator.isEmail(item.value)) {
                ValidatorArr[item.name] =
                  Dictionary.enteredEmailAddressIsNotValid;
  
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.type === "password") {
              if (!validator.isLength(item.value, { min: 6, max: undefined })) {
                ValidatorArr[item.name] =
                  Dictionary.thePasswordMustBeAtLeast6Characters;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            } //password
             else {
              if (item.value.length === 0) {
                ValidatorArr[item.name] = Dictionary.fillTheText;
                FormStatus = false;
              }else if (item.value==="Invalid date"){
                ValidatorArr[item.name] = Dictionary.thisFieldIsRequired;
                FormStatus = false;
              }else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "alphabet") {
              const pattern = /[^ A-Za-z]/gi;
              let testResult = item.value.match(pattern);
              if (testResult) {
                ValidatorArr[item.name] =
                  Dictionary.numbersAndNonLatinCharactersAreNotAllowed;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "farsiAlphabet") {
              const pattern = /^[\u0600-\u06FF\s]+$/;
              let testResult =pattern.test(item.value);
              if (!testResult) {
                ValidatorArr[item.name] =
                'اعداد و کاراکترهای لاتین مجاز نیستند'
                    // Dictionary.numbersAndLatinCharactersAreNotAllowed;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "number") {
              const pattern = /[^0-9۰-۹]/gi;
              let testResult = item.value.match(pattern);
              if (testResult) {
                ValidatorArr[item.name] = Dictionary.onlyNumbersAreAllowed;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
            if (item.getAttribute("data-validator-type") === "passportNumber") {
              const pattern = /[^0-9۰-۹A-Za-z]/gi;
              let testResult = item.value.match(pattern);
              if (testResult) {
                ValidatorArr[item.name] = Dictionary.passportNumberIsNotValid;
                FormStatus = false;
              } else {
                Map(DataFeild, item.name, item.value);
              }
            }
          }
        } else {
          if (item.type === "email") {
            if (item.value && !validator.isEmail(item.value)) {
              ValidatorArr[item.name] =
                Dictionary.enteredEmailAddressIsNotValid;

              FormStatus = false;
            } else {
              Map(DataFeild, item.name, item.value);
            }
          } //email
          else if (item.type === "checkbox") {
            if (item.checked) {
              Map(DataFeild, item.name, true);
            }else{
              Map(DataFeild, item.name, false);
            }
          }
          else if (item.type === "radio") {
            if (item.checked) {
              Map(DataFeild, item.name, item.value);
            }
          } else {
            Map(DataFeild, item.name, item.value);
          }
        }
      }
    }
    if (FormStatus) {
      this.setState(
        {
          Loading: FormStatus,
          DataInput: { ...DataFeild },
          ValidatorList: ValidatorArr,
        },
        () => {
          callback();
        }
      );
    } else {
      this.setState({ Loading: FormStatus, ValidatorList: ValidatorArr });
    }
  }
}

import React from "react";
import Autocomplete from "../Common/Autocomplete";
import { Header, Flight, ServerAddress } from "../../Enum/Urls";
import moment from "moment-jalaali";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
//import moment from 'moment';
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import MultiDatePicker from 'react-multi-date-picker';
import { showError } from "../../Actions/VisualDataAction";
class FlightFormSearchTOF extends React.Component {
  state = {
    numberOfTrip: [1],
    selectPassengersShow: false,
    validation: false,
    // range:[{min:moment().subtract(0,'day'),max:null},{min:moment().subtract(0,'day'),max:null}],
    searchParam: {
      departureDateTimes: [0, 0],
      arrivalDateTimes: [],
      originCodes: [0],
      originNames: [0],
      destinationCodes: [0],
      destinationNames: [0],
      adult: 1,
      child: 0,
      infant: 0,
      cabinClassCode: 0,
      airTripType: "RoundTrip",
      nonStop: false,
      airlineCode: "all",
      airlineText: "all",
    },
    defaultValue: {
      departureDateTimes: [0, 0],
      arrivalDateTimes: [],
      originCodes: [0],
      originNames: [0],
      destinationCodes: [0],
      destinationNames: [0],
      adult: 1,
      child: 0,
      infant: 0,
      cabinClassCode: 0,
      airTripType: "RoundTrip",
      nonStop: false,
      airlineCode: "all",
      airlineText: " ",
    },
    showMenu: true,
    calendarIsGregorian: false,
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };
  changeCalendarType = () => {
    this.setState((prevState) => {
      return { calendarIsGregorian: !prevState.calendarIsGregorian };
    });
  };
  changeTypeToReturn = () => {
    this.setState({
      searchParam: {
        ...this.state.searchParam,
        airTripType: "RoundTrip",
      },
      defaultValue: {
        ...this.state.defaultValue,
        airTripType: "RoundTrip",
      },
    });
  };
  changeTypeToOneWay = () => {
    this.setState({
      searchParam: {
        ...this.state.searchParam,
        airTripType: "OneWay",
      },
      defaultValue: {
        ...this.state.defaultValue,
        airTripType: "OneWay",
      },
    });
  };
  showPersianMonth = (input) => {
    let day = input.jMonth() + 1;
    switch (day) {
      case 1:
        return "فروردین";
      case 2:
        return "اردیبهشت";
      case 3:
        return "خرداد";
      case 4:
        return "تیر";
      case 5:
        return "مرداد";
      case 6:
        return "شهریور";
      case 7:
        return "مهر";
      case 8:
        return "آبان";
      case 9:
        return "آذر";
      case 10:
        return "دی";
      case 11:
        return "بهمن";
      case 12:
        return "اسفند";
      default:
        return null;
    }
  };
  showGregorianMonth = (input) => {
    let day = input.get("month") + 1;
    switch (day) {
      case 1:
        return "Jan";
      case 2:
        return "Feb";
      case 3:
        return "Mar";
      case 4:
        return "Apr";
      case 5:
        return "May";
      case 6:
        return "Jun";
      case 7:
        return "Jul";
      case 8:
        return "Aug";
      case 9:
        return "Sep";
      case 10:
        return "Oct";
      case 11:
        return "Nov";
      case 12:
        return "Dec";
      default:
        return null;
    }
  };
  ///
  showPersianDate = (input) => {
    let day = input.get("days");
    switch (day) {
      case 6:
        return "شنبه";
      case 0:
        return "یکشنبه";
      case 1:
        return "دوشنبه";
      case 2:
        return "سه شنبه";
      case 3:
        return "چهارشنبه";
      case 4:
        return "پنجشنبه";
      case 5:
        return "جمعه";
      default:
        return null;
    }
  };
  showGregorianDate = (input) => {
    let day = input.get("days");
    switch (day) {
      case 6:
        return "Saturday";
      case 0:
        return "Sunday";
      case 1:
        return "Monday";
      case 2:
        return "Tuesday";
      case 3:
        return "Wednesday";
      case 4:
        return "Thursday";
      case 5:
        return "Friday";
      default:
        return null;
    }
  };
  componentDidMount() {
    if (this.props.location.search) {
      this.getParamForFill();
      let parameters = queryString.parse(this.props.location.search);
      let route = [];
      for (
        let counter = 0;
        counter < parameters.originCodes.split("|").length;
        counter++
      ) {
        route.push(1);
      }
      this.setState({
        numberOfTrip: route,
      });
    } else {
      // this.setDates(0,new Date(moment(new Date().setHours(0,0)).add(1,'d')) );
      // this.setDates(1,new Date(moment(new Date().setHours(0,0)).add(2,'d')) );
    }
  }
  getParamForFill = () => {
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.adult !== undefined) {
      let modelLoad = {};
      modelLoad["adult"] = parseInt(parameters.adult);
      modelLoad["child"] = parseInt(parameters.child);
      modelLoad["infant"] = parseInt(parameters.infant);
      modelLoad["airTripType"] = parameters.airTripType;
      modelLoad["airlineCode"] = parameters.airlineCode;
      modelLoad["cabinClassCode"] = parseInt(parameters.cabinClassCode);
      modelLoad["departureDateTimes"] = parameters.departureDateTimes.split(
        "|"
      );
      modelLoad["destinationCodes"] = parameters.destinationCodes.split("|");
      modelLoad["destinationNames"] = parameters.destinationNames.split("|");
      modelLoad["nonStop"] = parameters.nonStop;
      modelLoad["originCodes"] = parameters.originCodes.split("|");
      modelLoad["originNames"] = parameters.originNames.split("|");
      modelLoad["airlineText"] = parameters.airlineText;
      let freezeURL = JSON.stringify(modelLoad);
      this.setState({
        defaultValue: JSON.parse(freezeURL),
        searchParam: modelLoad,
      });
    }
  };
  disabledDate = (index, current) => {
    // Can not select days before today and today
    if (index) {
      return (
        current &&
        current <= moment(this.state.searchParam.departureDateTimes[index - 1])
      );
    } else {
      return current && current <= moment().endOf("day").subtract(1, "day");
    }
  };
  setDates = (index, value) => {
    let dates = this.state.searchParam.departureDateTimes;
    dates[index] = moment(value);
    for (let counter = index + 1; counter < dates.length; counter++) {
      if (dates[index].isAfter(dates[counter])) {
        dates[counter] = dates[index];
      }
    }

    this.setState({
      searchParam: {
        ...this.state.searchParam,
        departureDateTimes: dates,
      },
    });
    // let cashDepartureDateTimes=this.state.searchParam.departureDateTimes;
    //
    // let cashMaxMin=this.state.range;
    // cashDepartureDateTimes[index]=value;
    // if(index<Object.keys(cashDepartureDateTimes).length -1){
    //     cashDepartureDateTimes[index+1]=value;
    // }
    // //if(index<cashMaxMin.length-1){
    //     cashMaxMin[index+1]={min:0,max:0};
    //     cashMaxMin[index+1].min=moment(value);
    // //}
    // this.setState({
    //         searchParam:{
    //             ...this.state.searchParam,
    //             departureDateTimes:cashDepartureDateTimes,
    //         },
    //         range:cashMaxMin
    //     })
  };
  getCallback = (value, text, name, id) => {
    let searchParamCatch = this.state.searchParam;
    searchParamCatch[name + "Names"][id] = text;
    searchParamCatch[name + "Codes"][id] = value;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };
  getAirline = (value, text) => {
    this.setState({
      searchParam: {
        ...this.state.searchParam,
        airlineCode: value ? value : "all",
        airlineText: text ? text : "all",
      },
    });
  };
  handleSearchParam = (type, value) => {
    let searchParamCash = this.state.searchParam;
    searchParamCash[type] = value;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCash },
    });
  };
  handleSearchButton = () => {
    this.setState({
      validation: true,
    });
    let statusForm = true;
    for (let obj in this.state.searchParam.departureDateTimes) {
      if (!this.state.searchParam.departureDateTimes[obj]) {
        statusForm = false;
      }
    }
    this.state.searchParam.originCodes.forEach((item) => {
      if (!item) {
        statusForm = false;
      }
    });
    this.state.searchParam.destinationCodes.forEach((item) => {
      if (!item) {
        statusForm = false;
      }
    });
    if (statusForm) {
      //preper URL search
      let dateArray = "";
      for (let date in this.state.searchParam.departureDateTimes) {
        let item = moment(
          this.state.searchParam.departureDateTimes[date]
        ).format("YYYY-MM-DD");
        parseInt(date) ? (dateArray += "|" + item) : (dateArray += item);
      }
      let originCodes = "";
      this.state.searchParam.originCodes.forEach((item, index) => {
        index ? (originCodes += "|" + item) : (originCodes += item);
      });
      let originNames = "";
      this.state.searchParam.originNames.forEach((item, index) => {
        index ? (originNames += "|" + item) : (originNames += item);
      });
      let destinationCodes = "";
      this.state.searchParam.destinationCodes.forEach((item, index) => {
        index ? (destinationCodes += "|" + item) : (destinationCodes += item);
      });
      let destinationNames = "";
      this.state.searchParam.destinationNames.forEach((item, index) => {
        index ? (destinationNames += "|" + item) : (destinationNames += item);
      });
      //validate passenger
      //sum of passenger greater than 9
      if (
        parseInt(this.state.searchParam.adult) +
          parseInt(this.state.searchParam.child) >
        9
      ) {
        this.props.showError({
          visibility: true,
          content: this.props.Dictionary
            .TheNumberOfPassengersShouldNotBeMoreThan9person,
        });
      } else if (
        parseInt(this.state.searchParam.adult) <
        parseInt(this.state.searchParam.infant)
      ) {
        this.props.showError({
          visibility: true,
          content: this.props.Dictionary.infantsMustBeLessThanAdults,
        });
      } else {
        this.props.history.push(
          `/flight/result?originCodes=${originCodes}` +
            `&originNames=${originNames}` +
            `&destinationCodes=${destinationCodes}` +
            `&destinationNames=${destinationNames}` +
            `&departureDateTimes=${dateArray}` +
            `&adult=${this.state.searchParam.adult}` +
            `&child=${this.state.searchParam.child}` +
            `&infant=${this.state.searchParam.infant}` +
            `&cabinClassCode=${this.state.searchParam.cabinClassCode}` +
            `&airTripType=${this.state.searchParam.airTripType}` +
            `&nonStop=${this.state.searchParam.nonStop}` +
            `&airlineCode=${this.state.searchParam.airlineCode}` +
            `&airlineText=${this.state.searchParam.airlineText}`
        );
      }
    }
  };
  changeTypeOfTrip = (param) => {
    if (param !== "OpenJaw") {
      this.setState(
        {
          searchParam: {
            ...this.state.searchParam,
            airTripType: param,
          },
          numberOfTrip: [1],
        },
        () => {
          let searchParamCatch = this.state.searchParam;
          if (this.state.searchParam.airTripType === "OneWay") {
            searchParamCatch.departureDateTimes = [
              searchParamCatch.departureDateTimes[0],
            ];
          } else {
            searchParamCatch.departureDateTimes = [
              searchParamCatch.departureDateTimes[0],
              searchParamCatch.departureDateTimes[1],
            ];
          }
          if (this.state.searchParam.originCodes.length > 1) {
            searchParamCatch.originCodes = [searchParamCatch.originCodes[0]];
            searchParamCatch.originNames = [searchParamCatch.originNames[0]];
            searchParamCatch.destinationCodes = [
              searchParamCatch.destinationCodes[0],
            ];
            searchParamCatch.destinationNames = [
              searchParamCatch.destinationNames[0],
            ];
          }
          this.setState({
            searchParam: { ...this.state.searchParam, ...searchParamCatch },
          });
        }
      );
    } else {
      this.setState(
        {
          searchParam: {
            ...this.state.searchParam,
            airTripType: param,
          },
          numberOfTrip: [1, 2],
        },
        () => {
          let searchParamCatch = this.state.searchParam;
          searchParamCatch.originCodes.push(0);
          searchParamCatch.originNames.push(0);
          searchParamCatch.destinationCodes.push(0);
          searchParamCatch.destinationNames.push(0);
          this.setState({
            searchParam: { ...this.state.searchParam, ...searchParamCatch },
          });
        }
      );
    }
  };
  addFlight = () => {
    let numberOfTripCash = this.state.numberOfTrip;
    if (numberOfTripCash.length < 4) {
      numberOfTripCash.push(numberOfTripCash[numberOfTripCash.length - 1] + 1);
      let searchParamCatch = this.state.searchParam;
      searchParamCatch.originCodes.push(0);
      searchParamCatch.originNames.push(0);
      searchParamCatch.destinationCodes.push(0);
      searchParamCatch.destinationNames.push(0);
      searchParamCatch.departureDateTimes.push(0);
      // let range=this.state.range;
      // range.push({min:this.state.searchParam.departureDateTimes[Object.keys(this.state.searchParam.departureDateTimes).length-1],max:null});
      this.setState({
        numberOfTrip: numberOfTripCash,
        searchParam: { ...this.state.searchParam, ...searchParamCatch },
      });
    }
  };
  destToOrigin = (index) => {
    let newSearchParam = { ...this.state.searchParam };
    let newDefaultValue = { ...this.state.defaultValue };

    let originName = newSearchParam.originNames[index];
    let originCode = newSearchParam.originCodes[index];
    let destinationName = newSearchParam.destinationNames[index];
    let destinationCode = newSearchParam.destinationCodes[index];

    newSearchParam.originCodes[index] = destinationCode;
    newSearchParam.originNames[index] = destinationName;
    newSearchParam.destinationCodes[index] = originCode;
    newSearchParam.destinationNames[index] = originName;

    newDefaultValue.originCodes[index] = destinationCode;
    newDefaultValue.originNames[index] = destinationName;
    newDefaultValue.destinationCodes[index] = originCode;
    newDefaultValue.destinationNames[index] = originName;

    this.setState({
      searchParam: newSearchParam,
      defaultValue: newDefaultValue,
    });
  };
  removeFlight = (index) => {
    let numberOfTripCash = this.state.numberOfTrip;
    numberOfTripCash.splice(index, 1);
    let searchParamCatch = this.state.searchParam;
    searchParamCatch.originCodes.splice(index, 1);
    searchParamCatch.originNames.splice(index, 1);
    searchParamCatch.destinationCodes.splice(index, 1);
    searchParamCatch.destinationNames.splice(index, 1);
    searchParamCatch.departureDateTimes.splice(index, 1);
    this.setState({
      numberOfTrip: numberOfTripCash,
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };
  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }else{
      debugger;
    }
  }
  render = () => {
    const { Dictionary, LAN } = this.props;
    return (
      <div className="full-width">
        <div className="search-box-holder">
          {this.props.inline || (
            <div className="clearfix tab-holder text-center hidden-small hidden-xsmall">
              <button type="button" className="tab-s active-tab-s">
                <span className="margin-end-light">{Dictionary.flight}</span>
                <span className="itours-icon top-plane-white margin-start-light" />
              </button>
              <Link to="/hotel" className="tab-s">
                <span className="margin-end-light">{Dictionary.hotel}</span>
                <span className="itours-icon hotel-gray margin-start-light" />
              </Link>
              <Link to="/package" className="tab-s">
                <span className="margin-end-light">
                  {Dictionary.hotel} + {Dictionary.flight}
                </span>
                <span className="itours-icon top-plane-gray margin-start-light" />
                <span className="itours-icon hotel-gray margin-start-light" />
              </Link>
            </div>
          )}

          <div className="searchbox-inner">
            <div className="triptype-radio form-group inline-radio">
              <div className="radio-item">
                <input
                  className="custom-radio"
                  value={"OneWay"}
                  type="radio"
                  name="triptype"
                  checked={this.state.searchParam.airTripType === "OneWay"}
                  onClick={() => this.changeTypeOfTrip("OneWay")}
                />
                <label className="radio-label">
                  <span className="radio-mark" />
                  {Dictionary.oneway}
                </label>
              </div>
              <div className="radio-item">
                <input
                  className="custom-radio"
                  value={"RoundTrip"}
                  type="radio"
                  name="triptype"
                  checked={this.state.searchParam.airTripType === "RoundTrip"}
                  onClick={() => this.changeTypeOfTrip("RoundTrip")}
                />
                <label className="radio-label">
                  <span className="radio-mark" />
                  {Dictionary.returnway}
                </label>
              </div>
              <div className="radio-item">
                <input
                  className="custom-radio"
                  value={"OpenJaw"}
                  type="radio"
                  name="triptype"
                  checked={this.state.searchParam.airTripType === "OpenJaw"}
                  onClick={() => this.changeTypeOfTrip("OpenJaw")}
                />
                <label className="radio-label">
                  <span className="radio-mark" />
                  {Dictionary.openjawway}
                </label>
              </div>
            </div>
            <div className="searchBar ">
              <div className="">
                {this.state.numberOfTrip.map((item, index) => {
                  return (
                    <div
                      className={
                        this.state.searchParam.airTripType === "OpenJaw"
                          ? " multy-dest-route"
                          : ""
                      }
                      key={item}
                    >
                      <div className="">
                        <div className={index > 1 ? "deleteable-route" : ""}>
                          <div className="float-row">
                            <div
                              className={
                                this.state.searchParam.airTripType ===
                                "RoundTrip"
                                  ? this.props.inline
                                    ? "col-xsmall-12 col-small-6 col-medium-3"
                                    : "col-xsmall-12 col-small-6"
                                  : "col-xsmall-12 col-small-4"
                              }
                            >
                              <div className="form-group flight-origin-holder">
                                <label className="form-label no-margin-top">
                                  {Dictionary.from}
                                </label>
                                <Autocomplete
                                  defaultValue={
                                    this.state.defaultValue.originCodes[index]
                                      ? this.state.defaultValue.originCodes[
                                          index
                                        ]
                                      : null
                                  }
                                  defaultText={
                                    this.state.defaultValue.originNames[index]
                                      ? this.state.defaultValue.originNames[
                                          index
                                        ]
                                      : null
                                  }
                                  getValue={this.getCallback.bind(this)}
                                  name={"origin"}
                                  index={index}
                                  type={"flight"}
                                  validate={true}
                                  url={
                                    ServerAddress.Type +
                                    ServerAddress.Flight +
                                    Flight.GetLocation
                                  }
                                  min={3}
                                  param="value"
                                  header={Header}
                                />
                                {this.state.validation &&
                                  !this.state.searchParam.destinationCodes[
                                    index
                                  ] && (
                                    <b className="validator-label">
                                      {Dictionary.pleaseEnterOrigin}
                                    </b>
                                  )}
                              </div>
                            </div>
                            <div
                              className={
                                this.state.searchParam.airTripType ===
                                "RoundTrip"
                                  ? this.props.inline
                                    ? "col-xsmall-12 col-small-6 col-medium-3"
                                    : "col-xsmall-12 col-small-6"
                                  : "col-xsmall-12 col-small-4"
                              }
                            >
                              <div className="form-group flight-destination-holder">
                                <label className="form-label no-margin-top">
                                  {Dictionary.to}
                                </label>
                                <Autocomplete
                                  defaultValue={
                                    this.state.defaultValue.destinationCodes[
                                      index
                                    ] &&
                                    this.state.defaultValue.destinationCodes[
                                      index
                                    ]
                                  }
                                  defaultText={
                                    this.state.defaultValue.destinationNames[
                                      index
                                    ]
                                  }
                                  getValue={this.getCallback.bind(this)}
                                  name={"destination"}
                                  index={index}
                                  type={"flight"}
                                  validate={true}
                                  url={
                                    ServerAddress.Type +
                                    ServerAddress.Flight +
                                    Flight.GetLocation
                                  }
                                  min={3}
                                  param="value"
                                  header={Header}
                                />
                                {this.state.validation &&
                                  !this.state.searchParam.destinationCodes[
                                    index
                                  ] && (
                                    <b className="validator-label">
                                      {Dictionary.pleaseEnterDestination}
                                    </b>
                                  )}
                              </div>
                            </div>

                            <div
                              className={
                                this.state.searchParam.airTripType ===
                                "RoundTrip"
                                  ? this.props.inline
                                    ? "col-xsmall-12 col-small-6 col-medium-3"
                                    : "col-xsmall-12 col-small-6"
                                  : "col-xsmall-12 col-small-4"
                              }
                            >
                              <div className="form-group full-width">
                                <label className="form-label">
                                  {this.state.searchParam.airTripType ===
                                  "RoundTrip"
                                    ? Dictionary.departureDate
                                    : Dictionary.flightDate}
                                </label>
                                <div className="relative">
                                      <MultiDatePicker 
                                        calendarPosition={LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left"}
                                        weekDays={this.state.datepickerLocale === "fa" ? [
                                          "ش", 
                                          "ی", 
                                          "د", 
                                          "س", 
                                          "چ", 
                                          "پ", 
                                          "ج"
                                        ]:this.state.datepickerLocale === "ar" ?[
                                          "الأحد",
                                          "الإثنين",
                                          "الثلاثاء",
                                          "الأربعاء",
                                          "الخميس",
                                          "الجمعة",
                                          "السبت"
                                        ]:undefined}
                                        digits={this.state.datepickerLocale === "ar" ? [
                                          "0",
                                          "1",
                                          "2",
                                          "3",
                                          "4",
                                          "5",
                                          "6",
                                          "7",
                                          "8",
                                          "9"
                                        ]:undefined}
                                        inputClass="multi-datepicker-input"
                                        className={`multi-datepicker ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`}
                                        minDate={
                                          index && this.state.searchParam.departureDateTimes[index - 1]?
                                          this.state.datepickerType ==="persian"? moment(this.state.searchParam.departureDateTimes[index - 1]).format("jYYYY/jMM/jDD") : moment(this.state.searchParam.departureDateTimes[index - 1]).format("YYYY/MM/DD")                                           
                                          : this.state.datepickerType ==="persian"? moment().format("jYYYY/jMM/jDD") : moment().format("YYYY/MM/DD")
                                        }
                                        format={"YYYY-MM-DD"}
                                        calendar={this.state.datepickerType}
                                        locale={this.state.datepickerLocale}
                                        value={
                                          this.state.searchParam.departureDateTimes[index] && 
                                          (this.state.datepickerType ==="persian"?
                                          moment(this.state.searchParam.departureDateTimes[index]).format("jYYYY/jMM/jDD")
                                          : moment(this.state.searchParam.departureDateTimes[index]).format("YYYY/MM/DD")
                                          )
                          
                                        }
                                        onChange={(d) => {
                                          const e = d.toDate();
                                          this.setDates(index, e);
                                        }}
                                        // placeholder={
                                        //   this.state.searchParam.airTripType === "RoundTrip"
                                        //     ? Dictionary.departureDate
                                        //     : Dictionary.flightDate
                                        // }
                                      >
                                        {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                                      </MultiDatePicker>

                                  {this.state.validation &&
                                    !this.state.searchParam.departureDateTimes[
                                      index
                                    ] && (
                                      <b className="validator-label">
                                        {Dictionary.pleaseEnterDepartureDate}
                                      </b>
                                    )}
                                </div>
                              </div>
                            </div>

                            {this.state.searchParam.airTripType ===
                              "RoundTrip" && (
                              <div
                                className={
                                  this.props.inline
                                    ? "col-xsmall-12 col-small-6 col-medium-3"
                                    : "col-xsmall-12 col-small-6"
                                }
                              >
                                <div className="form-group">
                                  <label className="form-label">
                                    {Dictionary.returnDate}
                                  </label>
                                  <div className="relative">
                                  <MultiDatePicker 
                                          calendarPosition={LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left"}
                                          weekDays={this.state.datepickerLocale === "fa" ? [
                                            "ش", 
                                            "ی", 
                                            "د", 
                                            "س", 
                                            "چ", 
                                            "پ", 
                                            "ج"
                                          ]:this.state.datepickerLocale === "ar" ?[
                                            "الأحد",
                                            "الإثنين",
                                            "الثلاثاء",
                                            "الأربعاء",
                                            "الخميس",
                                            "الجمعة",
                                            "السبت"
                                          ]:undefined}
                                          digits={this.state.datepickerLocale === "ar" ? [
                                            "0",
                                            "1",
                                            "2",
                                            "3",
                                            "4",
                                            "5",
                                            "6",
                                            "7",
                                            "8",
                                            "9"
                                          ]:undefined}
                                          inputClass="multi-datepicker-input"
                                          className={`multi-datepicker ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`}
                                          minDate={
                                            this.state.datepickerType ==="persian" ?
                                            moment(this.state.searchParam.departureDateTimes[0]).format("jYYYY/jMM/jDD")  
                                            : moment(this.state.searchParam.departureDateTimes[0]).format("YYYY/MM/DD")

                                            // this.state.datepickerType ==="persian"? moment().format("jYYYY/jMM/jDD") : moment().format("YYYY/MM/DD")
                                          }
                                          format={"YYYY-MM-DD"}
                                          calendar={this.state.datepickerType}
                                          locale={this.state.datepickerLocale}
                                          value={
                                            this.state.searchParam.departureDateTimes[index+1] ? (this.state.datepickerType ==="persian"? 
                                            moment(this.state.searchParam.departureDateTimes[index+1]).format("jYYYY/jMM/jDD")
                                            :moment(this.state.searchParam.departureDateTimes[index+1]).format("YYYY/MM/DD")
                                            ): undefined
                                            
                                            //this.state.datepickerType ==="persian"? moment(this.state.searchParam.checkin,"YYYY/MM/DD").format("jYYYY/jMM/jDD"): this.state.searchParam.checkin
                                          }
                                          onChange={(d) => {
                                            const e = d.toDate();
                                            this.setDates(index+1, e);
                                          }}
                                          // placeholder={Dictionary.returnDate}
                                        >
                                          {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                                        </MultiDatePicker>
                                    {this.state.validation &&
                                      !this.state.searchParam
                                        .departureDateTimes[index + 1] && (
                                        <b className="validator-label">
                                          {Dictionary.pleaseEnterReturnDate}
                                        </b>
                                      )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          {index > 1 && (
                            <button
                              type="button"
                              className="button grey-btn delete-route margin-bottom"
                              onClick={() => {
                                this.removeFlight(index);
                              }}
                            >
                              <span className="itours-icon close-icon" />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  );
                })}
                {this.state.searchParam.airTripType === "OpenJaw" &&
                  this.state.numberOfTrip.length < 4 && (
                    <div className="form-group">
                      <button
                        className="button blue-btn"
                        type={"button"}
                        onClick={() => this.addFlight()}
                      >
                        {Dictionary.addNewDestination}
                      </button>
                    </div>
                  )}
              </div>
              <div className="float-row">
                <div
                  className={
                    this.props.inline
                      ? "col-small-12 col-medium-9"
                      : "col-xsmall-12 col-small-12"
                  }
                >
                  <div className="float-row">
                    <div
                      className={
                        this.props.inline
                          ? "col-small-4 col-medium-2"
                          : "col-small-4"
                      }
                    >
                      <div className="form-group">
                        <label className="form-label">
                          {Dictionary.adult} (12+)
                        </label>
                        <select
                          onChange={(e) =>
                            this.handleSearchParam(
                              "adult",
                              parseInt(e.target.value)
                            )
                          }
                          className="itours-select"
                        >
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                            return (
                              <option
                                value={item}
                                key={item}
                                selected={item === this.state.searchParam.adult}
                              >
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      className={
                        this.props.inline
                          ? "col-small-4 col-medium-2"
                          : "col-small-4"
                      }
                    >
                      <div className="form-group">
                        <label className="form-label">
                          {Dictionary.child} (2-11)
                        </label>
                        <select
                          className="itours-select"
                          onChange={(e) =>
                            this.handleSearchParam(
                              "child",
                              parseInt(e.target.value)
                            )
                          }
                        >
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                            return (
                              <option
                                value={item}
                                key={item}
                                selected={item === this.state.searchParam.child}
                              >
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div
                      className={
                        this.props.inline
                          ? "col-small-4 col-medium-2"
                          : "col-small-4"
                      }
                    >
                      <div className="form-group">
                        <label className="form-label">
                          {Dictionary.infant} (0-1)
                        </label>
                        <select
                          className="itours-select"
                          onChange={(e) =>
                            this.handleSearchParam(
                              "infant",
                              parseInt(e.target.value)
                            )
                          }
                        >
                          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((item, index) => {
                            return (
                              <option
                                value={item}
                                key={item}
                                selected={
                                  item === this.state.searchParam.infant
                                }
                              >
                                {item}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>

                    <div
                      className={
                        this.props.inline
                          ? "col-xsmall-12 col-small-4 col-medium-2"
                          : "col-xsmall-12 col-small-4"
                      }
                    >
                      <div className="form-group airline-autocomplete-holder">
                        <label className="form-label">
                          {Dictionary.airline}
                        </label>
                        <Autocomplete
                          getValue={this.getAirline.bind(this)}
                          name={"airlineCode"}
                          type={"flight"}
                          defaultText={
                            this.state.defaultValue.airlineText
                              ? this.state.defaultValue.airlineText
                              : null
                          }
                          defaultValue={
                            this.state.defaultValue.airlineCode
                              ? this.state.defaultValue.airlineCode
                              : null
                          }
                          validate={true}
                          url={
                            ServerAddress.Type +
                            ServerAddress.Flight +
                            Flight.GetAirline
                          }
                          min={2}
                          param="value"
                          header={Header}
                        />
                      </div>
                    </div>
                    <div
                      className={
                        this.props.inline
                          ? "col-xsmall-12 col-small-4 col-medium-2"
                          : "col-xsmall-12 col-small-4"
                      }
                    >
                      <div className="form-group">
                        <label className="form-label">{Dictionary.stop}</label>
                        <select
                          className="itours-select"
                          onChange={(e) =>
                            this.handleSearchParam(
                              "nonStop",
                              e.target.value === "true"
                            )
                          }
                        >
                          <option value={false}>{Dictionary.all}</option>
                          <option
                            value={true}
                            selected={this.state.searchParam.nonStop === "true"}
                          >
                            {Dictionary.nonStop}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div
                      className={
                        this.props.inline
                          ? "col-xsmall-12 col-small-4 col-medium-2"
                          : "col-xsmall-12 col-small-4"
                      }
                    >
                      <div className="form-group">
                        <label className="form-label">
                          {Dictionary.classCabin}
                        </label>
                        <select
                          className="itours-select"
                          onChange={(e) =>
                            this.handleSearchParam(
                              "cabinClassCode",
                              parseInt(e.target.value)
                            )
                          }
                        >
                          <option
                            value={0}
                            selected={
                              this.state.defaultValue.cabinClassCode === 0
                            }
                          >
                            {Dictionary.all}
                          </option>
                          <option
                            value={1}
                            selected={
                              this.state.defaultValue.cabinClassCode === 1
                            }
                          >
                            {Dictionary.economy}
                          </option>
                          <option
                            value={2}
                            selected={
                              this.state.defaultValue.cabinClassCode === 2
                            }
                          >
                            {Dictionary.economyStandard}{" "}
                          </option>
                          <option
                            value={3}
                            selected={
                              this.state.defaultValue.cabinClassCode === 3
                            }
                          >
                            {Dictionary.economyPremium}{" "}
                          </option>
                          <option
                            value={4}
                            selected={
                              this.state.defaultValue.cabinClassCode === 4
                            }
                          >
                            {Dictionary.business}
                          </option>
                          <option
                            value={5}
                            selected={
                              this.state.defaultValue.cabinClassCode === 5
                            }
                          >
                            {Dictionary.firstClass}{" "}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    this.props.inline
                      ? "col-small-12 col-medium-3"
                      : "col-xsmall-12 col-small-12"
                  }
                >
                  <div className=" search-btn-holder form-group flight-search-btn">
                    <button
                      type="button"
                      className={
                        this.props.inline
                          ? "searchBtn button flight-search-btn red-btn full-width"
                          : "searchBtn button red-btn pull-end full-width"
                      }
                      onClick={() => this.handleSearchButton()}
                    >
                      {Dictionary.search}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  showError: (param) => dispatch(showError(param)),
});
const RouterPath = withRouter(FlightFormSearchTOF);
export default connect(mapStateToProps, mapDispatchToProps)(RouterPath);

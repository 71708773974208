import  React, { Component } from 'react';
import moment from "moment-jalaali";
import {connect} from 'react-redux';
import {default as timemoment} from "moment";
import airplane from "../../image/airplane.svg";
import {Tooltip} from 'antd';

class RouteItem extends Component{
    render(){
        const {Dictionary,LAN}=this.props;

        // let destinationTime = moment(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime,"YYYY-MM-DDTHH:mm:SS").format("YYYY-MM-DD");
        // let originTime = moment(this.props.model.flightSegments[0].departureDateTime,"YYYY-MM-DDTHH:mm:SS").format("YYYY-MM-DD");
        // let c = moment(destinationTime).diff(moment(originTime),'days');

        return(
            <div className={"route-item"}>
                <div className="route-item-flex">
                    <div className="air-line-info">
                        <div className="air-line-info-inner">
                            <div className="airline-logo-holder">
                                <img src={this.props.model.flightSegments[0].marketingAirline.photoUrl} alt={this.props.model.flightSegments[0].marketingAirline.name} className="airline-logo"/>
                            </div>
                            {this.props.isPackage || <div className={"airlineInfo"}>
                                <div className={"arilinename"}>{this.props.model.flightSegments[0].marketingAirline.name}</div>
                                <div className={"flightnumber en-font"}>{this.props.model.flightSegments[0].flightNumber}</div>
                            </div>}
                        </div>
                    </div>
                    <div>
                        <div className="router">
                            <div className="originInfo">
                                <div className="time">
                                    <strong>{moment(this.props.model.flightSegments[0].departureDateTime).format("HH:mm")}</strong>
                                </div>
                                <div className="date">
                                    <Tooltip placement="top" title=
                                        {(LAN === "IR")?<>
                                        <div>{moment(this.props.model.flightSegments[0].departureDateTime).format("jYYYY-jMM-jDD")}</div>
                                        <span className="font-12"> ({moment(this.props.model.flightSegments[0].departureDateTime).format("YYYY-MM-DD")})</span>
                                        </>
                                        :
                                        moment(this.props.model.flightSegments[0].departureDateTime).format("YYYY-MM-DD")}                                                    
                                        >
                                        <strong className="font-14">
                                            {(LAN === "IR")?
                                            moment(this.props.model.flightSegments[0].departureDateTime).format("ddd، jD jMMMM")
                                            :
                                            moment(this.props.model.flightSegments[0].departureDateTime).format("ddd, MMM D")
                                            }
                                        </strong>
                                    </Tooltip> 
                                </div>
                                <div className="codeCity">
                                    <Tooltip title={<div>
                                            <span>{this.props.model.flightSegments[0].departureAirport.cityName}, </span>
                                            <span>{this.props.model.flightSegments[0].departureAirport.countryName} -  </span>
                                            {this.props.model.flightSegments[0].departureAirport.locationName}
                                        </div>}>
                                        {this.props.model.flightSegments[0].departureAirport.locationCode}
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="stop-info text-center">
                                <div className="stop">{(this.props.model.flightSegments.length-1===0)? <span className="green">{Dictionary.direct}</span> : <span>{this.props.model.flightSegments.length-1} {Dictionary.stops}</span>}</div>
                                <div className="dashed-line">
                                    <img src={airplane} alt="airplane" title={"airplane"}/>
                                </div>
                                <div className="duration">
                                    {(this.props.model.journeyDuration==="00:00:00")?"-- : --":
                                    <>
                                        <span>{Dictionary.flightDuration}  </span>
                                        <span className="semi-bold"> {
                                        ((this.props.model.journeyDuration).indexOf(".")>0)?
                                        timemoment(this.props.model.journeyDuration, "d.HH:mm:ss").format("d[d] H[h] m[m]")
                                        :timemoment(this.props.model.journeyDuration, "HH:mm:ss").format("H[h] m[m]")
                                        }
                                        </span>
                                    </>}
                                </div>
                            </div>
                            <div className="destinationInfo text-end">
                                <div className="time">
                                    <strong className="relative">
                                        {(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime === '0001-01-01T00:00:00')?" -- : -- ":  moment(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime).format("HH:mm")}
                                        {/* {(c > 0)?
                                        <span className="font-12 diff-days"> +{c} </span>
                                        :null} */}
                                    </strong>
                                </div>
                                <div className="date">
                                    <Tooltip placement="top" title=
                                        {(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime === '0001-01-01T00:00:00')?undefined: 
                                        (LAN === "IR")?<>
                                        <div>{moment(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime).format("jYYYY-jMM-jDD")}</div>
                                        <span className="font-12"> ({moment(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime).format("YYYY-MM-DD")})</span>
                                        </>
                                        :
                                        moment(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime).format("YYYY-MM-DD")}
                                        >
                                        <strong className="font-14">
                                            {(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime === '0001-01-01T00:00:00')?
                                                " ------ "
                                            :
                                                (LAN === "IR")?
                                                moment(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime).format("ddd، jD jMMMM")
                                                :
                                                moment(this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalDateTime).format("ddd, MMM D")
                                            }
                                        </strong>

                                    </Tooltip> 
                                </div>
                                <div className="codeCity">
                                    <Tooltip placement="top" title={<div>
                                            <span>{this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalAirport.cityName}, </span>
                                            <span>{this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalAirport.countryName} -  </span>
                                            {this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalAirport.locationName}
                                        </div>}>
                                        {this.props.model.flightSegments[this.props.model.flightSegments.length-1].arrivalAirport.locationCode}
                                    </Tooltip>                                                
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(RouteItem);
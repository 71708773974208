import  React, { Component } from 'react';
import moment from "moment-jalaali";
import {connect} from 'react-redux';
import {default as timemoment} from "moment";
import airplane from "../../image/airplane.svg";
import {Tooltip} from 'antd';

class RouteAllSegments extends Component{
    render(){
        const {Dictionary,LAN}=this.props;
        return(
            <div className={"route-item"}>
                {this.props.model.flightSegments.map((segmentItem)=><div className="route-item-flex" key={segmentItem.flightNumber}>
                    <div className="air-line-info">
                        <div className="air-line-info-inner">
                            <div className="airline-logo-holder">
                                <img src={segmentItem.marketingAirline.photoUrl} alt={segmentItem.marketingAirline.name} className="airline-logo"/>
                            </div>
                            <div className={"airlineInfo"}>
                                <div className={"arilinename"}>{segmentItem.marketingAirline.name}</div>
                                <div className={"flightnumber en-font"}>{segmentItem.flightNumber}</div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="router">
                            <div className="originInfo">
                                <div className="time">
                                    <strong>{moment(segmentItem.departureDateTime).format("HH:mm")}</strong>
                                </div>
                                <div className="date">
                                    <Tooltip placement="top" title=
                                        {(LAN === "IR")?<>
                                        <div>{moment(segmentItem.departureDateTime).format("jYYYY-jMM-jDD")}</div>
                                        <span className="font-12"> ({moment(segmentItem.departureDateTime).format("YYYY-MM-DD")})</span>
                                        </>
                                        :
                                        moment(segmentItem.departureDateTime).format("YYYY-MM-DD")}                                                    
                                        >
                                        <strong className="font-14">
                                            {(LAN === "IR")?
                                            moment(segmentItem.departureDateTime).format("ddd، jD jMMMM")
                                            :
                                            moment(segmentItem.departureDateTime).format("ddd, MMMM D")
                                            }
                                        </strong>
                                    </Tooltip> 
                                </div>
                                <div className="codeCity">
                                    <Tooltip title={<div>
                                            <span>{segmentItem.departureAirport.cityName}, </span>
                                            <span>{segmentItem.departureAirport.countryName} -  </span>
                                            {segmentItem.departureAirport.locationName}
                                        </div>}>
                                        {segmentItem.departureAirport.locationCode}
                                    </Tooltip>
                                </div>
                            </div>
                            <div className="stop-info text-center hidden-xsmall">
                                {/* <div className="stop">{(this.props.model.flightSegments.length-1===0)? <span className="green">{Dictionary.direct}</span> : <span>{this.props.model.flightSegments.length-1} {Dictionary.stops}</span>}</div> */}
                                <div className="dashed-line">
                                    <img src={airplane} alt="airplane" title={"airplane"}/>
                                </div>
                                <div className="duration">
                                    {(this.props.model.journeyDuration==="00:00:00")?"-- : --":
                                    <>
                                        <span>{Dictionary.flightDuration}  </span>
                                        <span className="semi-bold"> {
                                        ((this.props.model.journeyDuration).indexOf(".")>0)?
                                        timemoment(this.props.model.journeyDuration, "d.HH:mm:ss").format("d[d] HH[h] mm[m]")
                                        :timemoment(this.props.model.journeyDuration, "HH:mm:ss").format("HH[h] mm[m]")
                                        }
                                        </span>
                                    </>}
                                </div>
                            </div>
                            <div className="destinationInfo text-end">
                                <div className="time">
                                    <strong className="relative">
                                        {(segmentItem.arrivalDateTime === '0001-01-01T00:00:00')?" -- : -- ":  moment(segmentItem.arrivalDateTime).format("HH:mm")}
                                        {/* {(c > 0)?
                                        <span className="font-12 diff-days"> +{c} </span>
                                        :null} */}
                                    </strong>
                                </div>
                                <div className="date">
                                    <Tooltip placement="top" title=
                                        {(segmentItem.arrivalDateTime === '0001-01-01T00:00:00')?undefined: 
                                        (LAN === "IR")?<>
                                        <div>{moment(segmentItem.arrivalDateTime).format("jYYYY-jMM-jDD")}</div>
                                        <span className="font-12"> ({moment(segmentItem.arrivalDateTime).format("YYYY-MM-DD")})</span>
                                        </>
                                        :
                                        moment(segmentItem.arrivalDateTime).format("YYYY-MM-DD")}
                                        >
                                        <strong className="font-14">
                                            {(segmentItem.arrivalDateTime === '0001-01-01T00:00:00')?
                                                " ------ "
                                            :
                                                (LAN === "IR")?
                                                moment(segmentItem.arrivalDateTime).format("ddd، jD jMMMM")
                                                :
                                                moment(segmentItem.arrivalDateTime).format("ddd, MMMM D")
                                            }
                                        </strong>

                                    </Tooltip> 
                                </div>
                                <div className="codeCity">
                                    <Tooltip placement="top" title={<div>
                                            <span>{segmentItem.arrivalAirport.cityName}, </span>
                                            <span>{segmentItem.arrivalAirport.countryName} -  </span>
                                            {segmentItem.arrivalAirport.locationName}
                                        </div>}>
                                        {segmentItem.arrivalAirport.locationCode}
                                    </Tooltip>                                                
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="baggage-column hidden-xsmall">
                        {segmentItem.baggageInformation.filter(baggageItem=>baggageItem.passengerType === "ADT").map((baggageItem) => (
                        <div key={baggageItem.passengerType}>
                            <span dir="ltr">
                                {baggageItem.baggageAllowance} {baggageItem.unitType} 
                            </span>
                            <span> {Dictionary.for} </span>
                            {baggageItem.passengerType === "ADT" && (
                                <span>{Dictionary.adult}</span>
                            )}
                            {baggageItem.passengerType === "CHD" && (
                                <span>{Dictionary.child}</span>
                            )}
                            {baggageItem.passengerType === "INF" && (
                                <span>{Dictionary.infant}</span>
                            )}
                        </div>
                        ))}
                    </div>
                </div>
                )}
            </div>
        )
    }
}

const mapStateToProps=(state)=>({
    Dictionary:state.VisualData.Dictionary,
    LAN:state.VisualData.LAN
});
export default connect(mapStateToProps)(RouteAllSegments);
import React from "react";
import Autocomplete from "../Common/Autocomplete.js";
import { ServerAddress, Hotel, Header } from "../../Enum/Urls";
import SelectRoom from "./SelectRoom";
import { withRouter } from "react-router-dom";
import queryString from "query-string";
import moment from "moment-jalaali";
import MultiDatePicker from "react-multi-date-picker"
import { connect } from "react-redux";

/* import flightIcon from '../../image/flight.svg';
import hotelIcon from '../../image/hotel.svg'; */
class SearchForm extends React.Component {
  state = {
    searchValidator: {
      location: true,
      dates: true,
    },
    validation: false,
    searchParam: {
      locationId: "",
      location: "",
      searchType: "",
      adults: [2],
      children: [0],
      ages: [0],
      checkin: null,
      checkout: null,
    },
    night: 0,
    searchMode: false,
    calendarIsGregorian: false,
    selectPassengersOptionVisibility: false,
    roomOptionSelect: "2ADT",
    datepickerType: this.props.LAN === "IR" ? "persian":"gregorian",
    datepickerLocale:this.props.LAN === "IR" ? "fa": this.props.LAN === "AR"? "ar":"en"
  };
  selectPassengersOptions = (event) => {
    let option = event.target.value;
    if (option === "2") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [2],
          children: [0],
          ages: [0],
        },
        selectPassengersOptionVisibility: false,
      });
    } else if (option === "1") {
      this.setState({
        searchParam: {
          ...this.state.searchParam,
          adults: [1],
          children: [0],
          ages: [0],
        },
        selectPassengersOptionVisibility: false,
      });
    } else {
      this.setState({
        selectPassengersOptionVisibility: true,
      });
    }
  };
  fillFromURL() {
    if (this.props.location.search !== "") {
      this.setState({ calendarIsGregorian: true });
      let parameters = queryString.parse(this.props.location.search);
      if (this.props.location.pathname === "/hotel/details") {
        this.setState(
          {
            searchParam: {
              ...parameters,
              checkin: parameters.Checkin,
              checkout: parameters.Checkout,
              adults: parameters.Adults.split(",").map((item) => Number(item)),
              children: parameters.Children.split(",").map((item) =>
                Number(item)
              ),
              ages: parameters.Ages
                ? parameters.ages.split(",").map((item) => Number(item))
                : [],
            },
          },
          () => {
            this.calculateDuration();
            this.checkSelectedPassengers();
          }
        );
      } else {
        this.setState(
          {
            searchParam: {
              ...parameters,
              checkin: parameters.checkin,
              checkout: parameters.checkout,
              adults: parameters.adults.split(",").map((item) => Number(item)),
              children: parameters.children
                .split(",")
                .map((item) => Number(item)),
              ages: parameters.ages
                ? parameters.ages.split(",").map((item) => Number(item))
                : [],
            },
          },
          () => {
            this.calculateDuration();
            this.checkSelectedPassengers();
          }
        );
      }
    }
  }
  checkSelectedPassengers() {
    if (
      this.state.searchParam.adults.length > 1 ||
      this.state.searchParam.children[0] > 0
    ) {
      this.setState({
        roomOptionSelect: "OPTIONS",
        selectPassengersOptionVisibility: true,
      });
    } else {
      if (this.state.searchParam.adults[0] === 1) {
        this.setState({
          roomOptionSelect: "1ADT",
          selectPassengersOptionVisibility: false,
        });
      } else if (this.state.searchParam.adults[0] === 2) {
        this.setState({
          roomOptionSelect: "2ADT",
          selectPassengersOptionVisibility: false,
        });
      } else {
        this.setState({
          roomOptionSelect: "OPTIONS",
          selectPassengersOptionVisibility: true,
        });
      }
    }
  }
  componentDidMount() {
    this.fillFromURL();
    if (this.props.collapseMode) {
      this.setState({
        searchMode: false,
      });
    } else {
      this.setState(
        {
          searchParam: {
            ...this.state.searchParam,
            checkin: moment().format("YYYY-MM-DD"),
            checkout: moment().add(1, "day").format("YYYY-MM-DD"),
          },
          searchMode: true,
        },
        () => {
          this.calculateDuration();
        }
      );
    }
  }
  changeCalendarType = () => {
    this.setState((prevState) => {
      return { calendarIsGregorian: !prevState.calendarIsGregorian };
    });
  };
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (JSON.stringify(prevProps) !== JSON.stringify(this.props)) {
      this.fillFromURL();
    }
  }
  disabledDate = (index, current) => {
    // Can not select days before today and today

    if (index === "checkout") {
      return current && current <= moment(this.state.searchParam.checkin);
    } else {
      return current && current <= moment().endOf("day").subtract(1, "day");
    }
  };
  resultRef = () => {
    console.log(this.myRef);
  };
  defaultDay = new Date();
  toggleSearchMode = () => {
    this.setState((prevState) => ({
      searchMode: !prevState.searchMode,
    }));
  };
  handleClick = () => {
    this.setState({
      validation: true,
    });
    if (this.state.searchParam.locationId && this.state.searchParam.checkin && this.state.searchParam.checkout) {
      if (this.state.searchParam.searchType === 6) {
        this.props.history.push(
          `/hotel/details?Id=${this.state.searchParam.locationId}&Adults=${this.state.searchParam.adults}&Children=${this.state.searchParam.children}&Ages=${this.state.searchParam.ages}&Checkin=${this.state.searchParam.checkin}&Checkout=${this.state.searchParam.checkout}`
        );
      } else {
        this.props.history.push(
          `/hotel/result?locationId=${this.state.searchParam.locationId}&location=${this.state.searchParam.location}&checkin=${this.state.searchParam.checkin}&checkout=${this.state.searchParam.checkout}&adults=${this.state.searchParam.adults}&children=${this.state.searchParam.children}&ages=${this.state.searchParam.ages}`
        );
      }
    }
  };
  handlePassenger = (e) => {
    let passenger = JSON.stringify(this.state.searchParam);
    passenger = JSON.parse(passenger);
    passenger.adults = e.adult;
    passenger.children = e.children;
    passenger.ages = e.age;
    this.setState({
      searchParam: { ...this.state.searchParam, ...passenger },
    });
  };
  calculateDuration = () => {
    let night = moment(this.state.searchParam.checkout).diff(
      moment(this.state.searchParam.checkin),
      "days"
    );
    this.setState({
      night: night,
    });
  };

  toggleDatepicker = () =>{
    if (this.props.LAN ==="IR"){
      if(this.state.datepickerType === "gregorian"){
        this.setState({
          datepickerType:"persian",
          datepickerLocale:"fa"
        })
      }else{
        this.setState({
          datepickerType:"gregorian",
          datepickerLocale:"en"
        })
      }
    }else{
      debugger;
    }
  }

  getCallback = (id, text, type) => {
    let searchParamCatch = this.state.searchParam;
    searchParamCatch.locationId = id;
    searchParamCatch.location = text;
    searchParamCatch.searchType = type;
    this.setState({
      searchParam: { ...this.state.searchParam, ...searchParamCatch },
    });
  };


  render() {
    const { Dictionary, LAN } = this.props;
    return (
      <>
        <div
          className={
            this.props.collapseMode
              ? this.state.searchMode
                ? "search-box-holder inline clearfix"
                : "search-box-holder inline clearfix"
              : "search-box-holder clearfix"
          }
        >
          <div>
            {this.state.searchMode ? (
              <>
                {this.props.fixedSearchHolder ? (
                  <div
                    className="fixed-search-overlay"
                    onClick={this.toggleSearchMode}
                  />
                ) : null}
                <div
                  className={
                    this.props.fixedSearchHolder ? "fixed-search-holder" : null
                  }
                >
                  <div className="searchbox-inner">
                    <div className="searchBar hotel">
                      {this.props.collapseMode && (
                        <>
                          {this.state.searchMode && (
                            <div className="clearfix">
                              <button
                                type="button"
                                className={"button show-close-btn pull-end"}
                                onClick={this.toggleSearchMode}
                              >
                                <span className="itours-icon gray-close-icon icon-large" />
                              </button>
                            </div>
                          )}
                        </>
                      )}
                      <div className="float-row half-gap">
                        <div className="col-small-12 col-medium-10">
                          <div className="float-row half-gap">
                            <div className="col-small-12 col-medium-3 col-large-4">
                              <div className="form-group hotel-destination-holder">
                                <Autocomplete
                                  defaultText={this.state.searchParam.location}
                                  defaultValue={
                                    this.state.searchParam.locationId
                                  }
                                  validate={this.state.searchValidator.location}
                                  getValue={this.getCallback.bind(this)}
                                  placeholder={Dictionary.enterYourDestination}
                                  url={
                                    ServerAddress.Type +
                                    ServerAddress.Hotel +
                                    Hotel.GetLocation
                                  }
                                  min={3}
                                  name="sample"
                                  param="value"
                                  header={Header}
                                />
                                  {this.state.validation &&
                                    !this.state.searchParam.location && (
                                    <b className="validator-label">
                                      {Dictionary.pleaseEnterDestination}
                                    </b>
                                  )}
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-6 col-large-5">
                              <div className="hotel-dates-group">
                                <div className="checkin-column">
                                  <div className="relative">
                                      <MultiDatePicker 
                                        calendarPosition={LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left"}
                                        weekDays={this.state.datepickerLocale === "fa" ? [
                                          "ش", 
                                          "ی", 
                                          "د", 
                                          "س", 
                                          "چ", 
                                          "پ", 
                                          "ج"
                                        ]:this.state.datepickerLocale === "ar" ?[
                                          "الأحد",
                                          "الإثنين",
                                          "الثلاثاء",
                                          "الأربعاء",
                                          "الخميس",
                                          "الجمعة",
                                          "السبت"
                                        ]:undefined}
                                        digits={this.state.datepickerLocale === "ar" ? [
                                          "0",
                                          "1",
                                          "2",
                                          "3",
                                          "4",
                                          "5",
                                          "6",
                                          "7",
                                          "8",
                                          "9"
                                        ]:undefined}
                                        inputClass="multi-datepicker-input"
                                        className={`multi-datepicker ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`}
                                        minDate={this.state.datepickerType ==="persian"? moment().format("jYYYY/jMM/jDD") : moment().format("YYYY/MM/DD")}
                                        format={"YYYY-MM-DD"}
                                        calendar={this.state.datepickerType}
                                        locale={this.state.datepickerLocale}
                                        value={this.state.datepickerType ==="persian"? moment(this.state.searchParam.checkin,"YYYY/MM/DD").format("jYYYY/jMM/jDD"): this.state.searchParam.checkin}
                                        onChange={(d) => {
                                          const e = d.toDate();
                                          this.setState(
                                            {
                                              searchParam: {
                                                ...this.state.searchParam,
                                                checkin: moment(e).format(
                                                  "YYYY-MM-DD"
                                                ),
                                                checkout: moment(e)
                                                  .add(1, "day")
                                                  .format("YYYY-MM-DD"),
                                              },
                                            },
                                            () => {
                                              this.calculateDuration();
                                            }
                                          );
                                        }}
                                      >
                                        {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                                      </MultiDatePicker>
                                      
                                    {/*
                                      <DatePicker
                                        value={moment(
                                          this.state.searchParam.checkin
                                        )}
                                        placeholder=""
                                        format={"YYYY-MM-DD"}
                                        showTime={false}
                                        disabledDate={this.disabledDate.bind(
                                          this,
                                          "checkin"
                                        )}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              searchParam: {
                                                ...this.state.searchParam,
                                                checkin: moment(e).format(
                                                  "YYYY-MM-DD"
                                                ),
                                                checkout: moment(e)
                                                  .add(1, "day")
                                                  .format("YYYY-MM-DD"),
                                              },
                                            },
                                            () => {
                                              this.calculateDuration();
                                            }
                                          );
                                        }}
                                      />
                                    */}
                                    {this.state.validation &&
                                      !this.state.searchParam.checkin && (
                                        <b className="validator-label">
                                          {Dictionary.pleaseSelectCheckinDate}
                                        </b>
                                      )}
                                  </div>
                                </div>
                                <div className="checkout-column">
                                  <div className="relative">
                                      <MultiDatePicker 
                                        calendarPosition={LAN === "IR" || LAN === "AR" ?"bottom-right":"bottom-left"}
                                        weekDays={this.state.datepickerLocale === "fa" ? [
                                          "ش", 
                                          "ی", 
                                          "د", 
                                          "س", 
                                          "چ", 
                                          "پ", 
                                          "ج"
                                        ]:this.state.datepickerLocale === "ar" ?[
                                          "الأحد",
                                          "الإثنين",
                                          "الثلاثاء",
                                          "الأربعاء",
                                          "الخميس",
                                          "الجمعة",
                                          "السبت"
                                        ]:undefined}
                                        digits={this.state.datepickerLocale === "ar" ? [
                                          "0",
                                          "1",
                                          "2",
                                          "3",
                                          "4",
                                          "5",
                                          "6",
                                          "7",
                                          "8",
                                          "9"
                                        ]:undefined}
                                        inputClass="multi-datepicker-input"
                                        className={`multi-datepicker ${LAN === "IR" && this.state.datepickerLocale === "en" ? "en-font":""}`}
                                        minDate={
                                          this.state.datepickerType ==="persian"? 
                                            moment(this.state.searchParam.checkin).add(1, "day").format("jYYYY/jMM/jDD") 
                                          :
                                            moment(this.state.searchParam.checkin).add(1, "day").format("YYYY/MM/DD")
                                        }
                                        format={"YYYY-MM-DD"}
                                        calendar={this.state.datepickerType}
                                        locale={this.state.datepickerLocale}
                                        value={this.state.datepickerType ==="persian"? moment(this.state.searchParam.checkout,"YYYY/MM/DD").format("jYYYY/jMM/jDD"): this.state.searchParam.checkout}
                                        onChange={(d) => {
                                          const e = d.toDate();
                                          this.setState(
                                            {
                                              searchParam: {
                                                ...this.state.searchParam,
                                                checkout: moment(e).format(
                                                  "YYYY-MM-DD"
                                                )
                                              },
                                            },
                                            () => {
                                              this.calculateDuration();
                                            }
                                          );
                                        }}
                                      >
                                        {LAN==="IR" && <button type="button" className="button blue-btn small-btn margin-bottom-half margin-top-half fa-font" onClick={this.toggleDatepicker}>{this.state.datepickerLocale === "fa" ? "تقویم میلادی":"تقویم شمسی"}</button>}
                                      </MultiDatePicker>
                                      
                                    {/*<DatePicker
                                        value={moment(
                                          this.state.searchParam.checkout
                                        )}
                                        placeholder=""
                                        format={"YYYY-MM-DD"}
                                        showTime={false}
                                        disabledDate={this.disabledDate.bind(
                                          this,
                                          "checkout"
                                        )}
                                        onChange={(e) => {
                                          this.setState(
                                            {
                                              searchParam: {
                                                ...this.state.searchParam,
                                                checkout: moment(e).format(
                                                  "YYYY-MM-DD"
                                                ),
                                              },
                                            },
                                            () => {
                                              this.calculateDuration();
                                            }
                                          );
                                        }}
                                      />
                                    */}
                                    {this.state.validation &&
                                      !this.state.searchParam.checkout && (
                                        <b className="validator-label">
                                          {Dictionary.pleaseSelectCheckoutDate}
                                        </b>
                                      )}
                                  </div>
                                </div>
                                <div className="nights-column">
                                  <div className="relative">
                                    <div className="relative">
                                      <span className="form-input">
                                        {this.state.night}{" "}
                                      </span>{" "}
                                      {Dictionary.nights}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-small-12 col-medium-3">
                              <div className="form-group">
                                <select
                                  className="form-select"
                                  onChange={(event) =>
                                    this.selectPassengersOptions(event)
                                  }
                                >
                                  <option
                                    value="2"
                                    selected={
                                      this.state.roomOptionSelect === "2ADT"
                                    }
                                  >
                                    {" "}
                                    {1 + " " + Dictionary.room}
                                    {Dictionary.comma}{" "}
                                    {2 + " " + Dictionary.adults}
                                    {Dictionary.comma}{" "}
                                    {0 + " " + Dictionary.children}
                                  </option>
                                  <option
                                    value="1"
                                    selected={
                                      this.state.roomOptionSelect === "1ADT"
                                    }
                                  >
                                    {" "}
                                    1 {Dictionary.room}
                                    {Dictionary.comma} 1 {Dictionary.adults}
                                    {Dictionary.comma} 0 {Dictionary.children}
                                  </option>
                                  <option
                                    value="0"
                                    selected={
                                      this.state.roomOptionSelect === "OPTIONS"
                                    }
                                  >
                                    {this.state.roomOptionSelect === "OPTIONS"
                                      ? this.state.searchParam.adults.length +
                                        Dictionary.room +
                                        Dictionary.comma +
                                        1 +
                                        Dictionary.adults +
                                        Dictionary.comma +
                                        0 +
                                        Dictionary.children
                                      : Dictionary.moreOptions}
                                  </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          {this.state.selectPassengersOptionVisibility && (
                            <div className="passengers-options-holder margin-bottom">
                              <SelectRoom
                                onSelectItem={(e) => {
                                  this.handlePassenger(e);
                                }}
                                defaultAdult={this.state.searchParam.adults}
                                defaultChildren={
                                  this.state.searchParam.children
                                }
                                defaultAges={this.state.searchParam.ages}
                              />
                            </div>
                          )}
                        </div>
                        <div className="col-small-12 col-medium-2">
                          <button
                            className="searchBtn hotel-search-btn button red-btn full-width"
                            type="button"
                            onClick={() => {
                              this.handleClick();
                            }}
                          >
                            <span className="itours-icon search-icon margin-end-light" />
                            {Dictionary.search}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              this.state.searchParam.checkin == null || (
                <>
                  <div className={"headerInformation"}>
                    <div className={"title"}>
                      <div className={"hidden-xsmall hidden-small"}>
                        <span className="itours-icon hotel-dark margin-end-light icon-small" />{" "}
                        {Dictionary.searchSummary}
                      </div>
                      {/*<div className={"hidden-medium hidden-large hidden-xlarge"}>*/}
                      {/*            searched condition*/}
                      {/*</div>*/}
                    </div>
                    <div className={"buttonContainer"}>
                      <button
                        type="button"
                        className={"button color-1-btn pull-end"}
                        onClick={this.toggleSearchMode}
                      >
                        {Dictionary.modifySearch}
                      </button>
                    </div>
                  </div>
                  <div className="searched-information">
                    <div className={"fakeForm"}>
                      <div className="textbox">
                        <span className="itours-icon location-dark margin-end-light icon-small" />{" "}
                        {this.state.searchParam.location}
                      </div>
                      <div className="textbox">
                        <span className="itours-icon checkin-dark margin-end-light icon-small" />
                        <span className="inline-block margin-end-light">
                          {" "}
                          {this.state.searchParam.checkin}{" "}
                        </span>
                        <span className={"font-11 inline-block"}>
                          {" "}
                          {LAN === "IR" && (
                            <>
                              (
                              {moment(this.state.searchParam.checkin).format(
                                "jYYYY-jMM-jDD"
                              )}
                              )
                            </>
                          )}
                        </span>
                      </div>
                      <div className="textbox">
                        <span className="itours-icon checkout-dark margin-end-light icon-small" />
                        <span className="inline-block margin-end-light">
                          {this.state.searchParam.checkout}
                        </span>
                        <span className={"font-11 inline-block"}>
                          {LAN === "IR" && (
                            <>
                              (
                              {moment(this.state.searchParam.checkout).format(
                                "jYYYY-jMM-jDD"
                              )}
                              )
                            </>
                          )}
                        </span>
                      </div>
                      <div className="textbox">
                        <span className="itours-icon moon-dark margin-end-light icon-small" />{" "}
                        {moment(this.state.searchParam.checkout).diff(
                          moment(this.state.searchParam.checkin),
                          "days"
                        )}
                      </div>
                      <div className="textbox">
                        <span className="itours-icon hotel-dark margin-end-light icon-small" />{" "}
                        {this.state.searchParam.adults.length.toString()}{" "}
                        {Dictionary.room}
                      </div>
                    </div>
                  </div>
                </>
              )
            )}
          </div>
        </div>
      </>
    );
  }
}
const RouterDOM = withRouter(SearchForm);
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(RouterDOM);

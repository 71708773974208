import React from "react";
import { connect } from "react-redux";
import { Form,Input} from "antd";
import moment from "moment";
import { setHomeCSS } from "./../Actions/VisualDataAction";
import axios from "axios";
import { ServerAddress, Hotel, Header,User } from "../Enum/Urls";
import Currency from "../Enum/Currency";
import LayoutConfig from "../Config/layoutConfig";
import NaminHomeAds from '../components/NaminHome/NaminHomeAds/NaminHomeAds';
import NaminHomeDestinations from '../components/NaminHome/NaminHomeDestinations/NaminHomeDestinations';
import Banner from "../components/Home/Banner";
//import PriceCalendar from "../components/Common/PriceCalendar";

class Home extends React.Component {
  state={
    newsLetterLoading:false,
    newsLetterFormMessage:undefined,
    showModal:false
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.props.setHomeCSS(true);
    axios({
      method: "get",
      url: `${
        ServerAddress.Type + ServerAddress.Hotel + Hotel.GetLocation
      }?value=ist`,
      headers: { ...Header },
    });
  }
  handleLink(idLocation, locationName) {
    let date = new Date();
    let checkIn = moment(date).add(15, "d").format("YYYY-MM-DD");
    let checkout = moment(date).add(18, "d").format("YYYY-MM-DD");
    this.props.history.push(
      `/hotel/result?locationId=${idLocation}&location=${locationName}&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`
    );
  }
  handleFlightLink(destCode, destName) {
    let culture = Currency.getNationality();
    let originCode = null;
    let originName = null;
    switch (culture) {
      case "IR":
        originCode = "THR";
        originName = "Tehran,%20Iran(THR-All%20Airports)";
        break;
      case "NO":
        originCode = "OSL";
        originName = "Oslo,%20Norway(OSL-All%20Airports)";
        break;
      case "SE":
        originCode = "STO";
        originName = "Stockholm,%20Sweden(STO-All%20Airports)";
        break;
      case "DK":
        originCode = "HEL";
        originName = "Helsinki,%20Finland(HEL-All%20Airports)";
        break;
      case "US":
        originCode = "OSL";
        originName = "Oslo,%20Norway(OSL-All%20Airports)";
        break;
      default:
        originCode = "HEL";
        originName = "Helsinki,%20Finland(HEL-All%20Airports)";
    }
    let date = new Date();
    let depart = moment(date).add(15, "d").format("YYYY-MM-DD");

    this.props.history.push(
      `/flight/result?originCodes=${originCode}&originNames=${originName}&destinationCodes=${destCode}&destinationNames=${destName}&departureDateTimes=${depart}&adult=1&child=0&infant=0&cabinClassCode=0&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=all`
    );
  }
  closeModal = () =>{
    if (!this.state.newsLetterLoading){
      this.setState({showModal:false});
    }
  }
  handleReset = () => {
    this.props.form.resetFields();
  };
  handleSubmitNewsletter = e =>{
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({newsLetterLoading:true,showModal:true});
        const params = {"email":values.email}
        axios.post(`${ServerAddress.Type}${ServerAddress.User}${User.CreateNewsLetter}`, params, {headers: {...Header,},})
        .then(({ data }) => {
          if (data.error) {
             this.setState({
                newsLetterLoading:false,
                newsLetterFormMessage:{text:data.error.message,success:data.success}
              });
            }else{
              this.handleReset();
              this.setState({
                newsLetterLoading:false,
                newsLetterFormMessage:{text:this.props.Dictionary.youveSuccessfullySubscribedToOurNewsletter,success:data.success}
              });
           }
        })
        .catch((error) => {
          this.setState({
            newsLetterLoading:false,
            newsLetterFormMessage:{text:error.response.data.error.message,success:false}
          });
        });
      }
    });
  }
  componentWillUnmount() {
    this.props.setHomeCSS(false);
  }
  render() {
    const { Dictionary } = this.props;
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="Home flight">
        {/* <div className="page-container padding-top padding-bottom">
          <PriceCalendar />
        </div> */}
        <div
          className={`itours-modal ${this.state.showModal ? "itours-modal visible" : ""}`}
        >
          <div className="modal-overlay" onClick={this.closeModal} />
          <div className="modal-inner small-width text-center">
            <div className="modal-main">
              {this.state.newsLetterLoading ? (
                <div className="loading-outer-container">
                  <div>
                    <div className="loading-inner-container">
                      <div className="item-1"></div>
                      <div className="item-2"></div>
                      <div className="item-3"></div>
                      <div className="item-4"></div>
                      <div className="item-5"></div>
                    </div>
                    <div className="text-center">
                      {Dictionary.loadingData} ...
                    </div>
                  </div>
                </div>
              ) : (
                this.state.newsLetterFormMessage && 
                <div>
                  <br/><br/>
                    {this.state.newsLetterFormMessage.success?
                    <span className="itours-icon check-green icon-xlarge" />
                    :
                    <span className="itours-icon error-icon icon-xlarge" />
                    }
                  <div className="margin-top margin-bottom">
                  {this.state.newsLetterFormMessage.text}.
                  </div>
                  <br/>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button color-1-btn"
                onClick={this.closeModal}
                disabled={this.state.newsLetterLoading}
              >
                {Dictionary.ok}
              </button>
            </div>
          </div>
        </div>
        <Banner searchType="flight" />
                
        {LayoutConfig.themeClassName === 'naminTravel-theme' && <><NaminHomeAds/><NaminHomeDestinations/> </>}

        <div className="newletter-section">
          <div className="page-container">
            <div className="newsletter-holder">
              <div className="float-row">
                <div className="col-small-12 col-medium-6">
                  <div className="newsleter-inner">
                    <h4 className="normal-title">
                      {Dictionary.newsletterSubscription}{" "}
                    </h4>
                    <p>{Dictionary.newsLeterText}</p>
                  </div>
                </div>
                <div className="col-small-12 col-medium-6 text-end">
                  <Form className="newsletter-form" onSubmit={this.handleSubmitNewsletter} >
                    <div className="form-group inline-newsletter-form">
                      <Form.Item className="no-margin">
                        {getFieldDecorator('email', {
                          rules: [{ required: true, message: Dictionary.EnterYourEmailAddress },
                            { type:"email", message: Dictionary.enteredEmailAddressIsNotValid }],
                        })(
                          <Input
                            type="text"
                            placeholder={Dictionary.EnterYourEmailAddress}
                            className="form-input placeholder-right"
                        />,
                        )}
                      </Form.Item>                   
                      <button type="submit" className="button color-1-btn">
                        {Dictionary.subscribe}
                      </button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === 'naminTravel-theme')  || (
          <>
            <div className="landmarks-section">
              <div className="page-container-fluid">
                <div className="float-row text-center">
                  <h3 className="purple section-title">
                    {/*{(LAN === "IR")?
                      <>
                      <span className="inline-block-middle margin-end-half">
                          {Dictionary.with}
                      </span>
                      <img src={logo} alt="itours" className="inline-itours-logo inline-block-middle " />
                      <span className="inline-block-middle margin-end-half">
                          {Dictionary.BookCheapInternationalFlights}
                      </span>                                
                      </>                                
                      :
                      <>
                      <span className="inline-block-middle margin-end-half">
                          {Dictionary.BookCheapInternationalFlightsWith}
                      </span>
                      <img src={logo} alt="itours" className="inline-itours-logo inline-block-middle " />                                
                      </>
                      } */}

                    <span className="inline-block-middle margin-end-half">
                      {Dictionary.topDestinations}
                    </span>
                  </h3>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() =>
                        this.handleFlightLink(
                          "DXB",
                          "Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)"
                        )
                      }
                    >
                      <div className="landmarg-icon landmark-dubai"></div>
                      <span className="title">{Dictionary.Dubai}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() =>
                        this.handleFlightLink(
                          "PAR",
                          "Paris,%20France(PAR-All%20Airports)"
                        )
                      }
                    >
                      <div className="landmarg-icon landmark-paris"></div>
                      <span className="title">{Dictionary.Paris}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() =>
                        this.handleFlightLink(
                          "LON",
                          "London,%20United%20Kingdom(LON-All%20Airports)"
                        )
                      }
                    >
                      <div className="landmarg-icon landmark-london"></div>
                      <span className="title">{Dictionary.London}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() =>
                        this.handleFlightLink(
                          "MOW",
                          "Moscow,%20Russia(MOW-All%20Airports)"
                        )
                      }
                    >
                      <div className="landmarg-icon landmark-moscow"></div>
                      <span className="title">{Dictionary.Moscow}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() =>
                        this.handleFlightLink(
                          "SYD",
                          "Sydney,%20Australia(SYD-All%20Airports)"
                        )
                      }
                    >
                      <div className="landmarg-icon landmark-sydney"></div>
                      <span className="title">{Dictionary.Sydney}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() =>
                        this.handleFlightLink("DEL", "Indira%20Gandhi%20Intl")
                      }
                    >
                      <div className="landmarg-icon landmark-delhi"></div>
                      <span className="title">{Dictionary.Delhi}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cities-section">
              <div className="page-container">
                <h3 className="color-1 section-title text-center">
                  {Dictionary.incrediblePlaces}
                </h3>

                <div className="float-row">
                  <div className="col-xsmall-12 col-small-6 margin-bottom">
                    <div className="thumbnail-link">
                      <div className="city-link city-ist large"></div>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">
                            {Dictionary.Istanbul}
                          </span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(75286, "Istanbul")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "IST",
                                  "Istanbul,%20Turkey(IST-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-6 margin-bottom">
                    <div className="thumbnail-link">
                      <div className="city-link city-dubai large"></div>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Dubai}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(267, "Dubai")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "DXB",
                                  "Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-4 margin-bottom">
                    <div className="thumbnail-link">
                      <div className="city-link city-beijing medium"></div>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Beijing}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(12827, "Beijing")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "BJS",
                                  "Beijing,%20China(BJS-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-4 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-rome medium"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Rome}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(54323, "Rome")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "ROM",
                                  "Rome,%20Italy(ROM-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-4 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-phuket medium"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Phuket}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(74961, "Phuket")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "HKT",
                                  "Phuket%20International"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-baku small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Baku}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(5120, "Baku")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "BAK",
                                  "Baku,%20Azerbaijan(BAK-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-delhi small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Delhi}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(49497, "Delhi")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "DEL",
                                  "Indira%20Gandhi%20Intl"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-bali small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Bali}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(47947, "Kuta")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink("DPS", "Ngurah%20Rai")
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-copenhagen small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">
                            {Dictionary.Copenhagen}
                          </span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleLink(22664, "Copenhagen")
                              }
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "CPH",
                                  "Copenhagen,%20Denmark(CPH-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setHomeCSS: (data) => {
    dispatch(setHomeCSS(data));
  },
});
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

const WrappedNewsletterForm = Form.create({ name: 'newsLetterForm' })(Home);

export default connect(mapStateToProps, mapDispatchToProps)(WrappedNewsletterForm);

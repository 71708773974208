import React from "react";
import moment from "moment";
import { setHomeCSS } from "../../Actions/VisualDataAction";
import { connect } from "react-redux";
import Currency from "../../Enum/Currency";
import axios from "axios";
import { ServerAddress, Hotel, Header } from "../../Enum/Urls";
import LayoutConfig from "../../Config/layoutConfig";
import Banner from '../../components/Home/Banner';
import NaminHomeAds from '../../components/NaminHome/NaminHomeAds/NaminHomeAds';
import NaminHomeDestinations from '../../components/NaminHome/NaminHomeDestinations/NaminHomeDestinations';

class HotelIndex extends React.Component {
  componentDidMount() {
    this.props.setHomeCSS(false);
    window.scrollTo(0, 0);
    this.props.setHomeCSS(true);
    axios({
      method: "get",
      url: `${
        ServerAddress.Type + ServerAddress.Hotel + Hotel.GetLocation
      }?value=ist`,
      headers: { ...Header },
    });
  }
  handleLink(idLocation, locationName) {
    let date = new Date();
    let checkIn = moment(date).add(15, "d").format("YYYY-MM-DD");
    let checkout = moment(date).add(18, "d").format("YYYY-MM-DD");
    this.props.history.push(
      `/hotel/result?locationId=${idLocation}&location=${locationName}&checkin=${checkIn}&checkout=${checkout}&adults=2&children=0&ages=0`
    );
  }
  handleFlightLink(destCode, destName) {
    let culture = Currency.getNationality();
    let originCode = null;
    let originName = null;
    switch (culture) {
      case "IR":
        originCode = "THR";
        originName = "Tehran,%20Iran(THR-All%20Airports)";
        break;
      case "NO":
        originCode = "OSL";
        originName = "Oslo,%20Norway(OSL-All%20Airports)";
        break;
      case "SE":
        originCode = "STO";
        originName = "Stockholm,%20Sweden(STO-All%20Airports)";
        break;
      case "DK":
        originCode = "HEL";
        originName = "Helsinki,%20Finland(HEL-All%20Airports)";
        break;
      case "US":
        originCode = "OSL";
        originName = "Oslo,%20Norway(OSL-All%20Airports)";
        break;
      default:
        originCode = "HEL";
        originName = "Helsinki,%20Finland(HEL-All%20Airports)";
    }
    let date = new Date();
    let depart = moment(date).add(15, "d").format("YYYY-MM-DD");

    this.props.history.push(
      `/flight/result?originCodes=${originCode}&originNames=${originName}&destinationCodes=${destCode}&destinationNames=${destName}&departureDateTimes=${depart}&adult=1&child=0&infant=0&cabinClassCode=0&airTripType=OneWay&nonStop=false&airlineCode=all&airlineText=all`
    );
  }

  render() {
    const { Dictionary } = this.props;
    return (
      <div className="Home hotel">
        <Banner searchType="hotel" />

        {LayoutConfig.themeClassName === 'naminTravel-theme' && <><NaminHomeAds/><NaminHomeDestinations/> </>}

        <div className="newletter-section">
          <div className="page-container">
            <div className="newsletter-holder">
              <div className="float-row">
                <div className="col-small-12 col-medium-6">
                  <div className="newsleter-inner">
                    <h4 className="normal-title">
                      {Dictionary.newsletterSubscription}{" "}
                    </h4>
                    <p>{Dictionary.newsLeterText}</p>
                  </div>
                </div>
                <div className="col-small-12 col-medium-6 text-end">
                  <form className="newsletter-form">
                    <div className="form-group inline-newsletter-form">
                      <input
                        type="email"
                        placeholder={Dictionary.EnterYourEmailAddress}
                        className="form-input placeholder-right"
                        disabled
                      />
                      <button
                        type="button"
                        className="button color-1-btn"
                        disabled
                      >
                        {Dictionary.subscribe}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === 'naminTravel-theme') || (
          <>
            <div className="landmarks-section">
              <div className="page-container-fluid">
                <div className="float-row text-center">
                  <h3 className="purple section-title">
                    <span className="inline-block-middle margin-end-half">
                      {/* {Dictionary.BookCheapInternationalFlightsWith} */}
                      {Dictionary.topDestinations}
                    </span>
                    {/* <img src={logo} alt="itours" className="inline-itours-logo inline-block-middle " /> */}
                  </h3>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() => this.handleLink(267, "Dubai")}
                    >
                      <div className="landmarg-icon landmark-dubai"></div>
                      <span className="title">{Dictionary.Dubai}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() => this.handleLink(39933, "Paris")}
                    >
                      <div className="landmarg-icon landmark-paris"></div>
                      <span className="title">{Dictionary.Paris}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() => this.handleLink(40977, "London")}
                    >
                      <div className="landmarg-icon landmark-london"></div>
                      <span className="title">{Dictionary.London}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() => this.handleLink(71508, "Moscow")}
                    >
                      <div className="landmarg-icon landmark-moscow"></div>
                      <span className="title">{Dictionary.Moscow}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() => this.handleLink(3388, "Sydney")}
                    >
                      <div className="landmarg-icon landmark-sydney"></div>
                      <span className="title">{Dictionary.Sydney}</span>
                    </div>
                  </div>
                  <div className="col-small-4 col-medium-2">
                    <div
                      className="landmark-link"
                      onClick={() => this.handleLink(49497, "Delhi")}
                    >
                      <div className="landmarg-icon landmark-delhi"></div>
                      <span className="title">{Dictionary.Delhi}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="cities-section">
              <div className="page-container">
                <h3 className="color-1 section-title text-center">
                  {Dictionary.incrediblePlaces}
                </h3>
                <div className="float-row">
                  <div className="col-xsmall-12 col-small-6 margin-bottom">
                    <div className="thumbnail-link">
                      <div className="city-link city-ist large"></div>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">
                            {Dictionary.Istanbul}
                          </span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(75286, "Istanbul")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "IST",
                                  "Istanbul,%20Turkey(IST-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-6 margin-bottom">
                    <div className="thumbnail-link">
                      <div className="city-link city-dubai large"></div>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Dubai}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(267, "Dubai")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "DXB",
                                  "Dubai,%20United%20Arab%20Emirates(DXB-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-4 margin-bottom">
                    <div className="thumbnail-link">
                      <div className="city-link city-beijing medium"></div>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Beijing}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(12827, "Beijing")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "BJS",
                                  "Beijing,%20China(BJS-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-4 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-rome medium"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Rome}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(54323, "Rome")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "ROM",
                                  "Rome,%20Italy(ROM-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-4 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-phuket medium"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Phuket}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(74961, "Phuket")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "HKT",
                                  "Phuket%20International"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-baku small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Baku}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(5120, "Baku")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "BAK",
                                  "Baku,%20Azerbaijan(BAK-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-delhi small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Delhi}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(49497, "Delhi")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "DEL",
                                  "Indira%20Gandhi%20Intl"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-bali small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">{Dictionary.Bali}</span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() => this.handleLink(47947, "Kuta")}
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink("DPS", "Ngurah%20Rai")
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="col-xsmall-12 col-small-3 margin-bottom">
                    <div className="thumbnail-link">
                      <span className="city-link city-copenhagen small"></span>
                      <div className="bg-on-hover">
                        <h5 className="title center">
                          <span className="cityName">
                            {Dictionary.Copenhagen}
                          </span>
                          <span className="show-on-hover">
                            <span
                              className="itours-icon hotel-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleLink(22664, "Copenhagen")
                              }
                            />
                            <span
                              className="itours-icon top-plane-white hotel-flight-search pointer"
                              onClick={() =>
                                this.handleFlightLink(
                                  "CPH",
                                  "Copenhagen,%20Denmark(CPH-All%20Airports)"
                                )
                              }
                            />
                          </span>
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}
const mapDispatchToProps = (dispatch) => ({
  setHomeCSS: (data) => {
    dispatch(setHomeCSS(data));
  },
});
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps, mapDispatchToProps)(HotelIndex);

import React, { useEffect, useState,useRef} from "react";
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import queryString from 'query-string';
import axios from "axios";
import {
   Header,
   Package as PackageURL,
   ServerAddress,
 } from "../../Enum/Urls";
import PackageSearchForm from "../../components/Package/PackageSearchForm";
import PackageFlightItem from "../../components/Package/PackageFlightItem";
import PackageHotelItem from "../../components/Package/PackageHotelItem";
import PackageSelected from "../../components/Package/PackageSelected";
import PackageFormSearchTOF from "../../components/TOF/Package-Form-Search-TOF";
import FilterForm from "../../components/Package/FilterForm";

import Loading from "../../components/Common/Loading";
import Page from "../../components/Common/Page";


import LayoutConfig from "../../Config/layoutConfig";
import moment from "moment";

const PackageResult = props => {

   const [defaultValues,setDefaultValues] = useState();
   const [packageAvailabilityToken,setPackageAvailabilityToken] = useState();
   const [getAvailabilityCounter,setGetAvailabilityCounter] = useState(0);
   const [flightsMobileVisibility,setFlightsMobileVisibility] = useState(false);
   const [hotelsMobileVisibility,setHotelsMobileVisibility] = useState(false);
   const [flightFilterMobileVisibility,setFlightFilterMobileVisibility] = useState(false);
   const [hotelFilterMobileVisibility,setHotelFilterMobileVisibility] = useState(false);
   const [flightLoading,setFlightLoading] = useState(true);
   const [hotelLoading,setHotelLoading] = useState(true);
   const [flightResult,setFlightResult] = useState([]);
   const [hotelResult,setHotelResult] = useState([]);
   const [flightFilteredResult,setFlightFilteredResult] = useState([]);
   const [hotelFilteredResult,setHotelFilteredResult] = useState([]);
   const [flightPagedResult,setFlightPagedResult] = useState([]);
   const [hotelPagedResult,setHotelPagedResult] = useState([]);
   const [flightCurrentPage,setFlightCurrentPage] = useState(0);
   const [hotelCurrentPage,setHotelCurrentPage] = useState(0);
   const [selectedFlight,setSelectedFlight] = useState();
   const [selectedHotel,setSelectedHotel] = useState();

   //filters:
   const [filterHotelName,setFilterHotelName] = useState("");
   const [filterHotelRating,setFilterHotelRating] = useState([]);
   const [filterHotelFeatures,setFilterHotelFeatures] = useState([]);
   const [filterHotelRegion,setFilterHotelRegion] = useState([]);
   const [filterHotelBoard,setFilterHotelBoard] = useState([]);
   const [filterFlightStop,setFilterFlightStop] = useState([]);
   const [filterFlightCabinClass,setFilterFlightCabinClass] = useState([]);
   const [filterFlightAirline,setFilterFlightAirline] = useState([]);
   const [filterFlightDepartureTime,setFilterFlightDepartureTime] = useState([]);
   const [filterFlightArrivalTime,setFilterFlightArrivalTime] = useState([]);

   const getAvailabilityToken = () =>{

      let parameters = queryString.parse(props.location.search);
      if(parameters){
         
         let adults=[];         
         const searchedAdults= parameters.adults.split(",");
         for(let i=0 ; i<searchedAdults.length ; i++){
            adults.push(+searchedAdults[i]);
         }

         let children=[];
         const searchedChildren= parameters.children.split(",");
         for(let i=0 ; i<searchedChildren.length ; i++){
            children.push(+searchedChildren[i]);
         }

         let ages=[];
         const searchedAges= parameters.ages.split(",");
         for(let i=0 ; i<searchedAges.length ; i++){
            ages.push(+searchedAges[i]);
         }

         let defaultSearchValues = {
            adults:adults,
            checkin:parameters.checkin,
            checkout:parameters.checkout,
            children:children,
            ages:ages,
            destinationCode:parameters.destinationCode,
            destinationText:parameters.destinationName,
            originCode:parameters.originCode,
            originText:parameters.originName                           
         }

         setDefaultValues(defaultSearchValues);

         const params = {
            "adults":adults,
            "children":children,
            "ages":ages,
            "checkIn":moment(parameters.checkin),            
            "checkOut":moment(parameters.checkout),
            "originCode":parameters.originCode,
            "destinationCode":parameters.destinationCode
         };

         axios.post(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.PackageAvailibility}`, params, {headers: {...Header,},})
         .then(({ data }) => {
            if (data.error) {
               debugger;
            }else{
               setPackageAvailabilityToken(data.result);
            }
         })
         .catch((error) => {
            debugger;
         });    
      }
   }

   const getAvailability = () => {
      if (packageAvailabilityToken){
         axios.get(`${ServerAddress.Type}${ServerAddress.Package}${PackageURL.PackageGetAvailability}?token=${packageAvailabilityToken}`,{headers: {...Header,}}).then(({data})=>{
            if (data.error) {
               debugger;
            }else{
               if(data.result.flight.length>0 && (flightResult.length === 0)){
                  setFlightResult(data.result.flight);
                  setSelectedFlight(data.result.flight[0]);
                  setFlightFilteredResult(data.result.flight);
                  setFlightLoading(false);
               }
               if(data.result.hotel.length>0 && (hotelResult.length === 0)){
                  setHotelResult(data.result.hotel);
                  setSelectedHotel(data.result.hotel[0]);
                  setHotelFilteredResult(data.result.hotel);
                  setHotelLoading(false);
               }
               if( (data.result.flight.length === 0) || (data.result.hotel.length===0)){
                  setTimeout(()=>{
                     setGetAvailabilityCounter(getAvailabilityCounter+1);
                  },1500);
                  
               }
            }
         });
      }else{

      }
   };

   const paginateFlights = (x,y)=>{
      let startIndex = x;
      let endIndex = startIndex + y;
      let newList = [...flightFilteredResult].slice(startIndex, endIndex);
      setFlightPagedResult(newList);
  }
 
   const paginateHotels = (x,y)=>{
      let startIndex = x;
      let endIndex = startIndex + y;
      let newList = [...hotelFilteredResult].slice(startIndex, endIndex);
      setHotelPagedResult(newList);
   }

   useEffect(()=>{
      executeScrollToSelectedPackage();
      setFlightsMobileVisibility(false);
      setHotelsMobileVisibility(false);
   },[selectedHotel,selectedFlight]);

   useEffect(()=>{

      setFlightLoading(true);
      setHotelLoading(true);
      setFlightResult([]);
      setHotelResult([]);
      setFlightFilteredResult([]);
      setHotelFilteredResult([]);
      setFlightPagedResult([]);
      setHotelPagedResult([]);
      setFlightCurrentPage(0);
      setHotelCurrentPage(0);   
      setSelectedFlight(undefined);
      setSelectedHotel(undefined); 

      getAvailabilityToken(); 

   },[props.location.search]);


   useEffect(()=>{
      getAvailability();
   },[packageAvailabilityToken,getAvailabilityCounter]);


   useEffect(()=>{
      paginateFlights(flightCurrentPage,10);
      executeScroll();
   },[flightCurrentPage]);

   useEffect(()=>{
      paginateFlights(0,10);
      executeScrollToSelectedPackage();
   },[flightFilteredResult]);

   useEffect(()=>{
      paginateHotels(hotelCurrentPage,10);
      executeScroll();
   },[hotelCurrentPage]);

   useEffect(()=>{      
      paginateHotels(0,10);
      executeScrollToSelectedPackage();
   },[hotelFilteredResult]);
   

   useEffect(()=>{
      let filteredResults = [...hotelResult].filter(hotelItem=>{   

         let itemFeatures=[];
         for(let i=0 ; i<hotelItem.features.length ; i++){
            itemFeatures.push(hotelItem.features[i].keyword);
         }

         let itemBoards=[];
         for(let i=0 ; i<hotelItem.boards.length ; i++){
            itemBoards.push(hotelItem.boards[i].name);
         }

         let itemRegions=[];
         for(let i=0 ; i<hotelItem.regions.length ; i++){
            itemRegions.push(hotelItem.regions[i].name);
         }

         if(
            (hotelItem.name.toLowerCase().includes(filterHotelName.toLowerCase()))
            &&
            (filterHotelRating.length === 0 || filterHotelRating.includes(hotelItem.rating))
            &&
            (filterHotelFeatures.length === 0 || filterHotelFeatures.every(item => itemFeatures.includes(item))) 
            &&
            (filterHotelRegion.length === 0 || filterHotelRegion.some(item => itemRegions.includes(item))) 
            &&
            (filterHotelBoard.length === 0 || filterHotelBoard.some(item => itemBoards.includes(item)))            
         ){
            return true;
         }else{
            return false;
         }
      });
      setHotelFilteredResult(filteredResults);
  },[filterHotelName,filterHotelRating,filterHotelFeatures,filterHotelRegion,filterHotelBoard]); 
  
   useEffect(()=>{
   let filteredResults = [...flightResult].filter(flightItem=>{   

      const flightRoutes = flightItem.airItinerary.originDestinationOptions;
      
      const flightDepartureTime = parseInt(moment(flightRoutes[0].flightSegments[0].departureDateTime).format("H")) ;
      const flightArrivalTime = parseInt(moment(flightRoutes[flightRoutes.length-1].flightSegments[flightRoutes[flightRoutes.length-1].flightSegments.length -1].arrivalDateTime).format("H")) ;

      let departureMinMax=[];
      if(filterFlightDepartureTime.length>0){
         for (let i=0 ; i<filterFlightDepartureTime.length ; i++){
            let min = parseInt(filterFlightDepartureTime[i].split("-")[0]);
            let max = parseInt(filterFlightDepartureTime[i].split("-")[1]);
            departureMinMax.push({min:min,max:max});
         }
      }
      
      let arrivalMinMax=[];
      if(filterFlightArrivalTime.length>0){
         for (let i=0 ; i<filterFlightArrivalTime.length ; i++){
            let min = parseInt(filterFlightArrivalTime[i].split("-")[0]);
            let max = parseInt(filterFlightArrivalTime[i].split("-")[1]);
            arrivalMinMax.push({min:min,max:max});
         }
      }  


      

      let routesStop=[];
      for(let i=0 ; i<flightRoutes.length ; i++){
         if(flightRoutes[i].numberOfStop>1){
            if(!(routesStop.includes(2))){
               routesStop.push(2);
            }
         }else{
            if(!(routesStop.includes(flightRoutes[i].numberOfStop)))
            routesStop.push(flightRoutes[i].numberOfStop);
         }
      }

      let routesCabinClass=[];
      for(let i=0 ; i<flightRoutes.length ; i++){
         const routeSegments =flightRoutes[i].flightSegments; 
         for(let j=0 ; j<routeSegments.length ; j++){
            if (!(routesCabinClass.includes(routeSegments[j].cabinClass.name))){
               routesCabinClass.push(routeSegments[j].cabinClass.name);
            }
         }
      }

      let routesAirline=[];
      for(let i=0 ; i<flightRoutes.length ; i++){
         const routeSegments =flightRoutes[i].flightSegments; 
         for(let j=0 ; j<routeSegments.length ; j++){
            if (!(routesAirline.includes(routeSegments[j].marketingAirline.name))){
               routesAirline.push(routeSegments[j].marketingAirline.name);
            }
         }
      }

      if(
         (filterFlightDepartureTime.length === 0 || departureMinMax.some(item => (flightDepartureTime < item.max ) && (flightDepartureTime > item.min) ) )
         &&
         (filterFlightArrivalTime.length === 0 || arrivalMinMax.some(item => (flightArrivalTime < item.max ) && (flightArrivalTime > item.min) ) )
         &&
         (filterFlightAirline.length === 0 || filterFlightAirline.some(item => routesAirline.includes(item))) 
         &&
         (filterFlightCabinClass.length === 0 || filterFlightCabinClass.some(item => item ===  routesCabinClass[0] && routesCabinClass.length === 1))            
         &&
         (filterFlightStop.length === 0 || filterFlightStop.some(item => item ===  routesStop[0] && routesStop.length === 1)) 
      ){
         return true;
      }else{
         return false;
      }
   });
   setFlightFilteredResult(filteredResults);
   },[filterFlightArrivalTime,filterFlightDepartureTime,filterFlightAirline,filterFlightCabinClass,filterFlightStop]); 



   const filterHandle = (type,value)=>{
      switch (type) {
         case "hotelName":
            setFilterHotelName(value);
            break;
         case "hotelRating":
            setFilterHotelRating(value)
            break;
         case "hotelFeatures":
            setFilterHotelFeatures(value)
            break;
         case "hotelRegion":
            setFilterHotelRegion(value)
            break;
         case "hotelBoard":
            setFilterHotelBoard(value)
            break;
         case "flightStop":
            setFilterFlightStop(value)
            break;
         case "flightCabinClass":
            setFilterFlightCabinClass(value)
            break;
         case "flightAirline":
            setFilterFlightAirline(value)
            break;
         case "flightDepartureTime":
            setFilterFlightDepartureTime(value)
            break;
         case "flightArrivalTime":
            setFilterFlightArrivalTime(value)
            break;
         default:
            console.log("its unknown filter");
      }
   }

   const closeFilters = () =>{
      setFlightFilterMobileVisibility(false);
      setHotelFilterMobileVisibility(false);
   }
   const { Dictionary } = props;
   
   const availsHolder = useRef();
   const selectedPackageHolder = useRef();
   const executeScroll = () => { if(availsHolder && availsHolder.current ){ availsHolder.current.scrollIntoView({behavior: "smooth"})}} ;
   const executeScrollToSelectedPackage = () => { if(selectedPackageHolder && selectedPackageHolder.current ){ selectedPackageHolder.current.scrollIntoView({behavior: "smooth"})}} ;
   

   return (
      <div className="package search-result-page">

      {(LayoutConfig.themeClassName === "padide-com-theme")  && (
            <div className="result-inline-search-section">
            <div className="page-container">
               <PackageFormSearchTOF inline={true} />
            </div>
            </div>
      )}

      <div className="page-container">
         {LayoutConfig.themeClassName === "padide-com-theme" || (
            <div className="search-form-wrapper">
               {defaultValues && <PackageSearchForm
                  inline={true}
                  defaultValues ={defaultValues}
               />
               }
            </div>
         )}

         {(flightLoading && hotelLoading) ? <Loading fullPage description={Dictionary.SearchingBestPrices+" ..."}/>        
         : 
            <>
            <div ref={selectedPackageHolder}>
            {(!flightLoading && !hotelLoading && (hotelResult.length === 0 || flightResult.length === 0)) ?
               "no result is available"
               : <PackageSelected 
               hotel={selectedHotel} 
               flight={selectedFlight} 
               onShowFlightsList={()=>{setFlightsMobileVisibility(true)}}
               onShowHotelsList={()=>{setHotelsMobileVisibility(true)}}
            />}
            </div>
            <div className="avails-row float-row package-avails-row padding-bottom padding-top" ref={availsHolder}>
               <div className="col-small-12 col-medium-9">
                     <div className="float-row">
                        <div className="col-small-12 col-medium-6">
                           <div className={`${hotelsMobileVisibility?`isOpen`:""} package-hotel-list`}>  
                              <div className="hidden-medium hidden-large">
                                 <button className="top-circle-close-btn" onClick={()=>{setHotelsMobileVisibility(false)}}><span className="itours-icon gray-close-icon" /></button>
                                 <button
                                       type="button"
                                       className="filter-responsive-btn"
                                       onClick={() => {setHotelFilterMobileVisibility(true)}}
                                 >
                                       {Dictionary.filters}
                                 </button>
                              </div>                              
                              {hotelLoading ? <div className="box-border bg-white card-padding"><Loading description={Dictionary.SearchingBestPrices+" ..."} /></div> :
                                 (hotelPagedResult.length > 0)?
                                 <div className="avails-holder" >
                                    {hotelPagedResult.map(item=><PackageHotelItem model={item} key={item.id} onSelectHotel={()=>setSelectedHotel(item)} isSelected={selectedHotel.id === item.id} showTag />)}
                                 {hotelFilteredResult.length > 10 && (
                                 <Page
                                    length={hotelFilteredResult.length}
                                    itemCurrent={hotelCurrentPage}
                                    itemPerPage={10}
                                    onChangeValue={(e)=>{setHotelCurrentPage(e)}}
                                 />
                                 )}
                              </div>
                              :
                              <div className="section-vertical-padding text-center">
                                 <div>
                                 <span className="itours-icon icon-xlarge error-icon" />
                                 </div>
                                 <h5 className="normal-title">
                                 {Dictionary.NoHotelsFoundForYourRequest}.
                                 <div className="page-subtitle">
                                    {Dictionary.pleaseTryAgain}.
                                 </div>
                                 </h5>
                              </div>
                              }
                           </div>
                        </div>
                        <div className="col-small-12 col-medium-6">
                           <div className={`${flightsMobileVisibility?`isOpen`:""} package-flight-list`}>  
                              <div className="hidden-medium hidden-large">
                                 <button className="top-circle-close-btn" onClick={()=>{setFlightsMobileVisibility(false)}}><span className="itours-icon gray-close-icon" /></button>
                                 <button
                                       type="button"
                                       className="filter-responsive-btn"
                                       onClick={() => {setFlightFilterMobileVisibility(true)}}
                                 >
                                       {Dictionary.filters}
                                 </button>
                              </div>
                              {flightLoading ? <div className="box-border bg-white card-padding"><Loading description={Dictionary.SearchingBestPrices+" ..."} /></div> :
                                 (flightPagedResult.length > 0)?
                                 <div className="avails-holder">
                                    {flightPagedResult.map(item=><PackageFlightItem model={item} key={item.key} onSelectFlight={()=>{setSelectedFlight(item)}} isSelected={selectedFlight.key === item.key} showTag/>)}

                                 {flightFilteredResult.length > 10 && (
                                 <Page
                                    length={flightFilteredResult.length}
                                    itemCurrent={flightCurrentPage}
                                    itemPerPage={10}
                                    onChangeValue={(e)=>{setFlightCurrentPage(e)}}
                                 />
                                 )} 
                              </div>
                              :
                              <div className="section-vertical-padding text-center">
                                    <div>
                                    <span className="itours-icon icon-xlarge error-icon" />
                                    </div>
                                    <h5 className="normal-title">
                                    {Dictionary.noFlightFoundForYourRequest}.
                                    <div className="page-subtitle">
                                       {Dictionary.pleaseTryAgain}.
                                    </div>
                                    </h5>
                              </div>
                              }  
                           </div>                              
                        </div>
                     </div>
               </div>
               <div className="col-small-12 col-medium-3">
                     <div className={`${flightFilterMobileVisibility?"showfilter only-flight-filter" : hotelFilterMobileVisibility? "showfilter only-hotel-filter":""} filters-holder`}>
                        <FilterForm filterHandle={filterHandle} closeFilters={closeFilters} hotels={hotelResult} flights={flightResult} />
                     </div>
               </div>
            </div>
            </>
         }
      </div>
   </div>
   );

}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});

export default withRouter(connect(mapStateToProps)(PackageResult));

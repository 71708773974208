import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { GetReserveById } from "../../Actions/PaymentAction";
import { gatewayReport } from "../../Actions/PaymentAction";
import { setFlightSelected } from "../../Actions/FlightAction";
import { selectedHotel } from "../../Actions/HotelAction";
import { Payment } from "../../Enum/Models";
import Price from "../../components/Common/Price";
import Loading from "../../components/Common/Loading";
import { editMessage } from "../../Actions/VisualDataAction";
import { Alert } from "antd";
import FlighSummary from "../../components/Flight/FlighSummary";
import SummaryHotel from "../../components/Accomodation/SummaryHotel";
import PackageSummary from "../../components/Package/PackageSummary";
import LayoutConfig from "../../Config/layoutConfig";

class ReserveDetails extends React.Component {
  state = {
    farePrice: null,
    bookingError: false,
    reserveID: null,
  };
  getInfromation = () => {
    this.props.setFlightSelected(null);
    this.props.selectedHotel(null);
    let parameters = queryString.parse(this.props.location.search);
    if (parameters.hasError === "true") {
      this.setState({
        bookingError: true,
      });
    }
    let params = new URLSearchParams();
    params.append("reserveId", parameters.reserveId);
    params.append("userName", parameters.userName);
    this.props.GetReserveById(params);
    // this.props.gatewayReport({ ...parameters });
    this.setState({
      reserveID: parameters.reserveId,
    });
  };
  componentDidMount() {
    this.props.editMessage(false);
    window.scrollTo(0, 0);
    this.getInfromation();
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.flightInfo !== null && this.props.flightInfo !== prevProps.flightInfo
    ) {
      let perPrice = [];
      let flightInfoCurrency = this.props.flightInfo.currency;
      this.props.flightInfo.passengersFare.forEach((item) => {
        perPrice[item.passengerTypeQuantity.code] = {
          totalTaxes: item.fare.totalTaxes,
          totalFare: item.fare.totalFare,
          baseFare: item.fare.baseFare,
        };
      });
      let passengerInfo = this.props.flightInfo.passengers;
      passengerInfo.forEach((itemPass) => {
        itemPass["baseFare"] = perPrice[itemPass.code].baseFare;
        itemPass["totalTaxes"] = perPrice[itemPass.code].totalTaxes;
        itemPass["totalFare"] = perPrice[itemPass.code].totalFare;
        itemPass["currency"] = flightInfoCurrency;
      });
      this.setState({
        farePrice: passengerInfo,
      });
    }

    if (
      this.props.packageInfo !== null && this.props.packageInfo !== prevProps.packageInfo
    ) {
      let perPrice = [];
      let packageInfoCurrency = this.props.packageInfo.currency;
      this.props.packageInfo.reserveFlightInfo.passengersFare.forEach((item) => {
        perPrice[item.passengerTypeQuantity.code] = {
          totalTaxes: item.fare.totalTaxes,
          totalFare: item.fare.totalFare,
          baseFare: item.fare.baseFare,
        };
      });
      let passengerInfo = this.props.packageInfo.reserveFlightInfo.passengers;
      passengerInfo.forEach((itemPass) => {
        itemPass["baseFare"] = perPrice[itemPass.code].baseFare;
        itemPass["totalTaxes"] = perPrice[itemPass.code].totalTaxes;
        itemPass["totalFare"] = perPrice[itemPass.code].totalFare;
        itemPass["currency"] = packageInfoCurrency;
      });
      this.setState({
        farePrice: passengerInfo,
      });
    }

  }

  phoneItem = (value)=>{
    if (LayoutConfig.themeClassName === 'naminTravel-theme'){
      return (
        <a href={`https://wa.me/${value.replace(/ +/g, "").replace(/[{(+)}]/g, '').replace(/-/g , "")}`} className="pull-end" dir="ltr" rel="noopener noreferrer" target="_blank" >
        <span className="margin-end-half itours-icon whatsapp icon-small" />
          <span dir="ltr">{value} ({this.props.Dictionary.WhatsAppOnly})</span>
        </a>
      );
    }
    return (
      <span className="pull-end" dir="ltr" >
        <span className="margin-right-light itours-icon red-phon-icon icon-small" />
        <span dir="ltr">{value} </span>
      </span>
    )
  };

  render() {
    // let checkIn=null;
    // let checkOut=null;
    const { Dictionary } = this.props;
    let night = 0;
    let totalPrice = 0;
    const { respond, flightInfo, voucherDetails,packageInfo } = this.props;
    if (voucherDetails != null) {
      night = voucherDetails.date.duration;
      // let cashDateIN = new Date(voucherDetails.date.checkIn);
      // checkIn = cashDateIN.getFullYear() + "/" + (cashDateIN.getMonth() + 1) + "/" + cashDateIN.getDate();
      // let cashDateOUT = new Date(voucherDetails.date.checkOut);
      // checkOut = cashDateOUT.getFullYear() + "/" + (cashDateOUT.getMonth() + 1) + "/" + cashDateOUT.getDate();
      if (voucherDetails.accommodation) {
        voucherDetails.accommodation.rooms.forEach((item) => {
          totalPrice += parseFloat(item.salePrice);
        });
      }
    }
    let paymentArray = [];
    if (this.props.paymentInformation) {
      for (let objIndex in this.props.paymentInformation) {
        paymentArray.push(this.props.paymentInformation[objIndex]);
      }
    }
    return !respond ? (
      <Loading fullPage description={Dictionary.loadingReservationInformation+" ..."}/>
      // <div className="loading-outer-container">
      //   <div>
      //     <img
      //       src={LayoutConfig.logo ? LayoutConfig.logo : siteLogo}
      //       className="loading-logo"
      //       alt={LayoutConfig.companyName ? LayoutConfig.companyName : "itours"}
      //     />
      //     <div className="loading-inner-container">
      //       <div className="item-1"></div>
      //       <div className="item-2"></div>
      //       <div className="item-3"></div>
      //       <div className="item-4"></div>
      //       <div className="item-5"></div>
      //     </div>
      //     <div className="text-center">
      //       {Dictionary.loadingReservationInformation}
      //     </div>
      //   </div>
      // </div>
      
    ) : (
      <div className="section-vertical-padding">
        <div className="page-container ">
          <h2 className="page-title">{Dictionary.reservationDetails}</h2>
          {this.props.flightError && this.props.flightError === "2" && (
            <Alert
              message={Dictionary.error}
              description={Dictionary.SoryThereIsAProblemInTheReservationCallUs}
              type="error"
              showIcon
            />
          )}
          <div className="sides-outer-holder">
            <div className="sides-holder reverse">
              <div className="small-side flightdetails">
                <div className="side-item full-width">
                  <div className="side-item-inner">
                    {flightInfo && (
                      <FlighSummary
                        showFlightAllDetails
                        model={
                          this.props.flightInfo.airItinerary
                            .originDestinationOptions
                        }
                        itinTotalFare ={this.props.flightInfo.flightFare}
                        fareInfo={this.props.flightInfo.passengersFare}
                        totalPrice={this.props.flightInfo.flightFare.totalFare}
                        currencyCode={this.props.flightInfo.currency}
                        showButton={false}
                        feild={"fare"}
                      />
                    )}
                    {voucherDetails && (
                      <SummaryHotel
                        model={voucherDetails}
                        currencyCode={this.props.voucherDetails.currency.code}
                        showButton={false}
                        totalPrice={totalPrice}
                        night={night}
                      />
                    )}

                    {packageInfo && (
                      <PackageSummary
                      currencyCode={packageInfo.currency}
                      flightModel={packageInfo.reserveFlightInfo.airItinerary.originDestinationOptions}
                      hotelModel={packageInfo.reserveHotelInfo}
                      night={packageInfo.reserveHotelInfo.date.duration}
                      cancellationPolicy={packageInfo.reserveHotelInfo.cancellationPolicy}
                      flightPrice={packageInfo.reserveFlightInfo.flightFare.totalFare}                              
                      />
                    )}

                    {flightInfo && flightInfo.reserveStatus === Payment.Pending && <div className="box-border top-color-border orange margin-top bg-white">
                      <div className="colored">
                        {Dictionary.pricesToRiseSoon}!
                      </div>
                      <div className="font-12">
                        {Dictionary.bookNowToGetTheBestRateForYourCheckinDate}.
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="large-side">
                <div className="side-item full-width">
                  <div className="side-item-inner">
                    {flightInfo && (
                      <>
                        {this.state.bookingError === true && (
                          <div
                            className={
                              "bg-white box-border card-padding Reserver information margin-bottom"
                            }
                          >
                            <div className="clearfix">
                              <div className="pull-start">
                                <h3 className="normal-title">
                                  <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                  <span>
                                    {
                                      Dictionary.SoryThereIsAProblemInTheReservationCallUs
                                    }
                                  </span>
                                </h3>
                              </div>
                            </div>
                          </div>
                        )}

                        <div
                          className={
                            "bg-white box-border card-padding Reserver information margin-bottom"
                          }
                        >
                          <div className="clearfix">
                            <div className="pull-start">
                              <h3 className="normal-title">
                                {flightInfo.reserveStatus === Payment.Pending && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
                                    <span>{Dictionary.paymentPending}</span>
                                  </div>
                                )}
                                {flightInfo.reserveStatus === Payment.Canceled && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.canceled}</span>
                                  </div>
                                )}
                                {flightInfo.reserveStatus === Payment.Failed && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.Failed}</span>
                                  </div>
                                )}
                                {flightInfo.reserveStatus ===
                                  Payment.Issued && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                                    <span>{Dictionary.Issued}</span>
                                  </div>
                                )}
                                {(
                                  flightInfo.reserveStatus === Payment.PaymentSuccessful
                                  ||
                                  flightInfo.reserveStatus === Payment.UnConfirmed
                                  ) 
                                  && (<div>
                                    <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                                    <span>{Dictionary.successfulPayment}</span>
                                  </div>
                                )}
                                {/*{voucherDetails.status===Payment.PaymentSuccessful && <div>*/}
                                {/*    <span className="itours-icon icon-xlarge peyment-exchange margin-end-half" />*/}
                                {/*    <span>*/}
                                {/*        {Dictionary.Processing}*/}
                                {/*    </span>*/}
                                {/*</div>}*/}
                                {flightInfo.reserveStatus ===
                                  Payment.Refunded && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.Refunded}</span>
                                  </div>
                                )}
                                {flightInfo.reserveStatus ===
                                  Payment.WebServiceUnsuccessful && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>
                                      {Dictionary.WebServiceUnsuccessful}
                                    </span>
                                  </div>
                                )}
                              </h3>
                              {/*<div className="margin-bottom">*/}
                              {/*        <span className="inline-block">*/}
                              {/*        {Dictionary.trackingCode}*/}
                              {/*            :*/}
                              {/*        </span>*/}
                              {/*    <span className="inline-block margin-end"> {flightInfo.reserveId} </span>*/}
                              {/*</div>*/}
                            </div>
                            {(flightInfo.reserveStatus ==="Issued") &&
                            <a
                              href={
                                LayoutConfig.themeClassName === 'naminTravel-theme'?
                                `https://voucher.namintravel.com/en/flight/Ticket2?reserveid=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                                : (this.props.LAN ==="IR")?
                                `https://voucher.itours.ir/en/flight/irTicket?reserveid=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                                :
                                `https://voucher.imt.as/en/flight/Ticket?reserveid=${this.state.reserveID}&username=${flightInfo.reserver.username}`
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                              download
                              className="button color-1-btn pull-end"
                            >
                              {Dictionary.ticket}
                            </a>
                            }
                          </div>

                          {(flightInfo.reserveStatus === Payment.PaymentSuccessful) &&
                            <p>{Dictionary.YourPaymentWasSuccessfulAndYourTicketWillBeSentToYourEmailOnceItIsIssued}.</p>
                          }
                          <h5 className="normal-title">
                            {Dictionary.ReserverInformartion}
                          </h5>
                          <div
                            className="float-row"
                            style={{
                              border: "1px solid gray",
                              backgroundColor: "#e9e9e9",
                            }}
                          >
                            <div className="col-small-12 col-medium-6">
                              <b>{Dictionary.trackingNumber}</b>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <b>{this.state.reserveID}</b>
                            </div>
                          </div>
                          <div>
                            <div>
                              <span className="item-label">
                                {Dictionary.gender}:{" "}
                              </span>{" "}
                              {flightInfo.reserver.gender
                                ? Dictionary.male
                                : Dictionary.female}
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.firstName}:{" "}
                              </span>{" "}
                              {flightInfo.reserver.firstName}
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.lastName}:{" "}
                              </span>{" "}
                              {flightInfo.reserver.lastName}
                            </div>
                            <div
                              className="margin-bottom padding-h-10"
                              style={{
                                border: "1px solid gray",
                                margin: "0 -7px 6px",
                                backgroundColor: "#e9e9e9",
                              }}
                            >
                              <b>
                                {
                                  Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail
                                }
                              </b>
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.email}:{" "}
                              </span>{" "}
                              {flightInfo.reserver.email}
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.phoneNumber}:{" "}
                              </span>{" "}
                              <span dir="ltr">
                                {flightInfo.reserver.phoneNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={"passengerInformation margin-bottom"}>
                          <h5 className="normal-title">{Dictionary.price}</h5>
                          <div className="itours-responsive-table medium-table bg-white">
                            <div className="table-row-group">
                              <div className="table-row table-head">
                                <div>{Dictionary.row}</div>
                                <div>{Dictionary.firstName}</div>
                                <div>{Dictionary.lastName}</div>
                                {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?
                                <>
                                  <div>{Dictionary.wheelchair}</div>
                                  <div>{Dictionary.specialDiet}</div>
                                </>
                                :null}
                                <div>{Dictionary.baseFare}</div>
                                <div>{Dictionary.taxes}</div>
                                <div>{Dictionary.fare}</div>
                              </div>
                              {this.state.farePrice &&
                                this.state.farePrice.map(
                                  (itemPass, indexPass) => {
                                    return (
                                      <div
                                        className="table-row"
                                        key={indexPass}
                                      >
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.row}
                                          </span>
                                          <span> {indexPass + 1}</span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.firstName}
                                          </span>
                                          <span>{itemPass.firstName}</span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.lastName}
                                          </span>
                                          <span>{itemPass.lastName} </span>
                                        </div>


                                        {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?
                                          <>
                                          <div>
                                            <span className="responsive-label">
                                              {Dictionary.wheelchair}
                                            </span>
                                            <span>{itemPass.hasWheelChair?<span className="itours-icon green-checkmark icon-small" />:"-----"}</span>
                                          </div>
                                          <div>
                                            <span className="responsive-label">
                                              {Dictionary.specialDiet}
                                            </span>
                                            <span>
                                              {itemPass.mealType?
                                                itemPass.mealType
                                                :"-----"}
                                              </span>
                                          </div>
                                          </>
                                          :null
                                        }
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.baseFare}
                                          </span>
                                          <span className="nowrap">
                                            <Price currency={itemPass.currency}>
                                              {itemPass.baseFare}
                                            </Price>{" "}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.taxes}
                                          </span>
                                          <span className="nowrap">
                                            <Price currency={itemPass.currency}>
                                              {itemPass.totalTaxes}
                                            </Price>{" "}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.fare}
                                          </span>
                                          <span className="nowrap">
                                            <Price currency={itemPass.currency}>
                                              {itemPass.totalFare}
                                            </Price>
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              <div className="table-row total-row">
                                <div>
                                  <b className="no-wrap">{Dictionary.baseFare}</b>
                                </div>
                                <div />
                                <div />
                                {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?<><div /><div /></>:null}
                                <div />
                                <div />
                                <div>
                                  <b className="no-wrap" >
                                    <Price currency={flightInfo.currency}>
                                      {flightInfo.flightFare.baseFare}
                                    </Price>
                                  </b>
                                </div>
                              </div>
                              <div className="table-row total-row">
                                <div>
                                  <b className="no-wrap">{Dictionary.totalTaxes}</b>
                                </div>
                                <div />
                                <div />
                                {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?<><div /><div /></>:null}
                                <div />
                                <div />
                                <div>
                                  <b className="no-wrap">
                                    <Price currency={flightInfo.currency}>
                                      {flightInfo.flightFare.totalTaxes}
                                    </Price>
                                  </b>
                                </div>
                              </div>
                              <div className="table-row total-row">
                                <div>
                                  <b className="purple no-wrap">
                                    {Dictionary.totalFare}
                                  </b>
                                </div>
                                <div />
                                <div />
                                {process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?<><div /><div /></>:null}
                                <div />
                                <div />
                                <div>
                                  <b className="purple no-wrap">
                                    <Price currency={flightInfo.currency}>
                                      {flightInfo.flightFare.totalFare}
                                    </Price>
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                    {voucherDetails && (
                      <>
                        <div className="card-padding bg-white box-border margin-bottom">
                          <div className="clearfix margin-bottom-half">
                            <div className="pull-start">
                              <h3 className="normal-title">
                                {voucherDetails.status === Payment.Pending && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
                                    <span>{Dictionary.paymentPending}</span>
                                  </div>
                                )}
                                {voucherDetails.status === Payment.Canceled && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.canceled}</span>
                                  </div>
                                )}
                                {voucherDetails.status === Payment.Failed && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.Failed}</span>
                                  </div>
                                )}
                                {voucherDetails.status === Payment.Issued && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                                    <span>{Dictionary.Issued}</span>
                                  </div>
                                )}
                                {voucherDetails.status ===
                                  Payment.PaymentSuccessful && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                                    <span>{Dictionary.Issued}</span>
                                  </div>
                                )}
                                {/*{voucherDetails.status===Payment.PaymentSuccessful && <div>*/}
                                {/*    <span className="itours-icon icon-xlarge peyment-exchange margin-end-half" />*/}
                                {/*    <span>*/}
                                {/*        {Dictionary.Processing}*/}
                                {/*    </span>*/}
                                {/*</div>}*/}
                                {voucherDetails.status === Payment.Refunded && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.Refunded}</span>
                                  </div>
                                )}
                                {voucherDetails.status ===
                                  Payment.WebServiceUnsuccessful && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>
                                      {Dictionary.WebServiceUnsuccessful}
                                    </span>
                                  </div>
                                )}
                              </h3>
                              <div className="margin-bottom">
                                <span className="inline-block">
                                  {Dictionary.trackingCode}:
                                </span>
                                <span className="inline-block margin-end">
                                  {" "}
                                  {voucherDetails.reserveId}{" "}
                                </span>
                              </div>
                            </div>

                            
                            {voucherDetails.status ==="Issued" && <a
                              href={
                                LayoutConfig.themeClassName === 'naminTravel-theme'?
                                `https://voucher.namintravel.com/en/flight/Voucher2?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver.username}`
                                :
                                `http://voucher.imt.as/en/Hotel/Voucher?reserveId=${voucherDetails.reserveId}&username=${voucherDetails.reserver.username}`}
                              download
                              target="_blank"
                              rel="noopener noreferrer"
                              className="button color-1-btn pull-end"
                            >
                              {Dictionary.GetVoucher}
                            </a>}
                          </div>
                          <h3 className="normal-title">
                            {Dictionary.ReserverInformartion}
                          </h3>
                          <div>
                            <div className="margin-bottom-light">
                              <b className="inline-block margin-end-light">
                                {Dictionary.fullName}:
                              </b>
                              <span className="inline-block">
                                {" "}
                                {voucherDetails.reserver.firstName}{" "}
                                {voucherDetails.reserver.lastName}
                              </span>
                            </div>
                            <div className="margin-bottom-light">
                              <b className="inline-block margin-end-light">
                                {Dictionary.email}:
                              </b>
                              <span className="en-font inline-block" dir="ltr">
                                {" "}
                                {voucherDetails.reserver.username}
                              </span>
                            </div>
                            <div>
                              <b className="inline-block margin-end-light">
                                {Dictionary.mobileNumber}:
                              </b>
                              <span className="inline-block" dir="ltr">
                                {" "}
                                {voucherDetails.reserver.phoneNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                        {/*reserver information*/}

                        {voucherDetails.accommodation.rooms[0].remarks && (
                          <div className=" margin-bottom bg-white box-border card-padding">
                            <h5 className="normal-title">
                              {Dictionary.remarks}:
                            </h5>
                            <div className="justified en-font" dir="ltr" dangerouslySetInnerHTML={{__html:voucherDetails.accommodation.rooms[0].remarks}} />
                          </div>
                        )}
                      </>
                    )}
                    {packageInfo && (
                      <>
                        <div
                          className={
                            "bg-white box-border card-padding Reserver information margin-bottom"
                          }
                        >
                          <div className="clearfix">
                            <div className="pull-start">
                              <h3 className="normal-title">
                                {packageInfo.reserveFlightInfo.reserveStatus === Payment.Pending && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-warning margin-end-half" />
                                    <span>{Dictionary.paymentPending}</span>
                                  </div>
                                )}
                                {packageInfo.reserveFlightInfo.reserveStatus === Payment.Canceled && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.canceled}</span>
                                  </div>
                                )}
                                {packageInfo.reserveFlightInfo.reserveStatus === Payment.Failed && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.Failed}</span>
                                  </div>
                                )}
                                {packageInfo.reserveFlightInfo.reserveStatus === Payment.Issued && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                                    <span>{Dictionary.Issued}</span>
                                  </div>
                                )}
                                {packageInfo.reserveFlightInfo.reserveStatus === Payment.PaymentSuccessful && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-ok margin-end-half" />
                                    <span>{Dictionary.Issued}</span>
                                  </div>
                                )}
                                {/*{voucherDetails.status===Payment.PaymentSuccessful && <div>*/}
                                {/*    <span className="itours-icon icon-xlarge peyment-exchange margin-end-half" />*/}
                                {/*    <span>*/}
                                {/*        {Dictionary.Processing}*/}
                                {/*    </span>*/}
                                {/*</div>}*/}
                                {packageInfo.reserveFlightInfo.reserveStatus === Payment.Refunded && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>{Dictionary.Refunded}</span>
                                  </div>
                                )}
                                {packageInfo.reserveFlightInfo.reserveStatus === Payment.WebServiceUnsuccessful && (
                                  <div>
                                    <span className="itours-icon icon-xlarge peyment-error margin-end-half" />
                                    <span>
                                      {Dictionary.WebServiceUnsuccessful}
                                    </span>
                                  </div>
                                )}
                              </h3>
                              {/*<div className="margin-bottom">*/}
                              {/*        <span className="inline-block">*/}
                              {/*        {Dictionary.trackingCode}*/}
                              {/*            :*/}
                              {/*        </span>*/}
                              {/*    <span className="inline-block margin-end"> {flightInfo.reserveId} </span>*/}
                              {/*</div>*/}
                            </div>
                          </div>
                          <h5 className="normal-title">
                            {Dictionary.ReserverInformartion}
                          </h5>
                          <div
                            className="float-row"
                            style={{
                              border: "1px solid gray",
                              backgroundColor: "#e9e9e9",
                            }}
                          >
                            <div className="col-small-12 col-medium-6">
                              <b>{Dictionary.trackingNumber}</b>
                            </div>
                            <div className="col-small-12 col-medium-6">
                              <b>{this.state.reserveID}</b>
                            </div>
                          </div>
                          <div>
                            <div>
                              <span className="item-label">
                                {Dictionary.gender}:{" "}
                              </span>{" "}
                              {packageInfo.reserveFlightInfo.reserver.gender
                                ? Dictionary.male
                                : Dictionary.female}
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.firstName}:{" "}
                              </span>{" "}
                              {packageInfo.reserveFlightInfo.reserver.firstName}
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.lastName}:{" "}
                              </span>{" "}
                              {packageInfo.reserveFlightInfo.reserver.lastName}
                            </div>
                            <div
                              className="margin-bottom"
                              style={{
                                border: "1px solid gray",
                                marginBottom: "6px",
                                backgroundColor: "#e9e9e9",
                              }}
                            >
                              <b>{Dictionary.BookingInformationsWillbeSentToThisNumberOrEmail}</b>
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.email}:{" "}
                              </span>{" "}
                              {packageInfo.reserveFlightInfo.reserver.email}
                            </div>
                            <div>
                              <span className="item-label">
                                {Dictionary.phoneNumber}:{" "}
                              </span>{" "}
                              <span dir="ltr">
                                {packageInfo.reserveFlightInfo.reserver.phoneNumber}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className={"passengerInformation margin-bottom"}>
                          <h5 className="normal-title">{Dictionary.price}</h5>
                          <div className="itours-responsive-table medium-table bg-white">
                            <div className="table-row-group">
                              <div className="table-row table-head">
                                <div>{Dictionary.row}</div>
                                <div>{Dictionary.firstName}</div>
                                <div>{Dictionary.lastName}</div>
                                <div>{Dictionary.baseFare}</div>
                                <div>{Dictionary.taxes}</div>
                                <div>{Dictionary.fare}</div>
                              </div>
                              {this.state.farePrice &&
                                this.state.farePrice.map(
                                  (itemPass, indexPass) => {
                                    return (
                                      <div
                                        className="table-row"
                                        key={indexPass}
                                      >
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.row}
                                          </span>
                                          <span> {indexPass + 1}</span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.firstName}
                                          </span>
                                          <span>{itemPass.firstName}</span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.lastName}
                                          </span>
                                          <span>{itemPass.lastName} </span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.baseFare}
                                          </span>
                                          <span className="nowrap">
                                            <Price currency={itemPass.currency}>
                                              {itemPass.baseFare}
                                            </Price>{" "}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.taxes}
                                          </span>
                                          <span className="nowrap">
                                            <Price currency={itemPass.currency}>
                                              {itemPass.totalTaxes}
                                            </Price>{" "}
                                          </span>
                                        </div>
                                        <div>
                                          <span className="responsive-label">
                                            {Dictionary.fare}
                                          </span>
                                          <span className="nowrap">
                                            <Price currency={itemPass.currency}>
                                              {itemPass.totalFare}
                                            </Price>
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                              <div className="table-row total-row">
                                <div>
                                  <b>{Dictionary.baseFare}</b>
                                </div>
                                <div />
                                <div />
                                <div />
                                <div />
                                <div>
                                  {" "}
                                  <b>
                                    {" "}
                                    <Price currency={packageInfo.currency}>
                                      {packageInfo.reserveFlightInfo.flightFare.baseFare}
                                    </Price>{" "}
                                  </b>{" "}
                                </div>
                              </div>
                              <div className="table-row total-row">
                                <div>
                                  <b>{Dictionary.totalTaxes}</b>
                                </div>
                                <div />
                                <div />
                                <div />
                                <div />
                                <div>
                                  {" "}
                                  <b>
                                    {" "}
                                    <Price currency={packageInfo.currency}>
                                      {packageInfo.reserveFlightInfo.flightFare.totalTaxes}
                                    </Price>{" "}
                                  </b>{" "}
                                </div>
                              </div>
                              <div className="table-row total-row">
                                <div>
                                  <b className="purple">
                                    {Dictionary.totalFare}
                                  </b>
                                </div>
                                <div />
                                <div />
                                <div />
                                <div />
                                <div>
                                  <b className="purple">
                                    <Price currency={packageInfo.currency}>
                                      {packageInfo.reserveFlightInfo.flightFare.totalFare}
                                    </Price>
                                  </b>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    )}

                    {
                      paymentArray && paymentArray.length > 0 ? (
                        <div>
                          <h3>{Dictionary.peymentDetails}</h3>
                          <div className="itours-responsive-table large-table text-center">
                            <div className="table-row-group">
                              <div className="table-row table-head">
                                <div>{Dictionary.row}</div>
                                <div>{Dictionary.port} </div>
                                <div>{Dictionary.trackingNumber} </div>
                                <div>{Dictionary.paymentDate} </div>
                                <div>{Dictionary.paymentTime} </div>
                                <div>{Dictionary.missionary} </div>
                                <div>{Dictionary.status} </div>
                              </div>
                              {paymentArray &&
                                paymentArray.map((item, index) => {
                                  return (
                                    <div
                                      className="table-row bg-white"
                                      key={index}
                                    >
                                      <div>
                                        <span className="responsive-label">
                                          {Dictionary.row}
                                        </span>
                                        <span>{index + 1}</span>
                                      </div>
                                      <div>
                                        <span className="responsive-label">
                                          {Dictionary.port}
                                        </span>
                                        <span>{item.gatewayName}</span>
                                      </div>
                                      <div>
                                        <span className="responsive-label">
                                          {Dictionary.trackingNumber}{" "}
                                        </span>
                                        <span>{item.id}</span>
                                      </div>
                                      <div>
                                        <span className="responsive-label">
                                          {Dictionary.paymentDate}{" "}
                                        </span>
                                        <span className="nowrap">
                                          {item.transferDateTime.split("T")[0]}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="responsive-label">
                                          {Dictionary.paymentTime}
                                        </span>
                                        <span className="nowrap">
                                          {item.transferDateTime.split("T")[1]}
                                        </span>
                                      </div>
                                      <div>
                                        <span className="responsive-label">
                                          {Dictionary.missionary}
                                        </span>
                                        <span dir="ltr" className="nowrap">
                                          <Price
                                            currency={this.props.currencyCode}
                                          >
                                            {item.amount}
                                          </Price>
                                        </span>
                                      </div>
                                      <div>
                                        <span className="responsive-label">
                                          {Dictionary.status}
                                        </span>
                                        <span>
                                          {item.isConfirmed
                                            ? Dictionary.paid
                                            : Dictionary.unpaid}
                                        </span>
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      ) : null
                      // <div>{Dictionary.paymentDataIsLoading}</div>
                    }
                    <div className="card-padding bg-white box-border clearfix">
                      <span className="inline-block">
                        {
                          process.env.REACT_APP_THEME ==='NAMINTRAVELCOM'?
                          Dictionary.ForQuestionsRegardingThisReservationPleaseContactNaminTravelDirectly
                          :Dictionary.ForQuestionsRegardingThisReservationPleaseContactItoursDirectly
                        }
                        :
                      </span>
                      {LayoutConfig.contacts &&
                      LayoutConfig.contacts.telephone &&
                      LayoutConfig.contacts.telephone.length > 0
                        ? this.phoneItem (LayoutConfig.contacts.telephone[0])
                        : this.phoneItem(Dictionary.supportNumber)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
//Map
const mapStateToProps = (state) => ({
  voucherDetails: state.HotelSearch.selectedHotel,
  currencyCode: state.UserData.Currency,
  paymentInformation: state.PaymentData.voucherDetailsPayment,
  flightInfo: state.FlightSearch.flightSelected,
  respond: state.VisualData.Responded,
  flightError: state.FlightSearch.flighterror,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  packageInfo: state.PackageSearch.reservedPackageData
});
const mapDispatchToProps = (dispatch) => ({
  GetReserveById: (param) => {
    dispatch(GetReserveById(param));
  },
  gatewayReport: (param) => {
    dispatch(gatewayReport(param));
  },
  editMessage: (params) => {
    dispatch(editMessage(params));
  },
  setFlightSelected: (params) => {
    dispatch(setFlightSelected(params));
  },
  selectedHotel: (params) => {
    dispatch(selectedHotel(params));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(ReserveDetails);

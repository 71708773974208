import {FilterName, FilterType, Hotel} from '../Enum/Models';
import _ from "lodash";
export const HotelSearch=(state=new Hotel(),action)=>{
    switch (action.type) {
        case "HotelSearch":
            return {
                ...state,
                city:action.result.city,
                country:action.result.country,
                date:action.result.date,
                hotels:_.sortBy(action.result.hotels,"salePrice"),
                hotelsBackup:_.sortBy(action.result.hotels,"salePrice")
            }
        case "HotelDetails":
            let hoteldetails=action.result;
            if(hoteldetails){
                if(hoteldetails.segments!==undefined){
                    hoteldetails.segments.forEach((item)=>{
                        item.rooms.forEach((itemIN)=>{
                            itemIN["cancelationInfo"]=null
                        })
                    })
                }
            }
            return {
                ...state,
                selectedHotel:hoteldetails
            };
        case "HotelReserve":
            return {
                ...state,
                selectedHotel:action.accommodation
            }
        case "SaveParameter":
            return{
                ...state,
                parameters:{...action.params}
            }
        case "SetReserveID":{
            return{
                ...state,
                reserveId:action.reserveId
            }
        }
        case "HotelFilterAsync":
            return {
                ...state,
                filterShow:action.result
            }
        case "FilterSelect":
            let filterSelect=state.filterSelect;
            if(action.input.isAdd){
                if(action.input.type===FilterName.name){
                    filterSelect[action.input.type].value=action.input.textInput;
                }else{
                    filterSelect[action.input.type].value.push(JSON.parse(action.input.value));
                }
            }else{
                filterSelect[action.input.type].value=filterSelect[action.input.type].value.filter((item)=>{
                    let value=(typeof action.input.value==="object")?JSON.stringify(action.input.value) : action.input.value.toString();
                    return JSON.stringify(item)!==value
                })
            }
            return {
                ...state,
                filterSelect:filterSelect
            }
        case "ApplyFilter":
            const filtering=(item)=>{
                let status=true;
                for(let filterName in state.filterSelect){
                    let filterItem=state.filterSelect[filterName].value;
                   if(filterItem!==null && filterItem.length){
                       //convert number value to array
                       let valueFilterName=(typeof item[filterName]==="number")?[item[filterName]]:item[filterName];
                       if(state.filterSelect[filterName].type===FilterType.Like){
                           if(valueFilterName.toLowerCase().indexOf(filterItem.toLowerCase())===-1){
                               status=false;
                           }
                       }else{
                           let differentCount=_.differenceWith(filterItem,valueFilterName,_.isEqual);
                           //when if hotel should have all of selected  item in filter choice Intersection but hotel should have some item choice Union
                           if(state.filterSelect[filterName].type===FilterType.Union){
                               if(differentCount.length===filterItem.length){
                                   status=false
                               }
                           }
                           else if(state.filterSelect[filterName].type===FilterType.Intersection){
                               if(differentCount.length!==0){
                                   status=false
                               }
                           }
                       }


                   }
                }
                if(status){
                    return item;
                }
            };
            let hotelResult=state.hotelsBackup.filter(filtering);
            return {
                ...state,
                hotels:hotelResult
            }
        case "editVoucher":
            return{
                ...state,
                reserveHotel:action.data
            }
        case "fillHotelSearchURL":
            return {
                ...state,
                searchURL:action.url
            }
        case "sortHotelSearch":
            const hotelData= _.sortBy(state.hotels,action.input.typeSort);
            const hotelDataBackup= _.sortBy(state.hotelsBackup,action.input.typeSort);
            return {
                ...state,
                hotels:action.input.isReverse?hotelData.reverse():hotelData,
                hotelsBackup:action.input.isReverse?hotelDataBackup.reverse():hotelDataBackup
            }
        case "setVoucherDetails":
            return{
                ...state,
                voucherDetails:action.result
            }
        case "setCancelationRoom":
            let selectedHotel=JSON.stringify(state.selectedHotel);
            selectedHotel=JSON.parse(selectedHotel);
            selectedHotel.segments.forEach((item)=>{
                item.rooms.forEach((itemIn)=>{
                    if(itemIn.bookingToken===action.result.bookingID){
                        itemIn.cancelationInfo=action.result.cancelationInfo;
                    }
                });
            });
            return {
                ...state,
                selectedHotel

            }
        default:
            return  state;
    }
};

import React from "react";
import PropTypes from "prop-types";
import Carousel, { Modal, ModalGateway } from "react-images";
import { connect } from "react-redux";
import "../../scss/gallery.scss";

class Gallery extends React.Component {
  static propTypes = {
    images: PropTypes.any.isRequired,
  };
  state = {
    currentImage: 0,
    lightboxIsOpen: false,
    largeImages: [],
  };
  openLightbox = (param) => {
    this.setState({
      currentImage: param,
      lightboxIsOpen: true,
    });
  };
  closeLightbox = () => {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  };
  gotoPrevious = () => {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  };
  gotoNext = () => {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  };
  componentDidMount() {
    let imagesArray = [];
    this.props.images.forEach((element) => {
      imagesArray.push({
        height: 1,
        width: 1,
        src: element.sizes[1].path,
      });
    });
    this.setState({
      largeImages: imagesArray,
    });
  }
  render() {
    const { Dictionary, images } = this.props;
    return (
      <>
        <div className="hotel-gallery">
          <div className="gallery-row">
            {images[0] == null ? null : (
              <div className="large-item">
                <div
                  className="image"
                  style={
                    images[0].sizes[0] == null
                      ? null
                      : {
                          backgroundImage: `url(${images[0].sizes[1].path})`,
                        }
                  }
                  onClick={this.openLightbox.bind(this, 0)}
                />
              </div>
            )}
            {images[1] == null ? null : (
              <div className="gallery-column">
                <div>
                  <div
                    className="image"
                    style={
                      images[1] == null
                        ? null
                        : {
                            backgroundImage: `url(${images[1].sizes[0].path})`,
                          }
                    }
                    onClick={this.openLightbox.bind(this, 1)}
                  />
                </div>
                {images[2] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      style={
                        images[2] == null
                          ? null
                          : {
                              backgroundImage: `url(${images[2].sizes[0].path})`,
                            }
                      }
                      onClick={this.openLightbox.bind(this, 2)}
                    />
                  </div>
                )}
                {images[3] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      style={
                        images[3] == null
                          ? null
                          : {
                              backgroundImage: `url(${images[3].sizes[0].path})`,
                            }
                      }
                      onClick={this.openLightbox.bind(this, 3)}
                    />
                  </div>
                )}
                {images[4] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      style={
                        images[4] == null
                          ? null
                          : {
                              backgroundImage: `url(${images[4].sizes[0].path})`,
                            }
                      }
                      onClick={this.openLightbox.bind(this, 4)}
                    />
                  </div>
                )}
              </div>
            )}
            {images[5] == null ? null : (
              <div className="gallery-column">
                <div className="">
                  <div
                    className="image"
                    style={
                      images[5] == null
                        ? null
                        : {
                            backgroundImage: `url(${images[5].sizes[0].path})`,
                          }
                    }
                    onClick={this.openLightbox.bind(this, 5)}
                  />
                </div>
                {images[6] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      style={
                        images[6] == null
                          ? null
                          : {
                              backgroundImage: `url(${images[6].sizes[0].path})`,
                            }
                      }
                      onClick={this.openLightbox.bind(this, 6)}
                    />
                  </div>
                )}
                {images[7] == null ? null : (
                  <div>
                    <div
                      className="image"
                      style={
                        images[7] == null
                          ? null
                          : {
                              backgroundImage: `url(${images[7].sizes[0].path})`,
                            }
                      }
                      onClick={this.openLightbox.bind(this, 7)}
                    />
                  </div>
                )}
                {images[8] == null ? null : (
                  <div className="">
                    <div
                      className="image"
                      style={
                        images[8] == null
                          ? null
                          : {
                              backgroundImage: `url(${images[8].sizes[0].path})`,
                            }
                      }
                    >
                      <div
                        className="hover"
                        onClick={this.openLightbox.bind(this, 8)}
                      >
                        <span className="more-images-text">
                          {Dictionary.moreImages}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <ModalGateway>
          {this.state.lightboxIsOpen ? (
            <Modal onClose={this.closeLightbox}>
              <Carousel views={this.state.largeImages} currentIndex={this.state.currentImage} />
            </Modal>
          ) : null}
        </ModalGateway>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps)(Gallery);

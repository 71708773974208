import React,{useState,useEffect,useRef} from "react";
import { connect } from "react-redux";
import { setHomeCSS } from "./../../Actions/VisualDataAction";
import { Link } from "react-router-dom";
import FlightSearchForm from "../../components/Flight/SearchForm";
import HotelSearchForm from "../../components/Accomodation/SearchForm";
import PackageSearchForm from "../../components/Package/PackageSearchForm";
import LayoutConfig from "../../Config/layoutConfig";
import FlightHomeTop from "../../components/TOF/Flight-Home-Top-TOF";
import HotelHomeTop from "../../components/TOF/Hotel-Home-Top-TOF";
import PackageHomeTop from "../../components/TOF/Package-Home-Top-TOF";


import leftArr from '../../image/arrow-left.svg'
import rightArr from '../../image/arrow-right.svg'
import banner1 from '../../image/namintravel-slider-images/slide(1).jpg';
import banner2 from '../../image/namintravel-slider-images/slide(2).jpg';
import banner3 from '../../image/namintravel-slider-images/slide(3).jpg';

const Banner = (props) =>{
  const sliderArray = [
    {image:banner1,title:"AMAZING OFFERS",subtitle:"15 kg extra Baggage"},
    {image:banner2,title:"AMAZING OFFERS",subtitle:"Buy Confidently"},
    {image:banner3,title:"AMAZING OFFERS",subtitle:"Free Professional Support"},
    {image:banner1,title:"AMAZING OFFERS",subtitle:"Easier Change Rules"},
    {image:banner2,title:"AMAZING OFFERS",subtitle:"Submit A Free Meal Service Request"},
    {image:banner3,title:"AMAZING OFFERS",subtitle:"Submit A Free Wheelchair Service Request"},
    {image:banner1,title:"AMAZING OFFERS",subtitle:"Buy Online Ticket With Extra Baggage"},
    {image:banner2,title:"AMAZING OFFERS",subtitle:"Flexible Ticket Service"}
    //, {image:banner2,title:"AMAZING OFFERS",subtitle:"Protection Against The Airline's Bankruptcy!"}
  ]; 
  const [activeBannerIndex,setActiveBannerIndex] =useState(0);

  const previousBanner = ()=>{
    if (activeBannerIndex === 0){
        setActiveBannerIndex(sliderArray.length - 1);
    }else{
        setActiveBannerIndex(activeBannerIndex - 1);
    }
  }
  
  const nextBanner = ()=>{
    if (activeBannerIndex === sliderArray.length - 1){
        setActiveBannerIndex(0);
    }else{
        setActiveBannerIndex(activeBannerIndex + 1);
    }  
  }
  function useInterval(callback, delay) {
    const savedCallback = useRef();
  
    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
    }, [callback]);
  
    // Set up the interval.
    useEffect(() => {
      let autoPlayTimer = setInterval(() => {
        savedCallback.current();
      }, delay);
      return () => clearInterval(autoPlayTimer);
    }, [delay]);
  }

  useInterval(() => {
    if (activeBannerIndex<sliderArray.length-1){
      setActiveBannerIndex(activeBannerIndex + 1);
    }else{
      setActiveBannerIndex(0);
    }
  }, 4500);


  const { Dictionary } = props;
  return(
    <>
        <div
          className={`home-search-section relative ${LayoutConfig.themeClassName === 'naminTravel-theme' ? "has-light-layer":""}`}
          style={{backgroundImage:`url("${(LayoutConfig.themeClassName === 'naminTravel-theme' && activeBannerIndex)?sliderArray[activeBannerIndex].image:LayoutConfig.homeBanner}")`}}
        >
          {(LayoutConfig.themeClassName === "padide-com-theme" ) ? 
            (
            <div>
              {props.searchType==="flight" ? 
                <FlightHomeTop handleLink={() => this.handleLink(74961, "Phuket")}/>
              :props.searchType==="hotel" ?
                <HotelHomeTop handleLink={() => this.handleLink(74961, "Phuket")}/>
              :props.searchType==="package" ?
                <PackageHomeTop handleLink={() => this.handleLink(74961, "Phuket")}/>
              :null}
              
            </div>
            )
          :
            (          
            <>
              {LayoutConfig.themeClassName === 'naminTravel-theme' ?
               <div className="relative">
                    <div className="slider-text-inner-wrapper">
                        <h3 className="slider-title relative">
                          {sliderArray.map((item,index)=><span key={index} className={index === activeBannerIndex ? "slider-title-text active":"slider-title-text"}>{item.title}</span>)}
                          {/* <span className="slider-title-text active">{sliderArray[0].title}</span> */}
                        </h3>
                        <div className="slider-subtitle-and-arrows">
                            <button className="slider-prev-btn" onClick={previousBanner}>
                                <img src={leftArr} alt="left" />
                            </button>
                            <span className="slider-subtitle relative">
                              {sliderArray.map((item,index)=><span key={index} className={index === activeBannerIndex ? "slider-subtitle-text active":"slider-subtitle-text"}>{item.subtitle}</span>)}
                            </span>
                            <button className="slider-next-btn" onClick={nextBanner}>
                                <img src={rightArr} alt="right" />
                            </button>
                        </div>
                    </div>                    
               </div>
               :
               
              <div className={"page-container hidden-xsmall hidden-small"}>
                <h2
                  className={"home-top-slogan"}
                  style={{
                    color: LayoutConfig.homeSlogancolor,
                  }}
                >
                  {LayoutConfig.sloganText && LayoutConfig.sloganText.largeText
                    ? LayoutConfig.sloganText.largeText
                    : Dictionary.letsBookYourNextTrip}

                  {LayoutConfig.sloganText &&
                  LayoutConfig.sloganText.largeText ? (
                    LayoutConfig.sloganText.smallText ? (
                      <span className="subslogan">
                        {LayoutConfig.sloganText.smallText}
                      </span>
                    ) : null
                  ) : (
                    <span className="subslogan">
                      {props.searchType==="flight" ? 
                        Dictionary.chooseFromOver450AirlinesWorldwide
                      :props.searchType==="hotel" ?
                        Dictionary.chooseFromOver1_5MillionHotelsWorldwide
                      :null}
                    </span>
                  )}
                </h2>
              </div>
              }

              <div className={LayoutConfig.themeClassName === 'naminTravel-theme' ? "naminTravel-search-block" : ""}>
                <div className="page-container mobile-tab-hotel-flight">
                  <div className="clearfix home-tabs-holder">
                    {props.searchType==="flight" ?     
                      <>                
                        {LayoutConfig.hasDomesticFlight?
                          <>
                          <button type="button" className="home-tab home-active-tab">
                            <span className="itours-icon top-plane-blue margin-end-light hidden-medium hidden-large" />
                            پرواز داخلی
                          </button>
                          <Link to="/foreignflight" className="home-tab">
                            <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                            {Dictionary.foreignFlight}
                          </Link>                                        
                          </>
                          :
                          <button type="button" className="home-tab home-active-tab">
                            <span className="itours-icon top-plane-blue margin-end-light hidden-medium hidden-large" />
                            {Dictionary.foreignFlight}
                          </button>
                        }


                        <Link to="/hotel" className="home-tab">
                          <span className="itours-icon hotel-white margin-end-light hidden-medium hidden-large" />
                          {Dictionary.foreignHotel}
                        </Link>

                        <Link to="/package" className="home-tab">
                          <span className="itours-icon package-white margin-end-light hidden-medium hidden-large" /> 
                          {Dictionary.package}
                        </Link>  
                        
                      </>
                    :props.searchType==="foreignFlight"?
                      <>                

                        <Link to="/" className="home-tab">
                          <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                          پرواز داخلی
                        </Link> 

                        <button type="button" className="home-tab home-active-tab">
                          <span className="itours-icon top-plane-blue margin-end-light hidden-medium hidden-large" />
                          {Dictionary.foreignFlight}
                        </button>  


                      <Link to="/hotel" className="home-tab">
                        <span className="itours-icon hotel-white margin-end-light hidden-medium hidden-large" />
                        {Dictionary.foreignHotel}
                      </Link>

                      <Link to="/package" className="home-tab">
                        <span className="itours-icon package-white margin-end-light hidden-medium hidden-large" /> 
                        {Dictionary.package}
                      </Link>  
                      
                    </>                    
                    :props.searchType==="hotel" ?
                      <>
                        {LayoutConfig.hasDomesticFlight ?
                          <Link to="/" className="home-tab">
                            <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                            پرواز داخلی
                          </Link>
                        :null}                  
                        <Link to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"} className="home-tab">
                          <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                          {Dictionary.foreignFlight}
                        </Link>

                        <button type="button" className="home-tab home-active-tab">
                          <span className="itours-icon hotel-blue margin-end-light  hidden-medium hidden-large" />
                          {Dictionary.foreignHotel}
                        </button>  
                        <Link to="/package" className="home-tab">
                          <span className="itours-icon package-white margin-end-light hidden-medium hidden-large" /> 
                          {Dictionary.package}
                        </Link>                    
                      </>
                    :props.searchType==="package" ?
                    <>
                      {LayoutConfig.hasDomesticFlight ?
                        <Link to="/" className="home-tab">
                          <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                          پرواز داخلی
                        </Link>
                      :null}                  
                      <Link to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"} className="home-tab">
                        <span className="itours-icon top-plane-white margin-end-light hidden-medium hidden-large" />
                        {Dictionary.foreignFlight}
                      </Link>

                      <Link to="/hotel" className="home-tab">
                        <span className="itours-icon hotel-white margin-end-light hidden-medium hidden-large" />
                        {Dictionary.foreignHotel}
                      </Link>

                      <button type="button" className="home-tab home-active-tab">
                        <span className="itours-icon package-blue margin-end-light hidden-medium hidden-large" /> 
                        {Dictionary.package}
                      </button>                       
                    </>                      
                    :null}

                  </div>
                </div>

                <div className="search-form-wrapper">
                  <div className="page-container">
                    {props.searchType==="flight" ? 
                      <FlightSearchForm domesticFlight={LayoutConfig.hasDomesticFlight} />
                    :props.searchType === "foreignFlight" ?
                      <FlightSearchForm />
                    :props.searchType==="hotel" ?
                      <HotelSearchForm collapseMode={false} fixedSearchHolder={false} />
                    :props.searchType ==="package" ? 
                      <PackageSearchForm collapseMode={false} />
                    :null}
                    
                  </div>
                </div>
              </div>
            </>
            )
          }
        </div>    
    </>
  )}
  const mapDispatchToProps = (dispatch) => ({
    setHomeCSS: (data) => {
      dispatch(setHomeCSS(data));
    },
  });
const mapStateToProps = (state) => ({
    Dictionary: state.VisualData.Dictionary,
    LAN: state.VisualData.LAN,
});
export default connect(mapStateToProps, mapDispatchToProps)(Banner);
import React from 'react';
class TermsEn extends React.PureComponent{
    render() {
        return (
            <>
                <div className="wrapper">
                    <div>
                        It is the buyer and the traveler's responsibility to...
                    </div>
                    <br />
                    <ul>
                        <li>Read and approve travel conditions before purchase.</li>
                        <li>
                            Ensure that all information is correct as regards to spellings of names and title, as well as check the flight
                            schedule dates before the agreement becomes effective. By choosing payment option, confirm the information.
                        </li>
                        <li>
                            Once you've completed your purchase, you get the ticket sent to you via email within 24 hours. It is the customer's responsibility to control that the number of ticket number matches the number of travelers. If the trip consists of two single tickets, each passenger is to have one ticket number per way. If there are missing ticket numbers or any other ambiguities you should contact Itours within two days.
                        </li>
                        <li>
                            Regularly check the specified e-mail address, since all correspondence with us is via e-mail.
                        </li>
                        <li>
                            Continuous check flight schedules and any changes in the schedule for both outbound and return journey.
                        </li>
                        <li>
                            Checking the current check-in times for the entire journey.
                        </li>
                        <li>
                            Checking with respective airlines from which airport terminal departure occurs.
                        </li>
                        <li>
                            Checking the visa requirements for their final destination as well as any intermediate stops. This is done via the
                            respective embassy and the airlines you are traveling with.
                        </li>
                        <li>
                            Check passport regulations and the passport validity, since some countries require that your passport is valid
                            for at least six months after the return journey
                        </li>
                        <li>
                            Check what vaccinations needed prior to travel.
                        </li>
                    </ul>
                    <div>Order.</div>
                    <p>
                        Enter the correct email address when booking. All communication between Itours and the travelers after booking is made via e-mail. Therefore it is important to continually check the available e-mail between time of booking and departure dates.
                    </p>
                    <p>
                        By ordering the roundtrip tickets must all stretches of the ticket is used in the correct order. If the traveler fails to attend any stretch, consciously or unconsciously, the airline will cancel the remaining part of the journey and will not make the repayment on the parts of the journey not used.
                    </p>
                    <p>
                        <b>
                            Itours also offers tour and return tickets composed of two single tickets. The rules for each ticket apply. It will appear when the ticket consists of two single journeys. In those cases the order consists of two single flights, the customer is responsible for checking the travel and ticket numbers for both journeys are received.
                        </b>
                    </p>
                    <p>
                        Once you have ordered and paid journey, we send an order confirmation to the email address provided at time of booking. In this confirmation email you will find a link to your personal booking page where you can print your e-ticket.
                    </p>
                    <p>
                        If the airline has changed the flight schedule, you can see this here. We recommend that you print the ticket from this page before traveling. The information on the page is in English. To change or add contact information in your order, you need to have a reservation number when contacting us. If you have not received confirmation via email, it is important that you contact us immediately so that we can confirm this and correct e-mail address if it is not correct. You always travel with e-ticket (electronic ticket). There are no longer paper tickets, instead you get an electronic travel document via e-mail from us. The simplest is that you print e-ticket and take it with you to the check In. There you will find all important information, ticket number and flight times. You will be given a boarding pass at check-in counter at the airport when you show a valid passport.
                    </p>
                    <div>
                        <b>
                            Check that all travelers have received ticket number.
                        </b>
                    </div>
                    <p>
                        Boarding cards can be printed at home if you check in online. They may otherwise be retrieved at check counter at the airport.
                        To book a trip on the homepage you must be over 18 years old or have authorization from a legal guardian
                    </p>
                    <div><b>Name.</b></div>
                    <div><b>Caution.</b></div>
                    <p>
                        Because of the airlines' high demands on safety, it is very important to fill in correct spelled names of all travelers in your booking.
                    </p>
                    <p>
                        First: To enter the first name as they appear in the passport
                    </p>

                    <p>
                        Surname: Enter all surnames on the basis of the passport. You cannot shorten your name or provide them only with initials.
                        If you're getting married, you must book your trip in the same name that you are going to have in your passport during the journey.
                        It is the buyer's responsibility to check so that all names are spelled correctly. Note that the booking system takes away all between stroke and punctuation between names. Oh, æ and ø changed according to international alphabet
                        When payment option has been selected and the ticket is issued, it is up to the airline's rules concerning the change of name are allowed. It is the customer's responsibility to inform themselves about airline regulations before completed purchase. In cases where the airline does change / correction of name, takes Itours a fee of 500 NOK / ticket for managing changes, in addition to the eventual fee from the airline.

                    </p>
                    <div><strong>Traveling with children.</strong></div>
                    <p>
                        It is important to enter the correct age of all children who accompany us on our journey. Their age are checked by the airline. Observe that the child's age at the return date will apply. If your child turns two during the journey should the trip booked as a child over two years. The same applies if the traveler fills twelve years on the journey. This should then be ordered as an adult.
                    </p>
                    <p>
                        Children who have not reached two years recorded as infants. This means that the child does not have a separate seat and instead get to sit on the lap of an adult.
                    </p>
                    <p>
                        It is not possible to book a travel to a child not yet born, or a person you do not know the name of.
                        So you cannot place an order on our homepage and specify , "Baby" that name, since the correct name must be specified when ordering and this must match the name on your passport.
                        We cannot guarantee that children are not involved from the start in order to fit on the plane. It is not always technically possible to book additional space for an infant on an existing reservation.
                        An infant cannot be added to a partially used order. For example, if the parents have traveled to another country to give birth, and have a t / r-ticket, it is not possible to add the infant on the rebound.
                        It is also not possible to place an infant alone in a separate order. In such cases, it must be ordered a new single ticket for one adult and infant.

                    </p>
                    <div><b>Children traveling alone.</b></div>
                    <p>
                        On our website it is not possible to book a separate children ticket since child discount applies only in the company of an adult. If you have booked to travel for a child on an adult ticket, it is technically not possible to book assistance on board.
                        For children traveling on their own it is often mandatory to pre-book called Unaccompanied Minor Service. The rules and the cost for this varies from airline to airline. Minimum and maximum permissible age also varies. SAS minimum age, for example, is Five years, while Air France has minimum age is 4 years.
                        Generally you should use the same airline on the journey in order to guarantee that it works. We need to know your name, address and telephone number of the persons supplying / collecting the child at the airport.
                        A so-called Action Advice must also be filled out at the airport. Each Unaccompanied Minor Service must be sent as a request from Itours to the Airline and this service is not guaranteed until the airline has accepted the request.
                        If you want to book a child ticket with assistance for unaccompanied children please contact us via the contact form on our website. Note that a booking fee applicable (295 NOK).
                    </p>
                    <div><b>Payment.</b></div>
                    <p>
                        You have several options. To pay by credit card or via online banking, you pay the entire sum the day the order is carried out. Observe that all forms of payment are binding once you have made the choice of payment method.
                        When you pay by card, it adds an additional fee. This we will notify you before the agreement becomes effective, and the fee will not be refunded. The card must be issued in Scandinavia
                        Cancellation shall not apply for transport. Once the customer has completed the order is considered to be paid and after this the booking is binding.
                    </p>
                    <p>
                        Itours conveys products from different vendors, and is therefore not responsible for the events that have to do with the completion of the journey. Example of this is lost baggage, baggage that is not included in the flight, canceled flights and changes in flight schedules. This responsibility rests with the airlines and the customer should therefore apply directly to the relevant airline with any complaints. Lost or damaged baggage must always be reported directly in place of the relevant airline.
                    </p>
                    <div><b>Technical problems.</b></div>
                    <p>

                        Itours reservs against any technical problems, data errors, pricing errors and changes made by airlines outside our control. It may, for example. be airlines showing places that are not available, changes in flight schedules or erroneous prices due to data errors. The airlines also have a right to change the order within 24 hours after the order has been completed.
                        We reserve the right to contact you within 24 hours on weekdays for any changes in the order. If problems occur during or in connection with the weekend we will contact you the first coming workday.
                    </p>
                    <div><b>Withdraw.</b></div>
                    <p>

                        Cancellation shall not apply for transport, according to current law. We have no 24-hour rule when it comes to our orders. Once payment is completed and the ticket is issued, we have no opportunities to change your ticket, but must follow the airlines' reissued- / cancellation policy.
                        These rules are determined by the airlines and not by us as agent. Read more under Cancellation / rebooking.

                    </p>
                    <div><b>Double Booking.</b></div>
                    <p>
                        The traveler has the responsibility to ensure that any double bookings will be canceled no matter how they are implemented. The traveler risk otherwise that the airline without notice cancel double booking. Itours assumes no responsibility for airline cancellations or failure to refund in connection therewith.
                    </p>
                    <div>
                        <b>
                            Cancellation / rebooking.
                        </b>
                    </div>
                    <p>
                        Once payment is completed and the ticket is issued, it is the airline's rules for rebooking / cancellation will apply. It is the customer's responsibility to obtain information about the rules that apply on the ticket before the purchase is completed. This can be done by contacting customer service at +47 22 11 27 44 Monday - Friday from 10. to 16.00.
                        In those cases the airline allows rebooking Itours takes 500 NOK / ticket processing fee in addition to the airline reissued fee.
                        In those cases the airline gives refunds on canceled tickets are always at a cost. In addition to airline cancellation charge Itours takes 500 NOK / ticket.
                        Energy consumption costs as card fee, cancellation insurance and service charge refunded. In most cases, refundable only a small portion of taxes, since these include fuel costs and other fees that the airline will reimburse. Upon repayment of the only airport taxes, Itours takes an administration fee of 250 NOK / ticket to the taxes and fees that the airline will reimburse.
                        Repayment is performed by the airlines; the processing time is 5-12 weeks.

                    </p>
                    <p>

                        Rebooking must be made at least 24 hours before departure at either the original booking or rebooking whichever occurs first.
                        Rebooking is not completed before Reissued fee is paid and Itours has issued new ticket.
                        All rebooking must be made by telephone. Please have your card available for payment of rebooking when you call us.
                        Cancellation must be made no later than 24 hours before the departure of the original order. The journey is considered canceled only when Itours has confirmed the cancellation.
                        Itours repays never partially used ticket.

                    </p>
                    <div><b>Flight times.</b></div>
                    <p>
                        All times given is in local times for respective airports. The times indicated at stopover approved time for change of aircraft, estimated by the airlines in cooperation with the airport. In case of delay means that policyholders do not make the next flight on a ticket that covers more flights, it is the airline's responsibility to help the customer to the destination.
                        When booking separate tickets, the airline has no responsibility for whether they miss the next flight because of delays. It is then the client's responsibility to ensure there is enough time to change planes.
                        Any mark transports and lodging during the trip is not included in the ticket price. It is the traveler's responsibility to check flight times before ordering.

                    </p>
                    <div>
                        <b>
                            Direct or non-stop.
                        </b>
                    </div>
                    <p>
                        Travelling with direct flights may mean that the plane can land on the road for example to refuel As long as the aircraft does not change the flight number on landing consider airlines that there is a direct flight. We mark this intermediate landing on the booking confirmation and do not attempt in any way to hide any stopovers. This type of technical stop is approved based on the international flight rules adopted by IATA (International Air Traffic Association).
                        Nonstop-flight is a flight that does not stopovers but flying from point A to B without example to refuel.
                        Fly indirect flights are with one or several stopovers and where you change aircraft and flight number.
                        Replacing airport and transfer

                    </p>
                    <p>

                        If a trip booked indirect that includes change of airport customer must itself be responsible for transfer costs between the airports. This applies for example, during a stopover in London when the journey in some cases goes to London / Heathrow and since further from London / Gatwick. Other common places where it sometimes occurs swap airport during a stopover for example. New York and Paris. Remember to check your flight schedule carefully. When replacing the airport luggage must be extracted and then checked in again at the new airport. At journey of a stop requires an overnight stay because the next flight does not continue until the next day, the traveler must even pay for any hotel night.
                    </p>
                    <div><b>Changes in flight schedules.</b></div>
                    <p>

                        The airlines can with short notice change flight times and cancel the flight. This is beyond Itours control. , The change in flight time before departure will be e-mailed with current information to the email address specified in the order. If the change in the flight time is not accepted by the traveler must be reported to Itours promptly. If the change occurs after leaving it difficult for Itours to give notice.
                        It is the traveler's responsibility to keep informed about the correct flight times for outward and return journey.
                        Those times the airline refund due to changes in flight schedules Itours takes out an administrative fee of 150 NOK / ticket. Repayment is made from the airlines and the processing time is from 5 to 12 weeks.

                    </p>
                    <p>
                        <b>
                            If you have booked a tour and return journey which consists of two single journeys and change occurs in flight time or a flight is canceled on one of the journeys, it is not permitted to change or cancel the second journey because of this, since journeys in the Carrier eyes do not stick together.
                        </b>
                    </p>
                    <p>
                        Itours cannot be held responsible for whether on or near the destination occurs environmental disaster, act of war, strike or other intervening event that makes the planned trip cannot be carried out. Itours arranges tickets and cannot be held responsible for any changes in flight schedules or canceled flights.
                    </p>
                    <div><b>Insurance.</b></div>
                    <p>
                        We strongly advice our travellers to have a travel insurance or buy one before departure. It is the travellers responsibility to have the insurance needed for their travel.
                    </p>
                    <div><b>Documentation.</b></div>
                    <p>
                        The traveler's responsibility to check any visa requirements with the embassy. Depending on the destination you refused to get on board if you do not have valid travel documents, such as passports, visas and transit visas (think also that some countries need a passport valid for at least 6 months after discharge date). We recommend that you always have your passport when going out and traveling.
                        Europeans traveling to the United States or between the lands which must be registered entry online. Passengers are requested to enter the estas web page and fill in the mandatory information at least 72 hours before departure.
                        Itours can help with visa to Australia for some nationalities, cost 500 Nok / person.
                    </p>
                    <div><b>Check In.</b></div>
                    <p>
                        We recommend that you are at the airport in good time before departure. Consider that there may be long queues to check in and security checks, and taking also into account that things could happened on the way to the airport. Many airlines offer check in on its website about 22 hours before departure. For questions about check online, we refer to the airline you are traveling with.
                    </p>
                    <div><b>Luggage.</b></div>
                    <p>
                        The traveler is responsible for controlling which baggage rules apply. Information can be found on the ticket or the respective airline's website.
                        Itours assumes no liability for lost or damaged luggage. Any errors should be prosecuted immediately at the airport, airline representative.
                    </p>
                    <p>
                        Notes that certain airlines take out a baggage fee. This is payable by check directly to the airline. This applies for example. Air Baltic, US carriers (domestic US).
                        By traveling with more than one airline, there may be different rules for the different selskapene.1 PC = 1 piece (maximum 1 bag á 23 kg) Norwegian 1 PC = 1 piece (maximum 1 Bag x 20 kg)
                    </p>
                    <div><b>Extra baggage.</b></div>
                    <p>
                        Unfortunately we do not have the option to add more storage. Please contact the airline directly for more information on possible opportunities for extra baggage as well as cost. Information about baggage usually use to be found on the airline's website.
                    </p>
                    <div><b>Special baggage.</b></div>
                    <p>
                        Most airlines want that you notify in advance if you should bring special baggage on the journey. We need to know the weight and dimensions (length x height x width) in special bags. You get most often reply from the airline within a few hours. The fee for this is paid always directly to the airline.
                    </p>
                    <p>
                        For special baggage considered : skiing, golf, weapons, musical instruments, etc. Note that some smaller aircraft types cannot accommodate larger special baggage.
                        Itours can only send a request to the airline when it comes to special baggage, we can never guarantee that the request will be approved until we have received confirmation from the airline.
                    </p>
                    <div><b>Contents of hand luggage.</b></div>
                    <p>
                        For information on liquids, medicine, baby food and special diet in hand luggage, we refer to Airlines web pages.
                        Checked baggage is not affected by the restrictions.
                    </p>
                    <div><b>Denied boarding, cancellations and delays.</b></div>
                    <p>
                        In accordance with European law under certain circumstances entitled to a refund and / or compensation from the airline in connection with denied boarding, cancellations and delays. More information about these rights is unveiled at European airports, and they are also available from the airlines. Compensation in such cases, however, is the airline's responsibility.
                    </p>
                </div>
            </>         
        )
    }

}
export default TermsEn;
import React from "react";
import queryString from "query-string";
import { connect } from "react-redux";
import { getCancellationPolicy } from "./../../../Actions/HotelAction";
import Price from "../../../components/Common/Price";
import Loading from "../../../components/Common/Loading";
import { Tooltip } from "antd";
import moment from "moment-jalaali";
class CancellationPolicy extends React.Component {
  state = {
    showModal: false,
    showLoading: true,
    cancellationRules: [],
  };
  closeModal = () => {
    this.setState({ showModal: false });
  };
  openModal = () => {
    this.setState({ showModal: true });
    this.showCancelation("item", this.props.rate);
  };
  showCancelation = async (item, itemIN) => {
    let parameters = queryString.parse(this.props.location.search);
    let params = new URLSearchParams();
    parameters.Adults.split(",").forEach((item) =>
      params.append("adults", item)
    );
    params.append("bookingToken", itemIN.bookingToken);
    params.append("checkin", parameters.Checkin);
    params.append("checkout", parameters.Checkout);

    parameters.Children.split(",").forEach((item) =>
      params.append("children", item)
    );
    if (parameters.Children !== "0") {
      parameters.Ages.split(",").forEach((item) => params.append("ages", item));
    }

    const cancellationInfo = await getCancellationPolicy(params);
    if (cancellationInfo.data) {
      this.setState({
        showLoading: false,
        cancellationRules: cancellationInfo.data.result.fees,
      });
    }
  };
  componentDidMount() {
    if (this.props.LAN === "IR") {
      moment.loadPersian({
        dialect: "persian-modern",
        usePersianDigits: false,
      });
    }
  }
  render() {
    const { Dictionary, LAN } = this.props;
    const cancellationStatus = this.props.rate.cancellationPolicy.status;
    return (
      <div>
        <div className="margin-bottom-light">
          <span
            onClick={this.openModal}
            className={
              cancellationStatus === "Refundable" ? "green pointer" : "pointer"
            }
          >
            {cancellationStatus === "Refundable" ? (
              <>
                <span className="green-checkmark itours-icon margin-end-light icon-small inline-block-middle margin-b-3" />
                <span className="underlined  margin-end-light">
                  {Dictionary.refundable}
                </span>
                <span className="green-info-icon itours-icon margin-end-light icon-small inline-block-middle margin-b-3" />
              </>
            ) : cancellationStatus === "NonRefundable" ? (
              <>
                <span className="underlined">{Dictionary.nonRefundable} </span>
                <span className="info-icon itours-icon margin-end-light icon-small inline-block-middle margin-b-3" />
              </>
            ) : (
              cancellationStatus
            )}
          </span>
        </div>

        <div
          className={
            this.state.showModal ? "itours-modal visible" : "itours-modal"
          }
        >
          <div className="modal-overlay" onClick={this.closeModal} />
          <div className="modal-inner">
            <div className="modal-header">{Dictionary.cancellationRules}</div>
            <div className="modal-main">
              {this.state.showLoading ? (
                // <div className="loading-outer-container">
                //   <div>
                //     <div className="loading-inner-container">
                //       <div className="item-1"></div>
                //       <div className="item-2"></div>
                //       <div className="item-3"></div>
                //       <div className="item-4"></div>
                //       <div className="item-5"></div>
                //     </div>
                //     <div className="text-center">
                //       {Dictionary.loadingCancellationPolicy} ...
                //     </div>
                //   </div>
                // </div>
                <div>
                  <br/>
                  <Loading description={Dictionary.loadingCancellationPolicy+" ..."}/>
                </div>
              ) : (
                <div>
                  <h5 className="normal-title">
                    {cancellationStatus === "Refundable"
                      ? Dictionary.refundable
                      : cancellationStatus === "NonRefundable"
                      ? Dictionary.nonRefundable
                      : cancellationStatus}
                  </h5>
                  <div className="margin-top">
                    {this.state.cancellationRules.length > 0 ? (
                      <>
                        {LAN === "IR" ? (
                          <div className="margin-bottom">
                            امکان کنسل کردن فقط تا تاریخ های زیر میسر است و در
                            صورت درخواست
                            <b> 2,000,000 ریال </b>
                            بابت کارمزد خدمات از مبلغ کل رزرو کسر میگردد.
                          </div>
                        ) : null}
                        <ul className="bullet-list ">
                          {this.state.cancellationRules.map((rule, index) => {
                            if (rule.amount > 0) {
                              return (
                                <li key={index}>
                                  <span className="inline-block margin-end-light">
                                    <Price currency={this.props.currency}>
                                      {rule.amount}
                                    </Price>
                                  </span>
                                  <span className="inline-block margin-end-light">
                                    {Dictionary.afterDate}
                                  </span>
                                  <span className="inline-block">
                                    <Tooltip
                                      title={
                                        <span className="en-font">
                                          {moment(
                                            rule.fromDate.split("T")[0]
                                          ).format("D/M/YYYY")}
                                        </span>
                                      }
                                    >
                                      {moment(
                                        rule.fromDate.split("T")[0]
                                      ).format("D MMM YYYY")}
                                    </Tooltip>
                                    {LAN === "IR" && (
                                      <Tooltip
                                        title={moment(
                                          rule.fromDate.split("T")[0]
                                        ).format("jYYYY/jM/jD")}
                                      >
                                        (
                                        {moment(
                                          rule.fromDate.split("T")[0]
                                        ).format("jD jMMMM jYYYY")}{" "}
                                        )
                                      </Tooltip>
                                    )}
                                  </span>
                                </li>
                              );
                            } else {
                              return null;
                            }
                          })}
                        </ul>
                      </>
                    ) : (
                      <p>{Dictionary.ThereIsNoCancellationRule}</p>
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="button color-1-btn"
                onClick={this.closeModal}
              >
                {Dictionary.ok}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const stateMapProp = (state) => ({
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
export default connect(stateMapProp)(CancellationPolicy);

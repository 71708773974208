import React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { withRouter } from 'react-router-dom';
import {
  searchDomesticFlight,
  searchFlightAsync,
  sortFlightSearch,
} from "../../Actions/FlightAction";
import FilterForm from "../../components/Flight/FilterForm";
import ListItem from "../../components/Common/ListItem.js";
import { Flight, Travel } from "../../Enum/Models";
import sortIcon from "../../image/vertical2arrow.svg";
import Loading from '../../components/Common/Loading';
import _ from "lodash";
//import image
import SearchForm from "../../components/Flight/SearchForm";
import AirlineFilter from "../../components/Flight/AirlineFilter.js";
import Page from "../../components/Common/Page";
import danger from "../../image/danger.svg";
import { editMessage } from "../../Actions/VisualDataAction";
import LayoutConfig from "../../Config/layoutConfig";
import FlightFormSearchTOF from "../../components/TOF/Flight-Form-Search-TOF";
class domesticFlightResult extends React.Component {
  state = {
    result: null,
    error: null,
    loadingShow: true,
    first: 0,
    last: 9,
    showfilter: false,
    showsearch: false,
    sortPrice: false,
    sortName: 0,
    sortRate: 0,
    tripType: null,
    infoModalVisibility:false,
    timeoutModalVisibility:false
  };
  handleChangeValue(first, last) {
    this.setState({ first: first, last: last });
  }
  closeInfoModal=()=>{
    this.setState({infoModalVisibility:false});
  }
  
  resultsTimer = null;

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.setState(
        {
          loadingShow: false,
          result: null,
        },
        () => {
          this.props.searchFlightAsync(new Flight());
          this.props.editMessage(false);
          this.getFlights();
        }
      );
    }
    if (this.props.respond !== prevProps.respond) {
      this.setState({
        loadingShow: !this.props.respond,
        result: this.props.flights
        
      });
    }

    if (this.props.flights !== prevProps.flights) {
      this.setState({
        result: this.props.flights,
      });
    }
    if (this.state.result !== prevState.result) {
      this.setState({
        first: 0,
        last: 9,
      });

      let searchedAirTripType = this.props.location.search.split("airTripType=")[1].split("&")[0];
      if(searchedAirTripType === "RoundTrip"){
        this.setState({infoModalVisibility:true});
      }

    }
    if (this.props.flights && this.props.flights.length>0 && prevProps.flights !==this.props.flights){
      this.resultsTimer = setTimeout(()=>{this.setState({timeoutModalVisibility:true})},600000);        
    }
    if (this.props.location !== prevProps.location){
      clearTimeout(this.resultsTimer);
    }

  }
  componentWillUnmount() {
    clearTimeout(this.resultsTimer);
  }
  componentDidMount() {
    if (this.props.location.search){
      let searchedAirTripType = this.props.location.search.split("airTripType=")[1].split("&")[0];
      if(searchedAirTripType === "RoundTrip"){
        this.setState({infoModalVisibility:true});
      }
    }
    window.scrollTo(0, 0);
    this.props.searchFlightAsync(new Flight());
    this.props.editMessage(false);
    this.getFlights();
    //this.props.fillHotelSearchURL(window.location.href);
  }
  handleSort = (type) => {
    if (type === "totalPrice") {
      this.setState(
        (prevState) => ({
          sortPrice: !prevState.sortPrice,
        }),
        () => {
          this.props.sortFlightSearch({
            typeSort: type,
            isReverse: this.state.sortPrice,
          });
        }
      );
    }
  };
  getFlights = () => {
    let parameters = queryString.parse(this.props.location.search);
    parameters.adult = parseInt(parameters.adult);
    parameters.child = parseInt(parameters.child);
    parameters.infant = parseInt(parameters.infant);
    parameters.departureDateTimes = parameters.departureDateTimes.split("|");
    parameters.originCodes = parameters.originCodes.split("|");
    parameters.originNames = parameters.originNames.split("|");
    parameters.destinationCodes = parameters.destinationCodes.split("|");
    parameters.destinationNames = parameters.destinationNames.split("|");
    if (parameters.airTripType === "RoundTrip") {
      parameters.originCodes.push(parameters.destinationCodes[0]);
      parameters.destinationCodes.push(parameters.originCodes[0]);
    }
    this.setState({
      tripType: parameters.airTripType,
    });
    this.props.searchFlight(parameters);
  };
  render() {
    const { Dictionary } = this.props;
    return this.state.loadingShow ? (
      // <div className="loading-outer-container">
      //   <div>
      //     <img
      //       src={LayoutConfig.logo ? LayoutConfig.logo : siteLogo}
      //       className="loading-logo"
      //       alt={LayoutConfig.companyName ? LayoutConfig.companyName : "itours"}
      //     />
      //     <div className="loading-inner-container">
      //       <div className="item-1" />
      //       <div className="item-2" />
      //       <div className="item-3" />
      //       <div className="item-4" />
      //       <div className="item-5" />
      //     </div>
      //     <div className="text-center">
      //       {Dictionary.SearchingBestPrices} ...
      //     </div>
      //   </div>
      // </div>
      <Loading fullPage description={Dictionary.SearchingBestPrices+" ..."}/>
    ) : // loading
    this.state.result === null ? (
      <div className="flight">
        <div className="page-container">
          <div className="section-vertical-padding">
            <h2 className="page-title">
              {Dictionary.noFlightFoundForYourRequest}.
              <div className="page-subtitle">{Dictionary.pleaseTryAgain}</div>
            </h2>

            <div className="clearfix alert-cart card">
              <img src={danger} className="alert-icon" alt="danger-icon" />
              <div className="alert-content">
                <h6 className="alert-title">
                  {Dictionary.please},{Dictionary.noFlightFoundForYourRequest}.
                </h6>
                <p className="no-margin">{Dictionary.pleaseTryAgain}.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="flight search-result-page">
        {this.state.timeoutModalVisibility &&
          <div className="itours-modal visible">
            <div className="modal-overlay" onClick={this.closeItinerariModal} />
            <div className="modal-inner small-width">
              <div className="modal-main">
                <div className="text-center margin-top margin-bottom">
                  <div className="margin-bottom">
                    <span className="itours-icon gray-clock-icon icon-xlarge" />
                  </div>
                  <h4 className="normal-title margin-bottom">
                    {Dictionary.flightStillAroundTitle}
                  </h4>
                  <p className="timer-modal-text">
                    {Dictionary.flightStillAroundP1} {Dictionary.flightStillAroundP2}
                  </p>
                  <button
                    type="button"
                    className="button color-1-btn"
                    onClick={()=>window.location.reload()}
                  >
                    {Dictionary.searchAgain}
                </button>
                </div>
              </div>
            </div>
          </div>
        }

        {(this.state.infoModalVisibility && this.state.result && this.state.result.length>0) && 
          <div className="itours-modal visible">
            <div className="modal-overlay" onClick={this.closeModal} />
            <div className="modal-inner small-width">
              {/* <div className="modal-header">توجه</div> */}
              <div className="modal-main">
                <p className="font-20 justified semi-bold">
                  <span className="itours-icon info-icon margin-end-light" />
                  پرواز های زیادی برای این مسیر وجود دارد اما باید به صورت دو پرواز یکطرفه رزرو شوند در حال حاضر فقط پرواز های ماهان امکان رزرو به صورت رفت و برگشت را دارند.</p>            
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="button color-1-btn"
                  onClick={this.closeInfoModal}
                >
                  {Dictionary.ok}
                </button>
              </div>
            </div>
          </div>
        }

        {(LayoutConfig.themeClassName === "padide-com-theme" || LayoutConfig.themeClassName === "naminTravel-theme__") && (
          <div className="result-inline-search-section">
            <div className="page-container">
              <FlightFormSearchTOF inline={true} />
            </div>
          </div>
        )}
        {this.state.result && this.props.airlineFilter.length !== 0 ? (
          <div className="airline-filter-holder">
            <div className="page-container">
              <AirlineFilter />
            </div>
          </div>
        ) : null}
        <div className="page-container">
          <div className="avails-row float-row flight-avails-row">
            <button
              type="button"
              className="filter-responsive-btn"
              onClick={() => {
                this.setState((prevState) => ({
                  showfilter: !prevState.showfilter,
                }));
              }}
            >
              {Dictionary.filters}
            </button>

            <div className="col-small-12 col-medium-3">
              <div className="sidebar box-border bg-white">
                {/*                                         <div className="sidebar-item clearfix">
                                    <span className="results-number-holder">
                                        <b> {<span>{this.state.result && this.state.result.length}</span>} </b> flyreise funnet
                                    </span>
                                    <button type="button" className="button grey-btn pull-end filter-responsive-btn" onClick={()=>{this.setState((prevState)=>({showfilter:!prevState.showfilter}))}}>
                                        Filtre
                                    </button>
                                </div> */}
                <div
                  className={
                    this.state.showfilter
                      ? "showfilter filters-holder"
                      : "filters-holder"
                  }
                >
                  <FilterForm domesticFlight={true} />
                  <button
                    type="button"
                    className="button red-btn filter-close-btn"
                    onClick={() => {
                      this.setState((prevState) => ({
                        showfilter: !prevState.showfilter,
                      }));
                    }}
                  >
                    {Dictionary.close}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-small-12 col-medium-9">
              {LayoutConfig.themeClassName === "padide-com-theme" || (
                <div className="search-form-wrapper ">
                  <SearchForm
                    domesticFlight={true}
                    inline={true}
                    originCity={
                      this.state.result &&
                      this.state.result[0] &&
                      this.state.result[0].airItinerary[0].flightSegments[0]
                        .departureAirport.cityName
                    }
                    destinationCity={
                      this.state.result &&
                      this.state.result[0] &&
                      _.last(
                        this.state.result[0].airItinerary[0].flightSegments
                      ).arrivalAirport.cityName
                    }
                  />
                </div>
              )}

              {this.state.result.length > 0 ? (
                <div className="avails-holder">
                  <div className="results-sorting-block row">
                    <div className="sort-container">
                      <img
                        src={sortIcon}
                        alt={"sort"}
                        title={Dictionary.sortBy}
                      />
                      <label className="margin-end-light" htmlFor={"sort"}>
                        {Dictionary.sortBy}:{" "}
                      </label>
                      <button
                        id={"sort"}
                        type="button"
                        className={
                          this.state.sortPrice
                            ? "button results-sort-btn"
                            : "button results-sort-btn low"
                        }
                        onClick={() => {
                          this.handleSort("totalPrice");
                        }}
                      >
                        {" "}
                        {this.state.sortPrice
                          ? Dictionary.theMostExpensive
                          : Dictionary.cheapest}{" "}
                      </button>
                    </div>
                  </div>
                  <ListItem
                    data={this.state.result}
                    last={this.state.last}
                    start={this.state.first}
                    type={Travel.flight}
                    tripType={this.state.tripType}
                    domestic={true}
                  />
                  {this.state.result.length > 10 && (
                    <Page
                      length={this.state.result.length}
                      itemCurrent={this.state.first}
                      itemPerPage={10}
                      onChangeValue={this.handleChangeValue.bind(this)}
                    />
                  )}
                </div>
              ) : (
                <div className="section-vertical-padding text-center">
                  <div>
                    <span className="itours-icon icon-xlarge error-icon" />
                  </div>
                  <h5 className="normal-title">
                    {Dictionary.noFlightFoundForYourRequest}.
                    <div className="page-subtitle">
                      {Dictionary.pleaseTryAgain}.
                    </div>
                  </h5>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  error: state.VisualData.error,
  respond: state.VisualData.Responded,
  flights: state.FlightSearch.flight,
  airlineFilter: state.FlightSearch.filterShow.airlinePrice,
  backupFlight: state.FlightSearch.backupFlight,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
});
const mapDispatchToProps = (dispatch) => ({
  searchFlight: (param) => {
    dispatch(searchDomesticFlight(param));
  },
  searchFlightAsync: (param) => {
    dispatch(searchFlightAsync(param));
  },
  editMessage: (param) => {
    dispatch(editMessage(param));
  },
  sortFlightSearch: (param) => {
    dispatch(sortFlightSearch(param));
  },
});
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(domesticFlightResult));

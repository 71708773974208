//import libraries
import React from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { setCurrency } from "../../Actions/UserAction";
//import redux actions
import { toggleAccountWindow } from "../../Actions/VisualDataAction";
import { SwitchAccountWindow } from "../../Actions/VisualDataAction";
//load UI components
import ProfileBallon from "../Admin/ProfileBallon";
//load Assets files

import LayoutConfig from "../../Config/layoutConfig";

//declaration Class Component
class Header extends React.Component {
  state = {
    routerInfo: null,
    disableLink: false,
    trackEmail: null,
    trackReserveID: null,
    showTrack: false,
    MenuClass: "",
  };
  componentDidMount() {
    if (this.props.LAN === "IR"){
      this.props.setCurrency("IRR");
    }
    this.setState({
      routerInfo: this.props.location.pathname.split("/")[1],
    });
    let path = this.props.history.location.pathname.split("/");
    if (path[1] === "payment") {
      this.setState({
        disableLink: true,
      });
    } else {
      this.setState({
        disableLink: false,
      });
    }
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location !== prevProps.location) {
      this.setState({
        routerInfo: this.props.location.pathname.split("/")[1],
      });
    }
    if (this.props.currency !== prevProps.currency) {
      window.location.reload();
    }
    if (this.props !== prevProps) {
      let path = this.props.history.location.pathname.split("/");
      if (path[1] === "payment") {
        this.setState({
          disableLink: true,
        });
      } else {
        this.setState({
          disableLink: false,
        });
      }
    }
  }
  changeCurrency = (currency) => {
    this.props.getCurrency(currency);
  };

  setToggleTopMenuClass = () => {
    if (this.state.MenuClass !== "expanded") {
      this.setState({
        MenuClass: "expanded",
      });
    } else {
      this.setState({
        MenuClass: "",
      });
    }
  };

  render() {
    const culture = window.location.origin;
    const { Dictionary, LAN } = this.props;
    const {
      SwitchAccountWindow,
      Verification,
      toggleAccountWindow,
      UserData,
      smPixel
    } = this.props;
    return (
      <header className="homeNavbar navbar box-shadow">
        {smPixel && <img src={smPixel} style={{width:"1px",height:"1px",opacity:0}} alt="safarmarketpixel"/>}
        <div className="page-container">
          <div position="static" color="transparent">
            <div className="relative">
              <div className="logo-holder clearfix">
                <div className="lang-currency pull-end">
                  {(LayoutConfig.themeClassName === "padide-com-theme" || LAN === "IR" || LayoutConfig.themeClassName==='naminTravel-theme__') || (
                    <div className="link margin-end-half inline-block">
                      <select
                        disabled={this.state.disableLink}
                        defaultValue={this.props.userData.Currency}
                        className="itours-select"
                        onChange={(e) => {
                          this.props.setCurrency(e.target.value);
                        }}
                      >
                        {LAN === "IR" && <option value="IRR" selected={this.props.userData.Currency === "IRR"}> IRR </option>}
                        <option value="EUR" selected={this.props.userData.Currency === "EUR"}>EUR </option>
                        <option value="AED" selected={this.props.userData.Currency === "AED"} > AED </option>
                        <option value="DKK" selected={this.props.userData.Currency === "DKK"}> DKK </option>
                        <option value="SEK" selected={this.props.userData.Currency === "SEK"}> SEK </option>
                        <option value="USD" selected={this.props.userData.Currency === "USD"}> USD </option>
                        <option value="NOK" selected={this.props.userData.Currency === "NOK"}> NOK </option>
                        <option value="TRY" selected={this.props.userData.Currency === "TRY"}> TRY </option>
                      </select>
                    </div>
                  )}
                  
                  {
                    (LayoutConfig.themeClassName &&
                      (
                          LayoutConfig.themeClassName === "sunhotel-theme" ||
                          LayoutConfig.themeClassName === "padide-com-theme" ||
                          LayoutConfig.themeClassName==='norTravel-theme'
                      )
                    ) 
                  ||  process.env.REACT_APP_THEME ==='NAMINTRAVELCOM' ?
                  null 
                  : (
                    <div className="link margin-end-half inline-block">
                      <select
                        className="itours-select"
                        onChange={(e) => {
                          window.location = e.target.value;
                        }}
                      >
                        {culture === "https://itours.ir" && (
                          <option value="https://itours.ir" selected >فارسی</option>
                        )}
     
                        <option value="https://itours.co" selected={LAN === "US"}>English</option>
                        <option value="https://itours.no" selected={LAN === "NO"}>Norsk</option>
                        <option value="https://itours.se" selected={LAN === "SE"} >Sverige</option>
                        <option value="https://itours.dk" selected={LAN === "DK"}>Dansk</option>
                        <option value="https://itours.ae" selected={LAN === "AR"} >العربية</option>
                        <option value="https://travelport.as" selected={LAN === "TR"} >Turkish</option>
                      </select>
                    </div>
                  )}

                  {LayoutConfig.themeClassName === "padide-com-theme" || (
                    <div className="account-links inline-block">
                      {!Verification ? (
                        <div className="account">
                          <div
                            className="link inline-block"
                            onClick={() => {
                              SwitchAccountWindow(false);
                              toggleAccountWindow();
                            }}
                          >
                            {Dictionary.login}
                          </div>
                          {/*<div className="link margin-start inline-block"  onClick={()=>{SwitchAccountWindow(true);toggleAccountWindow()}}> {Dictionary.registration}</div>*/}
                        </div>
                      ) : (
                        <ProfileBallon model={UserData} />
                      )}
                    </div>
                  )}
                </div>
                <Link className="logo" to={"/"}>
                  <img src={LayoutConfig.logo} alt={LayoutConfig.companyName} />
                </Link>
              </div>
            </div>
          </div>
          <div className="clearfix">
            <div className="pull-end tracking-order-holder">
              <button
                type="button"
                className="button color-1-btn small-btn"
                onClick={() => {
                  this.setState((prevState) => ({
                    showTrack: !prevState.showTrack,
                  }));
                }}
              >
                {Dictionary.trackOrder}
              </button>
              {this.state.showTrack && (
                <div className={"tracking"}>
                  <div className={"trackingContainer"}>
                    <div className={"username form-group"}>
                      <label htmlFor="trackingUsername">
                        *{Dictionary.email}
                      </label>
                      <input
                        className="form-input placeholder-right"
                        id={"trackingUsername"}
                        value={this.state.trackEmail}
                        onChange={(e) => {
                          this.setState({ trackEmail: e.target.value });
                        }}
                        type={"text"}
                        placeholder={Dictionary.enterYourEmail}
                      />
                    </div>
                    <div className={"reserveNumber form-group"}>
                      <label htmlFor="trackingUsername">
                        *{Dictionary.reservationCode}{" "}
                      </label>
                      <input
                        className="form-input placeholder-right"
                        id={"trackingUsername"}
                        value={this.state.trackReserveID}
                        onChange={(e) => {
                          this.setState({ trackReserveID: e.target.value });
                        }}
                        type="text"
                        placeholder={
                          Dictionary.enterTheReservationCodeYouReceived
                        }
                      />
                    </div>
                    <div className="clearfix">
                      <button
                        type="button"
                        className="button color-1-btn"
                        disabled={
                          !(this.state.trackEmail && this.state.trackReserveID)
                        }
                        onClick={() => {
                          this.setState({ showTrack: false });
                          this.props.history.push(
                            `/profile/details?userName=${this.state.trackEmail}&reserveId=${this.state.trackReserveID}`
                          );
                        }}
                      >
                        {Dictionary.send}
                      </button>
                      <button
                        type="button"
                        className="button red-btn pull-end"
                        onClick={() => {
                          this.setState({ showTrack: false });
                        }}
                      >
                        {Dictionary.close}
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="pull-start">
              <div>
                <button
                  type="button"
                  className="menu-toggle-btn"
                  onClick={this.setToggleTopMenuClass}
                >
                  <span className="menu-btn-bar icon-top-bar"></span>
                  <span className="menu-btn-bar icon-middle-bar"></span>
                  <span className="menu-btn-bar icon-bottom-bar"></span>
                </button>
                <div className="main-menu-holder__">
                  {this.state.MenuClass === "expanded" && (
                    <span
                      className={"menu-overlay"}
                      onClick={this.setToggleTopMenuClass}
                    />
                  )}
                  <div className={`main-menu-holder ${this.state.MenuClass}`}>
                    <button
                      type="button"
                      className="opened menu-toggle-btn red-arrow-right itours-icon mirror-ltr"
                      onClick={this.setToggleTopMenuClass}
                    />
                    <ul className="main-menu">
                      {LayoutConfig.hasDomesticFlight ?
                        <li
                          className={
                            this.state.routerInfo === "domesticflight" ||  (this.state.routerInfo === "" && (LayoutConfig.hasDomesticFlight)) 
                              ? "active menu-item"
                              : "menu-item"
                          }
                        >
                          <Link
                            to={"/"}
                            className="menu-link"
                            onClick={this.setToggleTopMenuClass}
                          >
                            <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                            پرواز داخلی
                          </Link>
                        </li>
                      :null}                      
                      <li
                        className={
                          (this.state.routerInfo === "" && (!LayoutConfig.hasDomesticFlight)) ||
                          this.state.routerInfo === "flight" ||
                          this.state.routerInfo === "foreignflight"
                            ? "active menu-item"
                            : "menu-item"
                        }
                      >
                        <Link
                          to={LayoutConfig.hasDomesticFlight?"/foreignflight":"/"}
                          className="menu-link"
                          onClick={this.setToggleTopMenuClass}
                        >
                          <span className="itours-icon top-plane-gray hidden-medium hidden-large margin-end-light" />
                          {Dictionary.foreignFlight}
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.routerInfo === "hotel"
                            ? "active menu-item"
                            : "menu-item"
                        }
                      >
                        <Link
                          to={"/hotel"}
                          className="menu-link"
                          onClick={this.setToggleTopMenuClass}
                        >
                          <span className="itours-icon hotel-gray hidden-medium hidden-large margin-end-light" />
                          {Dictionary.foreignHotel}
                        </Link>
                      </li>

                      <li
                        className={
                          this.state.routerInfo === "package"
                            ? "active menu-item"
                            : "menu-item"
                        }
                      >
                        <Link
                          to={"/package"}
                          className="menu-link"
                          onClick={this.setToggleTopMenuClass}
                        >
                          <span className="itours-icon package-gray hidden-medium hidden-large margin-end-light" />
                          {Dictionary.package}
                        </Link>
                      </li>                      

                      <li
                        className={
                          this.state.routerInfo === "terms"
                            ? "active menu-item"
                            : "menu-item"
                        }
                      >
                        <Link
                          to={"/terms"}
                          className="menu-link"
                          disabled={
                            LayoutConfig.themeClassName === "padide-com-theme" ||
                            LayoutConfig.themeClassName === "naminTravel-theme__"
                          }
                          onClick={this.setToggleTopMenuClass}
                        >
                          <span className="itours-icon terms hidden-medium hidden-large margin-end-light" />
                          {Dictionary.termsAndConditions}
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.routerInfo === "about"
                            ? "active menu-item"
                            : "menu-item"
                        }
                      >
                        <Link
                          to={"/about"}
                          className="menu-link"
                          onClick={this.setToggleTopMenuClass}
                        >
                          <span className="itours-icon about-us hidden-medium hidden-large margin-end-light" />
                          {Dictionary.aboutUs}
                        </Link>
                      </li>
                      <li
                        className={
                          this.state.routerInfo === "contact"
                            ? "active menu-item"
                            : "menu-item"
                        }
                      >
                        <Link
                          to={"/contact"}
                          className="menu-link"
                          onClick={this.setToggleTopMenuClass}
                        >
                          <span className="itours-icon contact-us hidden-medium hidden-large margin-end-light" />
                          {Dictionary.contactUs}
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
//map state to Props
const MapStateToProps = (state) => ({
  Verification: state.VisualData.Verification,
  userData: state.UserData,
  HomeStyle: state.VisualData.HomeStyle,
  currency: state.UserData.Currency,
  Dictionary: state.VisualData.Dictionary,
  LAN: state.VisualData.LAN,
  smPixel:state.FlightSearch.safarmarketPixel
});
//map action to Props
const MapAction = (dispatch) => ({
  toggleAccountWindow: () => dispatch(toggleAccountWindow()),
  SwitchAccountWindow: (mode) => {
    dispatch(SwitchAccountWindow(mode));
  },
  setCurrency: (value) => {
    dispatch(setCurrency(value));
  },
});
const routerInfo = withRouter(Header);
export default connect(MapStateToProps, MapAction)(routerInfo);
